<template>
  <Tile
    v-if="json"
    :data-test-id="`one-to-one-tile-${json.id}`"
    :json="json"
    :locked="locked"
    :locked-until-tasked="lockedUntilTasked"
    :type="json.tile_type"
    :user-endpoint="userEndpoint"
    :carousel-notice-displayed="carouselNoticeDisplayed"
    :learnlist-notice-displayed="learnlistNoticeDisplayed"
    :modal-copy="modalCopy"
    :use-legacy-menu="true"
  >
    <template #body-properties>
      <TileProperties
        :data-test-id="`one-to-one-tile-${json.id}-properties`"
        :properties="properties"
      />
      <div class="tile__details m-t-1">
        <i class="ic ic-t-users-man-woman" />
        <img v-if="json.user && json.user.avatar" class="avatar" :src="json.user.avatar" alt="" />
        <img
          v-if="json.assessor && json.assessor.avatar"
          class="avatar"
          :src="json.assessor.avatar"
          alt=""
        />
      </div>
      <div class="m-t-1">
        <span v-if="json.user && json.user.full_name" class="tile__reviewee">
          {{ t('vue_templates.components.one_to_one.review_avatar.reviewee') }}&colon;
          {{ json.user.full_name }}
        </span>
        <span v-if="json.assessor && json.assessor.full_name" class="tile__reviewer">
          {{ t('vue_templates.components.one_to_one.review_avatar.reviewer') }}&colon;
          {{ json.assessor.full_name }}
        </span>
      </div>
    </template>
    <template #footer-content>
      <BookmarkButton
        :id="json.id"
        :data-test-id="`one-to-one-tile-${json.id}-bookmark`"
        :type="json.tile_type"
        :init-liked="json.liked"
      />
    </template>
  </Tile>
</template>
<script lang="ts">
export default {
  name: 'OneToOneTile',
  compatConfig: { MODE: 3 },
};
</script>
<script setup lang="ts">
import { defineProps, computed } from 'vue';
import { useI18n } from '@/composables/useI18n';
import { useTileProps } from '@/composables/useTileProps';
import BookmarkButton from '@/components/molecules/BookmarkButton.vue';
import TileProperties from '@/components/atoms/Tile/TileProperties.vue';
import Tile from '@/components/organisms/Tiles/BaseTile/Index.vue';

defineProps({
  ...useTileProps('one_to_one'),
});
const { t } = useI18n();
const properties = computed(() => {
  return [t('activerecord.models.one_to_one.one')];
});
</script>

<style scoped lang="scss"></style>
