<template>
  <Transition name="fade">
    <slot />
  </Transition>
</template>

<script>
export default {
  name: 'TransitionFade',
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-down-leave-active {
  transition: opacity 0.4s ease;
}

.fade-down-leave-to {
  opacity: 0;
}
</style>
