<template>
  <ExpansionPanel :padded="!notes.length" class="notes-list" open rounded>
    <template #header>
      {{ t('activerecord.models.note.other') }}
      <!-- TODO: Implement tooltip with help prop when LA-9076 is done -->
      <BaseIcon v-if="helpText" icon-name="info" />
    </template>

    <!-- New Note -->
    <template v-if="showNewNoteInput">
      <BaseNoteInput
        :note="draftNote"
        :disabled="pendingNewNoteRequest"
        :save-button-text="t('vue_templates.notes.add_note')"
        :show-privacy-options="showPrivacyOptions"
        :at-mention-params="atMentionParams"
        :editor-config="editorConfig"
        class="m-a-2"
        data-test-id="notes-list-new-input-inline"
        @save="onSaveNewNote"
        @cancel="resetNoteInputs"
      />
      <div v-if="notes && notes.length" class="notes-list__hr" />
    </template>

    <!-- Note list -->
    <template v-if="notes && notes.length">
      <div v-if="showAddNoteButton" class="notes-list__actions">
        <a
          role="button"
          tabindex="0"
          class="notes-list__add"
          data-test-id="notes-list-add-button"
          @click="onAddNoteClick"
          @keydown.space.prevent="onAddNoteClick"
          @keydown.enter="onAddNoteClick"
        >
          {{ t('vue_templates.notes.add_note') }}
        </a>
      </div>
      <template v-for="(note, index) in notes" :key="`hr-${note.id}`">
        <div v-if="index !== 0" class="notes-list__hr" />
        <BaseNote
          :initial-note="note"
          :compact="compact"
          :at-mention-params="atMentionParams"
          :editor-config="editorConfig"
          :data-test-id="`notes-list-item-${note.id}`"
          @deleted="removeNote"
          @open="handleBaseNoteOpen"
          @attachment-click="handleAttachmentClick"
        />
      </template>
    </template>

    <!-- Skeleton loader notes -->
    <template v-else-if="loading">
      <BaseNote
        v-for="n in 3"
        :key="n"
        :loading="loading"
        :compact="compact"
        :data-test-id="`notes-list-loading-item-${n}`"
      />
    </template>

    <EmptyListState
      v-else
      :img-src="emptyStateImage"
      :text="t('vue_templates.notes.not_found')"
      :button-text="t('vue_templates.notes.add_note')"
      :show-button="showAddNoteButton"
      @button-click="onAddNoteClick"
    />

    <!-- Modal for compact list only -->
    <BaseModal
      id="notes-list-modal"
      v-model:model-value="modalOpen"
      :title="t('activerecord.models.note.other')"
      :disabled="pendingNewNoteRequest"
      :show-footer="false"
      :persistent="enablePersistentNoteModal"
      size="large"
      data-test-id="notes-list-modal"
      class="notes-list__modal"
      :focus-trap-enabled="focusTrapEnabled"
      @update:model-value="resetNoteInputs"
    >
      <!-- Existing Note -->
      <BaseNote
        v-if="noteSelected"
        :initial-note="noteSelected"
        :show-delete="false"
        data-test-id="notes-list-modal-note"
        :show-privacy-options="showPrivacyOptions"
        show-attachment-preview
        @updated="replaceNote"
        @edit-click="onNoteEditClick"
        @edit-cancel="onNoteEditCancel"
      />
      <!-- New Note -->
      <BaseNoteInput
        v-if="showNewNoteModalInput"
        :note="draftNote"
        :disabled="pendingNewNoteRequest"
        :save-button-text="t('vue_templates.notes.add_note')"
        :at-mention-params="atMentionParams"
        :editor-config="editorConfig"
        data-test-id="notes-list-new-input-modal"
        :show-privacy-options="showPrivacyOptions"
        @save="onSaveNewNote"
        @cancel="handleNoteModalClose()"
        @tinymce-dialog-opened="handleTinyMceDialogOpened"
        @tinymce-dialog-closed="handleTinyMceDialogClosed"
      />
    </BaseModal>

    <!-- Attachment modal for full-width notes -->
    <BaseModal
      id="notest-list-attachment-modal"
      v-model:model-value="attachmentModalOpen"
      :title="t('vue_templates.notes.attachment_modal_title')"
      :disabled="pendingNewNoteRequest"
      :show-footer="false"
      size="large"
      data-test-id="notes-list-attachment-modal"
      class="notes-list__modal"
      @update:model-value="attachmentSelected = null"
    >
      <DocumentAttachmentPreview v-if="attachmentSelected" :document="attachmentSelected" />
    </BaseModal>
  </ExpansionPanel>
</template>

<script lang="ts">
// Modules
import { notify } from '@kyvg/vue3-notification';
import { defineComponent, ref, Ref, computed, ComputedRef, PropType } from 'vue';
// Composables
import { useApiClient } from '@/composables/useApiClient';
import { useI18n } from '@/composables/useI18n';
import { ITinyMceAtMentionProps } from '@/composables/useAtMention';
// Types
import INote, { INoteDraft, NoteableTypes, NoteableTypeConfig } from '@/types/Note';
import Document from '@/types/Document';
// Components
import BaseIcon from '@/components/atoms/Icon/Index.vue';
import BaseNote from '@/components/molecules/BaseNote/Index.vue';
import BaseNoteInput from '@/components/molecules/BaseNoteInput/Index.vue';
import BaseModal from '@/components/atoms/BaseModal/Index.vue';
import DocumentAttachmentPreview from '@/components/molecules/DocumentAttachmentPreview/Index.vue';
import ExpansionPanel from '@/components/molecules/ExpansionPanel/Index.vue';
import EmptyListState from '@/components/molecules/one-to-one/EmptyListState/Index.vue';
// Assets
import emptyStateImage from 'images/empty_states/one_to_one/notes.svg';
// Types
import {
  CopyProps,
  IPropTypes,
} from '@tinymce/tinymce-vue/lib/cjs/main/ts/components/EditorPropTypes';

export default defineComponent({
  name: 'NotesList',
  components: {
    BaseIcon,
    BaseNote,
    BaseNoteInput,
    BaseModal,
    DocumentAttachmentPreview,
    ExpansionPanel,
    EmptyListState,
  },
  props: {
    editorConfig: Object as PropType<CopyProps<IPropTypes>>,
    atMentionParams: Object as PropType<ITinyMceAtMentionProps>,
    noteableType: String as PropType<NoteableTypes>,
    helpText: String,
    compact: Boolean,
    canAdd: Boolean,
    endpoint: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const { apiClient } = useApiClient();
    const notes: Ref<INote[]> = ref([]);
    const loading: Ref<boolean> = ref(false);
    const showNewNoteInput: Ref<boolean> = ref(false);
    const showNewNoteModalInput: Ref<boolean> = ref(false);
    const draftNote: Ref<INoteDraft> = ref({
      content: '',
      private: !!(props.noteableType && NoteableTypeConfig[props.noteableType].defaultPrivate),
    });
    const noteSelected: Ref<INote | null> = ref(null);
    const attachmentSelected: Ref<Document | null> = ref(null);
    const pendingNewNoteRequest: Ref<boolean> = ref(false);
    const modalOpen: Ref<boolean> = ref(false);
    const attachmentModalOpen: Ref<boolean> = ref(false);
    const isEditingNote = ref(false);
    const focusTrapEnabled = ref(true);

    const addNoteLabel: ComputedRef<string> = computed(() => {
      const noteModel = t('activerecord.models.note.one').toLowerCase();
      return `+ ${t('helpers.submit.add', { model: noteModel })}`;
    });

    const showAddNoteButton = computed(() => {
      return !!(props.endpoint && props.canAdd && !showNewNoteInput.value);
    });

    const showPrivacyOptions = computed(() => {
      if (!props.noteableType) {
        return false;
      }
      return NoteableTypeConfig[props.noteableType].showCommentPrivacyOptions;
    });

    const getNotes = async () => {
      const params = { page: 1 };

      loading.value = true;
      try {
        const response: IResponse = await apiClient.get(props.endpoint, { params: params });
        notes.value = response.body.notes;
      } catch (e) {
        notify({
          type: 'error',
          duration: -1,
          title: t('vue_templates.notes.unable_to_load'),
        });
      }
      loading.value = false;
    };

    const removeNote = ({ id }: INote) => {
      notes.value = notes.value?.filter((note) => note.id !== id);
    };

    const replaceNote = (note: INote) => {
      isEditingNote.value = false;

      const noteIndex = notes.value?.findIndex(({ id }) => id === note.id);
      if (noteIndex !== -1) {
        notes.value.splice(noteIndex, 1, note);
      }
    };

    const handleBaseNoteOpen = async (note: INote) => {
      noteSelected.value = note;
      modalOpen.value = true;
    };

    const handleTinyMceDialogOpened = () => {
      focusTrapEnabled.value = false;
    };

    const handleTinyMceDialogClosed = () => {
      focusTrapEnabled.value = true;
    };

    const onAddNoteClick = () => {
      if (props.compact) {
        modalOpen.value = true;
        showNewNoteModalInput.value = true;
      } else {
        showNewNoteInput.value = true;
      }
    };

    const resetNoteInputs = () => {
      isEditingNote.value = false;
      noteSelected.value = null;
      showNewNoteInput.value = false;
      showNewNoteModalInput.value = false;
    };

    const handleNoteModalClose = () => {
      modalOpen.value = false;
      resetNoteInputs();
    };

    const notifySaveError = (error?: string) => {
      notify({
        type: 'error',
        duration: -1,
        title: t('vue_templates.notes.note_error'),
        text: error,
      });
    };

    const onSaveNewNote = async (newNote: Partial<INote>) => {
      if (!props.endpoint) {
        notifySaveError();
        return;
      }

      pendingNewNoteRequest.value = true;
      try {
        const response = await apiClient.post(props.endpoint, {
          note: newNote,
        });

        if (response.ok) {
          notify({
            type: 'success',
            title: t('vue_templates.notes.save_successful'),
          });
          handleNoteModalClose();
          notes.value = [response.body, ...notes.value];
        } else {
          const error = typeof response?.body === 'string' ? response.body : undefined;
          notifySaveError(error);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        notifySaveError(e?.message);
      }
      pendingNewNoteRequest.value = false;
    };

    const handleAttachmentClick = (document: Document) => {
      if (document.open_inline) {
        attachmentSelected.value = document;
        attachmentModalOpen.value = true;
      }
    };

    const onNoteEditCancel = () => {
      isEditingNote.value = false;
    };

    const onNoteEditClick = () => {
      isEditingNote.value = true;
    };

    const enablePersistentNoteModal = computed(() => {
      return isEditingNote.value || showNewNoteModalInput.value;
    });

    getNotes();

    return {
      addNoteLabel,
      notes,
      loading,
      draftNote,
      noteSelected,
      attachmentSelected,
      showNewNoteInput,
      showNewNoteModalInput,
      pendingNewNoteRequest,
      showPrivacyOptions,
      showAddNoteButton,
      enablePersistentNoteModal,
      isEditingNote,
      focusTrapEnabled,
      handleAttachmentClick,
      handleTinyMceDialogOpened,
      handleTinyMceDialogClosed,
      onSaveNewNote,
      removeNote,
      replaceNote,
      t,
      onAddNoteClick,
      handleBaseNoteOpen,
      resetNoteInputs,
      handleNoteModalClose,
      onNoteEditCancel,
      onNoteEditClick,
      modalOpen,
      attachmentModalOpen,
      emptyStateImage,
      test__apiClient: apiClient,
    };
  },
});

interface IResponse {
  body: {
    notes: INote[];
  };
}
</script>

<style scoped lang="scss">
.notes-list__actions {
  padding: 1rem 2rem;
}
.notes-list__add {
  padding: 0;
  text-decoration: none;

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus-visible {
    outline-offset: 0.5rem;
  }
}

.notes-list__hr {
  border-bottom: 1px solid $pastel-blue;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
