<script lang="ts">
export default {
  name: 'CsvUploadModal',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineEmits, defineProps, Ref, ref } from 'vue';
import BaseModal from '@/components/atoms/BaseModal/Index.vue';
import BaseButton from '@/components/atoms/BaseButton/Index.vue';
import ProgressBar from '@/components/atoms/ProgressBar/Index.vue';
import TransitionSlideX from '@/components/atoms/Transitions/SlideX.vue';
import { useI18n } from '@/composables/useI18n';
import { useApiClient } from '@/composables/useApiClient';
import { useDropZone } from '@vueuse/core';
import { useFileDialog } from '@vueuse/core';
import { S3HostData } from '@/types/S3HostData';
import { useDirectFileUpload } from '@/composables/useDirectFileUpload';

interface Props {
  title: string;
  uploadMessage: string;
  templatePath: string;
  formData: string;
  host: S3HostData['host'];
  url: S3HostData['url'];
  endpoint: string;
  refreshEvent: string;
}

const props = defineProps<Props>();
const emit = defineEmits(['update:model-value']);
const { t } = useI18n();
const { apiClient } = useApiClient();
const { upload, uploadProgress, isUploading } = useDirectFileUpload(apiClient, t);

const modalOpen: Ref<boolean> = ref(false);
const csv: Ref<string | undefined> = ref(undefined);
const dropzone: Ref<HTMLElement | null> = ref(null);
const processing: Ref<boolean> = ref(false);
const success: Ref<boolean> = ref(false);

const uploadToS3 = async (file: File) => {
  const S3HostData: S3HostData = {
    formData: JSON.parse(props.formData),
    host: props.host,
    url: props.url,
  };
  const response = await upload(S3HostData, file);
  if (!response) return;
  csv.value = response?.url;
  uploadProgress.value = 100;
};

const onDrop = (files: File[] | null) => {
  if (!files) return;
  uploadToS3(files[0]);
};

const submitFile = async () => {
  processing.value = true;
  const params = {
    csv: csv.value,
  };

  try {
    const response = await apiClient.post(props.endpoint, params);
    if (response.ok) {
      success.value = true;
      csv.value = undefined;
    }
  } catch (e) {
    console.error(e);
  } finally {
    processing.value = false;
  }
};

const resetRefs = () => {
  csv.value = undefined;
  processing.value = false;
  success.value = false;
  isUploading.value = false;
};

const close = () => {
  modalOpen.value = false;
  emit('update:model-value', false);
  resetRefs();
  window.emitter.emit(props.refreshEvent);
};

useDropZone(dropzone, {
  onDrop,
  dataTypes: ['text/csv'],
});

const { open, onChange } = useFileDialog({
  accept: 'text/csv',
  multiple: false,
});

const openFileDialog = () => {
  open();
};

onChange((files: FileList | null) => {
  if (!files) return;
  uploadToS3(files[0]);
});
</script>

<template>
  <BaseModal
    v-model:model-value="modalOpen"
    class="csv-upload-modal"
    size="medium"
    :title="title"
    @update:model-value="close"
  >
    <p>{{ uploadMessage }}</p>
    <BaseButton
      theme="brand"
      variant="outlined"
      data-test-id="csv-upload-modal-template-button"
      fa-prepend-icon="fa-download fa-regular"
      :link="{ href: templatePath }"
      >{{ t('vue_templates.csv_upload_modal.csv_template') }}</BaseButton
    >
    <template v-if="success">
      <div class="tw-text-center">
        <TransitionSlideX>
          <FontAwesomeIcon
            icon="fa-solid fa-check"
            size="6x"
            class="tw-my-12 tw-text-green-600"
            data-test-id="csv-upload-modal-success-icon"
          />
        </TransitionSlideX>
      </div>
    </template>
    <template v-else-if="processing">
      <div class="loader"></div>
    </template>
    <template v-else>
      <ProgressBar
        v-if="isUploading || !!csv"
        :display-value="false"
        :value="uploadProgress"
        class="tw-my-12"
      />
      <div
        v-else
        ref="dropzone"
        class="tw-py-12 tw-my-12 tw-text-center tw-flex tw-flex-col tw-justify-center tw-bg-gray-100 tw-border-2 tw-border-dashed tw-border-gray-500 hover:tw-bg-blue-100"
      >
        <FontAwesomeIcon
          icon="fa-regular fa-file-circle-plus"
          size="6x"
          class="tw-my-4 tw-text-orange"
          data-test-id="csv-upload-modal-dropzone-icon"
        />
        <h4>{{ t('vue_templates.csv_upload_modal.drag_and_drop_file_here') }}</h4>
        <a
          class="tw-underline"
          role="button"
          tabindex="0"
          data-test-id="csv-upload-modal-open-file-dialog-button"
          @click.prevent="openFileDialog"
          @keyup.prevent.enter="openFileDialog"
          >{{ t('vue_templates.csv_upload_modal.or_browse_files') }}</a
        >
        <small class="tw-gray-600">{{
          t('vue_templates.csv_upload_modal.accepts_only_csv')
        }}</small>
      </div>
    </template>
    <template #footer>
      <div class="tw-m-6 tw-flex tw-justify-between">
        <BaseButton
          theme="brand"
          variant="text"
          data-test-id="csv-upload-modal-close-button"
          @click.prevent="close"
          @keyup.enter.prevent="close"
          >{{ t('helpers.buttons.close') }}</BaseButton
        >
        <BaseButton
          v-if="!processing && !success"
          theme="brand"
          data-test-id="csv-upload-modal-import-button"
          :disabled="!csv || isUploading"
          @click.prevent="submitFile"
          @keyup.enter.prevent="submitFile"
          >{{ t('vue_templates.imports_log.import') }}</BaseButton
        >
      </div>
    </template>
  </BaseModal>
</template>
