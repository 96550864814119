export default {
  props: {
    filters: Array,
  },

  data: function () {
    return {
      allSelected: false,
      allSelectedAllPages: false,
      sortOrder: 1,
      sortKey: '',
      columnList: [],
      editColumnList: [],
      appliedFilters: [],
      expandFilters: true,
      availableColumnList: {},
    };
  },
  methods: {
    // Sortable column helpers start
    fetchColumns: function () {
      this.$http.get(this.columnInfoUrl).then((response) => {
        this.columnList = response.body.slice(0);
        this.editColumnList = response.body.slice(0);
      });
    },

    showColumnEditor: function () {
      $('#editColumns').modal({ show: true });
    },

    addSelectedColumn: function (column) {
      this.editColumnList.push(column);
    },

    onEditColumnsClose: function () {
      setTimeout(() => {
        this.editColumnsIndex = this.editColumnsIndex + 1;
        this.fetchColumns();
      }, 200);
    },

    handleSaveColumns: function () {
      const params = this.editColumnList.map((item, index) => {
        item.display_order = index + 1;
        return item;
      });

      this.$http.put(this.columnInfoUrl, params).then((response) => {
        this.columnList = response.body.slice(0);
        $('#editColumns').modal('hide');
        this.reloadData();
      });
    },
    // Sortable column helpers end

    clearFilters: function (reload = true) {
      const filtersToRemove = this.appliedFilters.slice();

      filtersToRemove.forEach((filter) => {
        this.removeFilter(filter, false);
      });

      if (this.canManage && this.addStatusFilterOnLoad) {
        this.addFilter('Status');
      }

      if (reload) {
        this.$nextTick(() => {
          this.reloadData();
        });
      }
    },
    handleTypedChanges: _.debounce(function () {
      this.reloadData();
    }, 1000),
    sortBy: function (key, defaultOrder) {
      if (this.sortKey === key) {
        this.sortOrder *= -1;
      } else {
        this.sortOrder = defaultOrder || this.sortOrder;
      }
      this.sortKey = key;
      this.reloadData();
    },
  },
  computed: {
    availableFilters: function () {
      return _.differenceWith(this.filters, this.appliedFilters, _.isEqual);
    },
    showFilters: function () {
      return this.appliedFilters.indexOf('Status') > -1
        ? this.appliedFilters.length > 1
        : this.appliedFilters.length > 0;
    },
    showMultipageSelection: function () {
      return this.allSelected && this.paginationData && this.paginationData.total_pages > 1;
    },
  },
};
