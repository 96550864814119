export default {
  props: {
    placeholder: {
      required: true,
      type: String,
    },
    selector: {
      required: true,
      type: String,
    },
    preload: {
      required: true,
      type: Boolean,
    },
    maxItems: {
      required: true,
      type: Number,
    },
    closeAfterSelect: {
      required: true,
      type: Boolean,
    },
    url: {
      required: false,
      type: String,
    },
    onChange: {
      required: true,
      type: Function,
    },
    selectizeType: String, // Allows for custom RiseTo selectize types i.e. UsersDropdown
    openOnFocus: Boolean,
    valueField: String,
    labelField: String,
    optgroupLabelField: String,
    optgroupValueField: String,
    optgroupField: String,
    onLoad: Function,
    load: Function,
    ajaxData: Function,
    onInitialize: Function,
    optionsData: Array,
  },
};
