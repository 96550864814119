<template>
  <div class="date-range-filter__wrapper form-inline">
    <div class="form-group m-r-1">
      <label :for="$.uid.toString()">{{ t('vue_templates.filter_date_range.from') }}</label>
      <div class="input-group">
        <input
          :id="$.uid.toString()"
          ref="fromInput"
          class="datepicker form-control"
          placeholder="YYYY-MM-DD"
          data-test-id="date-from-input"
        />
        <div class="input-group-addon">
          <Icon icon-name="calendar" />
        </div>
      </div>
    </div>
    <div class="form-group">
      <label :for="$.uid.toString() + '2'">{{ t('vue_templates.filter_date_range.to') }}</label>
      <div class="input-group">
        <input
          :id="$.uid.toString() + '2'"
          ref="toInput"
          class="datepicker form-control"
          placeholder="YYYY-MM-DD"
          data-test-id="date-to-input"
        />
        <div class="input-group-addon">
          <Icon icon-name="calendar" />
        </div>
      </div>
    </div>
    <CustomButton
      v-if="showClearButton"
      class="m-l-1"
      button-style="primary"
      size="small"
      :action="clear"
      :text="t('vue_templates.filter_date_range.clear')"
    />
  </div>
</template>

<script>
export default {
  compatConfig: { MODE: 3 },
};
</script>

<script setup>
import { defineEmits, defineProps, onMounted, ref, watch } from 'vue';
import CustomButton from '@/components/atoms/Button/Index.vue';
import Icon from '@/components/atoms/Icon/Index.vue';
import { useI18n } from '@/composables/useI18n';

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({
      from: undefined,
      to: undefined,
    }),
  },
  initialFromDate: {
    type: String,
    required: false,
  },
  initialToDate: {
    type: String,
    required: false,
  },
  showClearButton: Boolean,
  datePickerOptions: {
    type: Object,
    default: () => ({
      format: 'yyyy-mm-dd',
      todayHighlight: true,
      weekStart: 1,
    }),
  },
});

const { t } = useI18n();

const from = ref(undefined);
const to = ref(undefined);

// Template refs
const fromInput = ref();
const toInput = ref();

const emit = defineEmits(['update:modelValue']);

watch(
  [from, to],
  ([newFrom, newTo]) => {
    emit('update:modelValue', {
      from: newFrom,
      to: newTo,
    });
  },
  {
    deep: true,
  }
);

const onFromChanged = () => {
  from.value = fromInput.value.value;
};

const onToChanged = () => {
  to.value = toInput.value.value;
};

const clear = () => {
  $(toInput.value).datepicker('setDate', null);
  $(fromInput.value).datepicker('setDate', null);
  from.value = undefined;
  to.value = undefined;
};

onMounted(() => {
  $(fromInput.value).datepicker(props.datePickerOptions).on('changeDate', onFromChanged);
  if (props.initialFromDate) {
    $(fromInput.value).datepicker('setDate', props.initialFromDate);
  }

  $(toInput.value).datepicker(props.datePickerOptions).on('changeDate', onToChanged);
  if (props.initialToDate) {
    $(toInput.value).datepicker('setDate', props.initialToDate);
  }

  $('.input-group-addon').on('click', () => {
    $(this).siblings('input').trigger('focus');
  });
});
</script>

<style scoped lang="scss">
.date-range-filter__wrapper {
  display: block;
}
</style>
