<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<template>
  <div
    :class="{
      'hover-wrapper--active': hover,
      'hover-wrapper--highlight': highlight,
      'hover-wrapper--error': error,
    }"
    class="hover-wrapper"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    @focusin="handleMouseEnter"
    @focusout="handleMouseLeave($event)"
  >
    <slot v-bind="{ hover }" />
  </div>
</template>

<script>
export default {
  compatConfig: { MODE: 3 },
  name: 'HoverWrapper',
  props: {
    highlight: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    hover: false,
  }),
  methods: {
    handleMouseEnter() {
      this.hover = true;
    },
    handleMouseLeave(e) {
      if (!e?.relatedTarget || !this.$el.contains(e.relatedTarget)) {
        this.hover = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$hover-background: $white-smoke;
.hover-wrapper--highlight {
  padding: 8px;

  &:hover {
    background-color: $hover-background;
  }

  &.hover-wrapper--active {
    background-color: $hover-background;
  }

  &.hover-wrapper--error {
    background-color: $pippin;
  }
}
</style>
