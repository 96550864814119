<template>
  <Tile
    v-if="json"
    :data-test-id="`learnlist-tile-${json.id}`"
    :json="json"
    :locked="locked"
    :locked-until-tasked="lockedUntilTasked"
    :type="json.tile_type"
    :user-endpoint="userEndpoint"
    :carousel-notice-displayed="carouselNoticeDisplayed"
    :learnlist-notice-displayed="learnlistNoticeDisplayed"
    :modal-copy="modalCopy"
    :use-legacy-menu="true"
  >
    <template #body-properties>
      <TileProperties
        :data-test-id="`learnlist-tile-${json.id}-properties`"
        :properties="properties"
      >
      </TileProperties>
    </template>
    <template #footer-content>
      <BookmarkButton
        :id="json.id"
        :data-test-id="`learnlist-tile-${json.id}-bookmark`"
        :type="json.tile_type"
        :init-liked="json.liked"
      />
    </template>
  </Tile>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useI18n } from '@/composables/useI18n';
import { usePluralise } from '@/composables/usePluralise';
import { useTileProps } from '@/composables/useTileProps';
import BookmarkButton from '@/components/molecules/BookmarkButton.vue';
import TileProperties from '@/components/atoms/Tile/TileProperties.vue';
import Tile from '@/components/organisms/Tiles/BaseTile/Index.vue';

export default defineComponent({
  name: 'LearnlistTile',
  components: {
    BookmarkButton,
    TileProperties,
    Tile,
  },
  props: {
    ...useTileProps('learnlist'),
  },
  setup(props) {
    const { t } = useI18n();
    const { pluralise } = usePluralise(t);

    const itemCountLabel = (count: number) => {
      const label = pluralise('activerecord.models.item', count).toLowerCase();
      return `${count} ${label}`;
    };

    const properties = computed(() => {
      const propArray: string[] = [];
      if (props.json?.learnlist_items_count) {
        propArray.push(itemCountLabel(props.json.learnlist_items_count));
      }

      if (props.json?.total_time) {
        propArray.push(props.json?.total_time);
      }

      return propArray;
    });

    return {
      properties,
      t,
    };
  },
});
</script>

<style scoped lang="scss">
.learnlist-tile__properties-icon {
  top: 2px;
  padding-right: 8px;
}
</style>
