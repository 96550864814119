<template>
  <Pips
    v-if="json.available_actions_path"
    dropup
    :full-width="menuFullWidth"
    :fetch-url="json.available_actions_path"
    :loading="loading"
    :float-none="true"
    @fetch-menu-items="fetchMenuItems"
  >
    <template v-for="(item, index) in menuItems">
      <Search
        v-if="item.type === 'search'"
        :key="`search-${index}`"
        :parent-item-id="json.id"
        :parent-item-type="json.tile_type"
        :fetch-url="`${json.available_actions_path}?include_collection=${item.search}`"
        :item-response-key="`${item.responseKey}`"
        :item-search-field="`${item.searchField}`"
        :item-sort-key="`${item.sortKey}`"
        :add-item="item.addItem"
      />
      <li
        v-else
        :key="`list-${index}`"
        :class="{ active: item.type === 'navigation' && item.direction === 'left' }"
      >
        <JavascriptActionLink
          v-if="item.show && item.type === 'javascript'"
          :action-name="item.actionName"
          :payload="item.payload"
          :label="item.label"
          @action="handleAction"
        />
        <CustomLink v-if="item.type === 'link'" :item="item" target="_blank" />
        <CustomLink v-if="item.type === 'remote'" :item="item" data-remote />
        <NavigationLink
          v-if="item.type === 'navigation'"
          :item="item"
          :direction="item.direction"
          @navigate="navigateMenu"
        />
        <template v-if="item.type === 'message'">
          {{ item.label }}
        </template>
      </li>
    </template>
  </Pips>
</template>

<script>
import i18n from '@/mixins/i18n';
import jsActionLink from '@/mixins/jsActionLink';
import CustomLink from '@/components/atoms/Menu/Link';
import JavascriptActionLink from '@/components/atoms/Menu/JavascriptActionLink';
import Pips from '@/components/atoms/Menu/Pips';
import NavigationLink from '@/components/atoms/Menu/NavigationLink';
import Search from '@/components/atoms/Menu/Search';

export default {
  name: 'CustomMenu',

  components: {
    CustomLink,
    Pips,
    NavigationLink,
    JavascriptActionLink,
    Search,
  },

  mixins: [i18n, jsActionLink],

  props: {
    json: Object,
    availableActions: Object,
    dropup: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      loading: true,
      menuItems: [],
      actions: [],
      menuFullWidth: false,
    };
  },

  watch: {
    availableActions: function (newActions) {
      this.formatMenuItems(newActions);
    },
  },

  methods: {
    navigateMenu: function (subMenu) {
      this.menuFullWidth = !this.menuFullWidth;
      this.formatMenuItems(this.availableActions, subMenu);
    },
    fetchMenuItems: function (fetchUrl) {
      if (this.menuItems.length > 0) {
        return;
      }

      this.$http.get(fetchUrl).then((response) => {
        this.actions = response.body.actions;
        this.formatMenuItems(this.availableActions, undefined);
        this.loading = false;
      });
    },

    formatMenuItems: function (availableActions, subMenu) {
      this.menuItems = [];

      // Iterate the actions the user is allowed to perform
      Object.keys(this.actions)
        // Filter based on the "availableActions" prop. Must be truthy (path or 'true' returned from API)
        .filter(
          (action) =>
            (action in availableActions && !!this.actions[action]) || action.type === 'javascript'
        )
        //Build the actions to pass to <menu />
        .forEach((action) => {
          // Sometimes we may use the same path with different labels. In this case we can provide
          // an array of labels and positions for one path.
          if (Array.isArray(availableActions[action])) {
            availableActions[action].forEach((_multiAction, actionIndex) => {
              this.addToMenuItems(
                {
                  payload: this.actions[action],
                  ...availableActions[action][actionIndex],
                },
                subMenu
              );
            });
          } else if (availableActions[action]) {
            this.addToMenuItems(
              {
                payload: this.actions[action],
                ...availableActions[action],
              },
              subMenu
            );
          }
        });

      if (this.menuItems.length > 0) {
        this.menuItems = this.menuItems.sort((a, b) => (a.position > b.position ? 1 : -1));
      } else {
        this.menuItems = [
          {
            label: this.t('vue_templates.tile_dropdown.no_actions_available'),
            type: 'message',
          },
        ];
      }
    },
    addToMenuItems: function (item, subMenu) {
      if (item.subMenu === subMenu) {
        this.menuItems.push(item);
      }
    },
  },
};
</script>
