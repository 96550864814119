const filterItems = {
  methods: {
    filterItems: function (items, search, searchField, sortKey) {
      const lowerCaseSearch = search.toLowerCase();
      if (items) {
        return items
          .concat()
          .filter(function (item) {
            return (
              item[searchField] && item[searchField].toLowerCase().indexOf(lowerCaseSearch) > -1
            );
          })
          .sort(sortBy(sortKey));
      } else {
        return [];
      }
    },
  },
};

const sortBy = (key) => {
  return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

export default filterItems;
