<template>
  <div class="base-pill" :class="{ [`base-pill--${theme}`]: theme, [`base-pill--${size}`]: size }">
    <slot>
      <BaseIcon v-if="icon" :icon-name="icon" size="small" class="base-pill__icon" align-middle />
      <span>{{ content }}</span>
    </slot>
  </div>
</template>

<script>
import BaseIcon from '@/components/atoms/Icon/Index.vue';

export default {
  name: 'BasePill',
  components: { BaseIcon },
  props: {
    theme: String,
    size: {
      type: String,
      default: 'medium',
      validator(size) {
        return ['small', 'medium'].includes(size);
      },
    },
    icon: String,
    content: String,
  },
};
</script>

<style lang="scss" scoped>
.base-pill {
  display: inline-flex;
  align-items: center;
  background-color: $light-gray;
  border-radius: 200px;
  padding: 6px 14px;
  text-align: center;
  font-weight: normal;
  font-size: 1rem;

  @media screen and (min-width: $screen-sm-min) {
    font-size: 1.4rem;
    font-weight: bold;
    min-width: 80px;
  }
}

.base-pill--success {
  background-color: $pale-green;
  color: $deep-jungle-green;
}

.base-pill--warning {
  background-color: $lemon-chiffon;
  color: $chocolate;
}

.base-pill--danger {
  background-color: $bubble-gum;
  color: $rose-wood;
}

.base-pill--learnamp {
  background-color: $blue-chalk;
  color: $indigo;
}

.base-pill--small {
  padding: 4px 12px;

  @media screen and (min-width: $screen-sm-min) {
    font-size: 1.2rem;
    font-weight: normal;
    min-width: initial;
  }
}

.base-pill__icon {
  margin-right: 0.5rem;
}
</style>
