<template>
  <h1 class="title__text">{{ title }}</h1>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.title__text {
  &::after {
    content: '';
    display: block;
    left: 0;
    height: 6px;
    width: 400px;
    max-width: 100%;
    margin-top: 30px;
    background-color: var(--button-color);
  }
}
</style>
