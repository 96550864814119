<template>
  <div
    :class="{
      'progress-bar--indeterminate': indeterminate,
      'progress-bar--line': line,
      'progress-bar--brand': brand,
    }"
    class="progress"
  >
    <div
      data-test-id="progress-bar-label"
      :class="{ error }"
      class="progress-bar"
      role="progressbar"
      :aria-valuenow="value"
      aria-valuemin="0"
      :aria-valuemax="max"
      :style="progressBarStyles"
    >
      {{ text }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProgressBar',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    displayPercents: {
      type: Boolean,
      default: true,
    },
    displayValue: {
      type: Boolean,
      default: true,
    },
    error: Boolean,
    indeterminate: Boolean,
    line: Boolean,
    brand: Boolean,
  },
  computed: {
    progressBarStyles() {
      return {
        width: !this.indeterminate && `${this.percentValue}%`,
      };
    },
    percentValue: function () {
      return (100 / this.max) * this.value;
    },
    text: function () {
      if (this.indeterminate) {
        return '';
      }

      const percentValue = `${Math.round(this.percentValue)}%`;
      const value = `${this.value}/${this.max}`;

      if (this.displayPercents && this.displayValue) {
        return `${percentValue} (${value})`;
      }

      if (this.displayPercents) {
        return percentValue;
      }

      if (this.displayValue) {
        return value;
      }

      return '';
    },
  },
};
</script>
<style scoped lang="scss">
.progress {
  --progress-background-color: #{$green};

  // Override Bootstrap margin
  margin-bottom: 0;
}

.progress-bar {
  background-color: var(--progress-background-color);

  &.error {
    background-color: $brand-danger;
  }
}

$flare-width: 80px;
.progress-bar--brand {
  --progress-background-color: var(--button-color);
}
.progress-bar--indeterminate {
  .progress-bar {
    width: 100%;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      width: $flare-width;
      background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
      position: absolute;
      top: 0;
      bottom: 0;
      animation-name: sweep;
      animation-duration: 1.5s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }

  &.progress-bar--line {
    box-shadow: none;
    height: 4px;
    border-radius: 0;
    .progress-bar {
      box-shadow: none;
    }
  }
}

@keyframes sweep {
  0% {
    left: -#{$flare-width};
  }

  100% {
    left: 100%;
  }
}
</style>
