<template>
  <div class="feed-post-comment">
    <UserAvatar
      v-if="comment.user"
      :profile-link="comment.user.show_path"
      :src="comment.user.avatar"
      :title="comment.user.name"
      class="feed-post-comment__avatar"
      data-test-id="feed-post-comment-avatar"
      size="large"
    />
    <div v-if="isEditing" class="feed-post-comment__edit-container">
      <RichTextInput
        v-model:model-value="content"
        :editor-config="editorConfig"
        :placeholder="t('team_social_feed.post.add_comment')"
        :at-mention-params="{
          endpoint: atMentionEndpoint,
          entityId: `${comment.id}`,
          entityType: 'teams_post',
          model: t('activerecord.models.team.one').toLowerCase(),
        }"
        :init-config-type="TinyMceConfigTypes.inline"
        class="feed-post-comment__input"
        data-test-id="feed-post-comment-edit-input"
        rounded
      />
      <div class="feed-post-comment__edit-container__buttons">
        <BaseButton
          :action="onCancelEditing"
          :text="t('team_social_feed.form.cancel')"
          :disabled="loading"
          class="feed-post-comment__cancel-button"
          data-test-id="feed-post-comment-cancel-button"
          button-style="cancel"
        />
        <BaseButton
          :disabled="loading || comment.content === content"
          :action="onEditSubmit"
          :text="t('team_social_feed.form.edit')"
          :loading="loading"
          data-test-id="feed-post-comment-submit-button"
          size="small"
        />
      </div>
    </div>
    <template v-else>
      <div class="feed-post-comment__comment-container">
        <CommentBubble
          :comment="comment"
          class="feed-post-comment__bubble"
          data-test-id="feed-post-comment-bubble"
        />
        <div class="feed-post-comment__toolbar">
          <Like
            :liked="likes.liked"
            :liked-by="likes.liked_by"
            :likes-count="likes.count"
            class="m-y-1 feed-post-comment__like-button"
            @like-clicked="onLikeComment"
          />
          <BaseButton
            data-test-id="feed-post-child-comment-button"
            class="m-l-1 feed-post-comment__comment-button"
            :action="onComment"
            icon="reply"
            button-style="link"
          />
        </div>
      </div>
      <PipsMenu
        v-if="showMenu"
        type="muted"
        class="feed-post-comment__menu"
        data-test-id="feed-post-comment-pips"
      >
        <li v-if="canEdit">
          <JavascriptActionLink
            :label="t('team_social_feed.post.edit')"
            data-test-id="feed-post-comment-edit-button"
            @action="onEdit"
          />
        </li>
        <li v-if="canDelete">
          <JavascriptActionLink
            :label="t('team_social_feed.post.delete')"
            data-test-id="feed-post-comment-delete-button"
            @action="$emit('delete', comment.id)"
          />
        </li>
      </PipsMenu>
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, Ref, ref, watch } from 'vue';
import { useI18n } from '@/composables/useI18n';
import { useApiClient } from '@/composables/useApiClient';
import BaseButton from '@/components/atoms/Button/Index.vue';
import CommentBubble from '@/components/molecules/CommentBubble/Index.vue';
import JavascriptActionLink from '@/components/atoms/Menu/JavascriptActionLink.vue';
import Like from '@/components/atoms/Like/Index.vue';
import PipsMenu from '@/components/atoms/Menu/Pips.vue';
import RichTextInput, {
  OverrideTinymceEditorConfig,
} from '@/components/atoms/forms/RichTextInput/Index.vue';
import UserAvatar from '@/components/atoms/Avatar/Index.vue';
import { IFeedPostComment } from '@/types/Team/Post';
import { TinyMceConfigTypes } from '@/configs/tinymce';

export default defineComponent({
  name: 'FeedPostComment',
  components: {
    BaseButton,
    CommentBubble,
    JavascriptActionLink,
    Like,
    PipsMenu,
    RichTextInput,
    UserAvatar,
  },
  props: {
    initComment: {
      type: Object as PropType<IFeedPostComment>,
      required: true,
    },
    initIsEditing: Boolean,
    replyPath: String,
    atMentionEndpoint: String,
  },
  emits: ['new-comment', 'delete'],
  setup(props, { emit }) {
    const { apiClient } = useApiClient();
    const { t } = useI18n();
    const loading = ref(false);
    const comment = ref(props.initComment);

    const isEditing = ref(props.initIsEditing);
    const content = ref(props.initComment.content);
    const likes = ref(props.initComment.likes);
    const editorConfig: Ref<OverrideTinymceEditorConfig> = ref({
      toolbar: '',
      plugins: ['emoticons'],
    });

    const canEdit = computed(() => !!props.initComment.edit_path);
    const canDelete = computed(() => !!props.initComment.delete_path);
    const showMenu = computed(() => canEdit.value || canDelete.value);

    const onEdit = () => {
      isEditing.value = true;
    };

    const onEditSubmit = () => {
      loading.value = true;

      apiClient
        .patch(props.initComment.edit_path, { content: content.value })
        .then(
          (commentResponse: IEditResponse) => {
            comment.value.content = commentResponse.body.content;
          },
          () => {
            /* istanbul ignore next */
            console.log('Save error');
          }
        )
        .finally(() => {
          loading.value = false;
          isEditing.value = false;
        });
    };

    const onCancelEditing = () => {
      isEditing.value = false;
      loading.value = false;
      content.value = props.initComment.content;
    };

    const onLikeComment = async () => {
      const { liked, url } = likes.value;
      const likeParams = {
        like: { likeable_type: 'Teams::Comment', likeable_id: props.initComment.id },
      };
      let response;

      if (liked) {
        const deletePath = `${url}/${props.initComment.id}`;
        try {
          response = await apiClient.delete(deletePath, { body: likeParams });
        } catch (e) {
          // e
        }
      } else {
        try {
          response = await apiClient.post(url, likeParams);
        } catch (e) {
          // e
        }
      }
      if (response) {
        likes.value = response.body;
      }
    };

    const onComment = async () => {
      emit('new-comment');
    };

    watch(
      () => props.initComment.content,
      () => {
        // Close editor once comment has been saved in parent
        onCancelEditing();
      }
    );

    watch(
      () => props.initIsEditing,
      (newIsEditing) => {
        isEditing.value = newIsEditing;
      }
    );

    return {
      comment,
      canDelete,
      canEdit,
      content,
      editorConfig,
      isEditing,
      onComment,
      onLikeComment,
      likes,
      loading,
      onCancelEditing,
      onEditSubmit,
      onEdit,
      showMenu,
      t,
      TinyMceConfigTypes,
    };
  },
});

interface IEditResponse {
  body: {
    content: string;
  };
}
</script>

<style lang="scss" scoped>
.feed-post-comment {
  display: flex;
  align-items: flex-start;
}
.feed-post-comment__avatar {
  margin-right: 15px;
}
.feed-post-comment__menu {
  margin-left: 10px;
  // TODO - hack fix for weird global dropdown CSS
  & ::v-deep .dropdown-menu {
    top: 10px;
  }
}
.feed-post-comment__edit-container {
  width: 100%;
  margin-right: 20px;
}
.feed-post-comment__edit-container__buttons {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.feed-post-comment__bubble {
  flex-grow: 1;
}
.feed-post-comment__input {
  width: 100%;
}

.feed-post-comment__comment-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.feed-post-comment__toolbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.feed-post-comment__like-button {
  cursor: pointer;
}

.feed-post-comment__comment-button {
  padding: 0;
}
</style>
