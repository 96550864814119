export interface OneToOneAttemptProps {
  emitEdit: boolean;
  attempt: SurveyAttempt;
  tinymceStylesheet: string;
  selfRecordEnabled: boolean;
  displayQuestion: boolean;
  browser: string;
  browserNotSupported: boolean;
}

export enum ReviewRole {
  REVIEWEE = 'reviewee',
  REVIEWER = 'reviewer',
}

interface SurveyAnswerChoice {
  id: number;
  sentence: string;
}

interface SurveyPollColumn {
  id: number;
  value: string;
}

export enum SurveyQuestionType {
  RADIO_BUTTON = 'radio_button',
  DROP_DOWN = 'drop_down',
  POLL = 'poll',
  OPINION_SCALE = 'opinion_scale',
  MULTIPLE_CHOICE = 'multiple_choice',
  FREE_TEXT = 'free_text',
}

export type SurveyPollChoiceIds = { [id: string | number]: number };
export type SurveyCheckboxChoiceIds = number[];

export interface SurveyAnswer<A = SurveyPollChoiceIds | SurveyCheckboxChoiceIds> {
  id: number;
  question_id: string;
  answer?: string;
  choice_id?: number;
  choice_ids?: A;
  opinion_scale_score?: number;
}

export enum SurveyAnswersFrom {
  REVIEWEE = 'reviewee',
  REVIEWER = 'reviewer',
  BOTH = 'both',
}

export interface SurveyAttempt {
  id: number;
  can_answer: boolean;
  edit_url: string;
  submitted_at: string;
  started_at: string;
  overdue: boolean;
  completed: boolean;
  has_other_subject: boolean;
  survey_name: string;
  user_id: number;
  anonymous: boolean;
  user: object; // Todo: Update to be user interface
  answers: SurveyAnswer[];
  save_path: string;
  questions: SurveyQuestion[];
}

export interface SurveyQuestion {
  id: number;
  sentence: string;
  sentence_for: string;
  display_order: number;
  description: string;
  question_type: SurveyQuestionType;
  opinion_scale_min: number;
  opinion_scale_max: number;
  opinion_scale_optional_free_text: boolean;
  poll_columns_attributes: SurveyPollColumn[];
  choices_attributes: SurveyAnswerChoice[];
  answers: SurveyAnswer[];
}

export interface Survey {
  id: number;
  name: string;
  header_label: string;
  display_question: boolean;
  description: string;
  reviewer_attempt?: SurveyAttempt;
  reviewee_attempt: SurveyAttempt;
  private_survey: boolean;
  answers_from: SurveyAnswersFrom;
  single_page: boolean;
  dropdown_menu: {
    id: number;
    user_endpoint: string;
    carousel_notice_displayed: string;
    learnlist_notice_displayed: string;
    actions_endpoint: string;
    modal_copy: {
      header_copy: string;
      body_copy: string;
      continue_copy: string;
    };
  };
}

interface SurveySaveResponseError {
  errors: {
    text: string[];
  };
  question_id: number;
}
interface SurveySaveResponseData extends SurveyAttempt {
  errors?: SurveySaveResponseError[];
}

export interface SurveySaveResponse extends Response {
  data: SurveySaveResponseData;
}
