<template>
  <TableFilterWrapper :label="label" :label-for="$.uid.toString()">
    <input
      :id="$.uid.toString()"
      class="form-control"
      :value="modelValue"
      :placeholder="placeholder"
      @input="updateModel"
    />
  </TableFilterWrapper>
</template>

<script>
export default {
  compatConfig: {
    MODE: 3,
  },
};
</script>

<script setup>
import { defineEmits, defineProps } from 'vue';
import TableFilterWrapper from '@/components/atoms/Filter/TableFilterWrapper.vue';

const props = defineProps({
  placeholder: String,
  modelValue: String,
  label: String,
  debounced: Boolean,
  debounceMilliseconds: {
    type: Number,
    default: 1000,
  },
});

const emit = defineEmits(['update:modelValue']);

const updateModel = (event) => {
  if (props.debounced) {
    _.debounce(() => {
      emit('update:modelValue', event.target.value);
    }, props.debounceMilliseconds)();
  } else {
    emit('update:modelValue', event.target.value);
  }
};
</script>
