<template>
  <div class="search-input">
    <LoadingSpinner v-if="loading" data-test-id="menu-search-loading" type="xs" />
    <template v-else>
      <a
        href="#"
        data-test-id="menu-search-clear-icon"
        class="search-input__icon search-input__icon--tile-dropdown"
        :class="{ 'search-input__icon--clear': filterValue }"
        @click.prevent.stop="filterValue = ''"
      />
      <input
        v-model="filterValue"
        class="search-input__input search-input__input--tile-dropdown"
        type="text"
        :placeholder="t('helpers.buttons.search')"
        @click.prevent.stop="() => {}"
      />
      <li
        v-for="item in filteredItems"
        :key="item.id"
        :class="{ 'search-input--clicked': item.added }"
      >
        <a
          data-test-id="menu-search-item"
          href="#"
          @click.prevent="addItem(item, parentItemId, parentItemType)"
        >
          {{ truncate(item.name, 22) }}
          <i v-if="item.added" class="ic icon ic-check" />
        </a>
      </li>
    </template>
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';
import filterItems from '@/mixins/filterItems';
import filterTruncate from '@/mixins/filterTruncate';
import LoadingSpinner from '@/components/atoms/LoadingSpinner/Index.vue';
export default {
  name: 'MenuSearch',
  components: {
    LoadingSpinner,
  },
  mixins: [i18n, filterItems, filterTruncate],
  props: {
    fetchUrl: String,
    itemResponseKey: String,
    itemSearchField: String,
    itemSortKey: String,
    addItem: Function,
    parentItemId: Number,
    parentItemType: String,
  },
  data: function () {
    return {
      loading: true,
      filterValue: '',
      items: [],
      filteredItems: [],
      addItemClicked: true,
    };
  },
  watch: {
    filterValue: function () {
      this.filteredItems = this.filterItems(
        this.items,
        this.filterValue,
        this.itemSearchField,
        this.itemSortKey
      );
    },
  },
  mounted: function () {
    this.fetchItems();
  },
  methods: {
    fetchItems: function () {
      this.$http.get(this.fetchUrl).then((response) => {
        const items = response.body[this.itemResponseKey].map((item) => {
          item.name = item.title ? item.title : item.name;
          return item;
        });
        this.items = items;
        this.filteredItems = items;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.search-input__input {
  line-height: normal;
}
.search-input__icon--tile-dropdown {
  top: 1px;
}
li {
  &:hover,
  &:focus,
  &:active {
    background-color: $cultured;
  }
  a {
    display: block;
    line-height: normal;
    color: $dark-primary;
  }
}
.search-input--clicked {
  background-color: $ghost-white;
  &:hover,
  &:focus,
  &:active {
    background-color: $ghost-white;
  }
  .ic {
    margin-right: -15px;
    float: right;
    color: $persian-green;
  }
}
</style>
