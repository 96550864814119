<script lang="ts">
export default {
  name: 'SurveyQuestion',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import sanitizeHTML from 'sanitize-html';
import { defineProps, defineEmits } from 'vue';
import { SurveyAnswer, SurveyQuestion } from '@/types/Survey';
import TextArea from '@/components/atoms/forms/TextArea/Index.vue';
import InputHint from '@/components/atoms/forms/InputHint/Index.vue';

const emit = defineEmits<{
  (e: 'update:modelValue', value: SurveyAnswer): void;
}>();

const props = defineProps<{
  modelValue: SurveyAnswer;
  question: SurveyQuestion;
  inputId?: string;
  error?: string;
}>();

const questionSentence = sanitizeHTML(props.question.sentence_for || props.question.sentence);

const handleInput = (answer: string) => {
  const payload = {
    ...props.modelValue,
    answer,
  };
  emit('update:modelValue', payload);
};
</script>

<template>
  <label
    :for="inputId"
    class="survey-question-text-input__accessibility-label"
    data-test-id="survey-question-text-input-accessibility-label"
    v-html="questionSentence"
  />
  <TextArea
    v-bind="$attrs"
    :id="inputId"
    :rows="2"
    multiple
    :model-value="modelValue?.answer"
    @update:model-value="handleInput"
  />
  <InputHint v-if="error" :value="error" :error="!!error" class="m-t-1" />
</template>

<style lang="scss" scoped>
.survey-question-text-input__accessibility-label {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
</style>
