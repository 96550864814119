<template>
  <div class="button-radio-input" :class="{ 'button-radio-input--rounded': rounded }">
    <InputLabel
      v-if="label"
      :value="label"
      :required="required"
      :error="error"
      class="button-radio__label"
      data-test-id="button-radio-label"
    />
    <div class="button-radio-input__buttons">
      <ButtonRadio
        v-for="option in options"
        :key="option.value"
        :active="option.value === modelValue"
        :label="option.label"
        :name="name"
        :value="option.value"
        :small="small"
        class="button-radio-input__button"
        @change="$emit('update:modelValue', $event)"
      />
    </div>
    <InputHint
      v-if="hintText"
      :error="error"
      :value="hintText"
      class="button-radio-input__hint"
      data-test-id="button-radio-input-hint"
    />
  </div>
</template>

<script>
import ButtonRadio from '@/components/atoms/forms/ButtonRadio/Index.vue';
import InputLabel from '@/components/atoms/forms/InputLabel/Index.vue';
import InputHint from '@/components/atoms/forms/InputHint/Index.vue';

export default {
  name: 'ButtonRadioInput',
  compatConfig: {
    MODE: 3,
  },
  components: { ButtonRadio, InputLabel, InputHint },
  props: {
    error: Boolean,
    errorMessage: String,
    hint: String,
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: undefined,
    },
    rounded: Boolean,
    small: Boolean,
    disabled: Boolean,
  },
  computed: {
    hintText() {
      return this.error && this.errorMessage ? this.errorMessage : this.hint;
    },
  },
};
</script>

<style lang="scss" scoped>
$border-radius-rounded: 6px;

.button-radio-input__buttons {
  display: flex;
}
.button-radio-input__button {
  margin-left: -1px;
  flex-grow: 1;
}
.button-radio-input__hint {
  display: block;
  margin-top: 5px;
}

.button-radio-input--rounded {
  & ::v-deep .button-radio:first-of-type {
    border-top-left-radius: $border-radius-rounded;
    border-bottom-left-radius: $border-radius-rounded;
  }
  & ::v-deep .button-radio:last-of-type {
    border-top-right-radius: $border-radius-rounded;
    border-bottom-right-radius: $border-radius-rounded;
  }
}
</style>
