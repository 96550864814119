<template>
  <div class="notice-box__content" v-html="htmlContents" />
</template>

<script>
export default {
  name: 'NoticeBox',
  props: {
    htmlContents: String,
  },
};
</script>

<style scoped lang="scss">
.notice-box__content {
  background-color: $white-smoke;
  height: 100%;
  width: auto;
  padding: 2rem;
  line-height: 2.5rem;
}
</style>
