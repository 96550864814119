<template>
  <div>
    <draggable
      handle=".draggable-list__handle-icon"
      item-key="name"
      ghost-class="list-group-item--ghost"
      data-test-id="draggable-list"
      tag="ul"
      :list="value"
      :force-fallback="true"
      class="list-group"
    >
      <template #item="{ element }">
        <li class="list-group-item draggable-list__list-item">
          <span class="draggable-list__item-name">{{ element.name }}</span>
          <Icon
            data-test-id="drag-handle"
            class="draggable-list__handle-icon"
            icon-name="bars"
            size="large"
          />
          <BaseButton
            v-if="value && value.length > 1"
            data-test-id="drag-trash-icon"
            class="draggable-list__trash-icon"
            variant="icon"
            theme="error"
            prepend-icon="trash"
            size="large"
            @click="deleteItem(element.name)"
          />
        </li>
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import BaseButton from '@/components/atoms/BaseButton/Index.vue';
import Icon from '@/components/atoms/Icon/Index.vue';

export default {
  name: 'DraggableList',
  components: {
    BaseButton,
    draggable,
    Icon,
  },

  props: {
    list: Array,
  },

  data: function () {
    return {
      value: this.list,
    };
  },

  watch: {
    list: {
      handler: function (newVal) {
        this.value = newVal;
      },
      deep: true,
    },
  },

  created() {
    draggable.compatConfig = { MODE: 3 };
  },

  methods: {
    deleteItem: function (itemNameToDelete) {
      const index = this.value.findIndex((item) => item.name === itemNameToDelete);
      if (index > -1) {
        this.value.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.draggable-list__select-input {
  max-width: 350px;
  margin-right: auto;
}

.draggable-list__list-item {
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.draggable-list__item-name {
  user-select: none;
}

.draggable-list__handle-icon {
  margin: 0 8px 0 auto;
}

.draggable-list__trash-icon {
  position: absolute;
  top: 8px;
  right: -40px;
}

.handle {
  background: none;
  border: none;
}
.close {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}
input {
  display: inline-block;
  width: 50%;
}
.text {
  margin: 20px;
}
</style>
