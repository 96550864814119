<template>
  <!-- This is a Vue-wrapper for Tinymce.
  The event-docs are incorrect. Events require the 'on' prefix
  with the exception of 'input'. -->
  <Editor
    :key="configType"
    v-model="localValue"
    :initial-value="modelValue"
    :init="mergedEditorConfig"
    class="rt-input"
    :class="{
      empty: modelValue === '',
      'rt-input--dark': theme === 'dark',
      'rt-input--rounded': rounded,
    }"
    data-test-id="rich-text-user-input"
  />
</template>

<script lang="ts">
import tinymceConfigs, { TinyMceConfigTypes } from '@/configs/tinymce';
import tinymce, { Ui, Editor as IEditor } from 'tinymce';
import { editorProps } from '@tinymce/tinymce-vue/lib/cjs/main/ts/components/EditorPropTypes';
// Modules
import { defineComponent, Ref, ref, PropType, watch } from 'vue';
import _ from 'lodash';
// Composables
import { useI18n } from '@/composables/useI18n';
import { useAtMention, ITinyMceAtMentionProps } from '@/composables/useAtMention';
import { useApiClient } from '@/composables/useApiClient';
// Components
import Editor from '@tinymce/tinymce-vue';

export default defineComponent({
  name: 'RichTextInput',
  components: {
    Editor,
  },
  compatConfig: {
    MODE: 3,
  },
  props: {
    atMentionParams: Object as PropType<ITinyMceAtMentionProps>,
    initConfigType: {
      type: String as PropType<TinyMceConfigTypes>,
      default: TinyMceConfigTypes.default,
    },
    modelValue: {
      type: String,
      default: '',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    editorConfig: Object as PropType<typeof editorProps>,
    plugins: {
      type: Array,
      default: () => [],
    },
    theme: String as PropType<Themes>,
  },
  emits: ['update:model-value', 'link-inserted', 'tinymce-dialog-opened', 'tinymce-dialog-closed'],
  setup(props, { emit }) {
    const { apiClient } = useApiClient();
    const { t } = useI18n();
    const editor: Ref<IEditor | null> = ref(null);
    const editorId = ref();
    const placeholder = ref(t('team_social_feed.form.placeholder'));
    const configType = ref(props.initConfigType);
    const localValue = ref(props.modelValue);

    watch(
      () => props.modelValue,
      (newValue) => {
        localValue.value = newValue;
      }
    );

    const aiRetoneConfiguration = [
      { caption: t('vue_templates.components.rich_text_input.witty'), value: 'witty' },
      {
        caption: t('vue_templates.components.rich_text_input.conversational'),
        value: 'conversational',
      },
      {
        caption: t('vue_templates.components.rich_text_input.professional'),
        value: 'professional',
      },
    ];

    const aiRewriteTextModalInit = (editorContext, command, params = {}) => {
      editor.value?.fire('contexttoolbar-hide', { toolbarKey: 'vue_textselection_ai' });
      window.emitter.emit('ai-rewrite-modal-init', {
        command,
        text: editorContext.getContent(),
        callback: (rewrittenText: string) => {
          editorContext.setContent(rewrittenText);
        },
        ...params,
      });
    };

    const aiRetoneTextModalInit = (editorContext, toneValue) => {
      aiRewriteTextModalInit(editorContext, 'rewrite', { extra_command: `in a ${toneValue} tone` });
    };

    const setup = {
      setup: (editor: IEditor) => {
        editorId.value = editor.id;

        editor.on('change redo undo input paste', () => {
          emit('update:model-value', editor.getContent());
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        editor.on('execCommand', (e: any) => {
          handleExecCommand(e);
        });

        editor.on('openWindow', async () => {
          emit('tinymce-dialog-opened');
          setTimeout(() => {
            const firstInteractiveElement = $('.tox-dialog__body [tabindex]').first();
            if (firstInteractiveElement) {
              firstInteractiveElement.trigger('focus');
            }
          }, 100);
        });

        editor.on('closeWindow', async () => {
          emit('tinymce-dialog-closed');
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        editor.on('init', (e: any) => {
          handleEditorInit(e, editor);
        });

        editor.on('setContent', () => {
          handleSetContent();
        });

        /* istanbul ignore next */
        editor.ui.registry.addButton('vue_simple_toggle', {
          text: t('vue_templates.components.rich_text_input.simple_editor'),
          onAction: async () => {
            editor.destroy();
            configType.value = TinyMceConfigTypes.simple;
          },
        });
        /* istanbul ignore next */
        editor.ui.registry.addButton('vue_advanced_toggle', {
          text: t('vue_templates.components.rich_text_input.advanced_editor'),
          onAction: async () => {
            editor.destroy();
            configType.value = props.initConfigType;
          },
        });
        /* istanbul ignore next */
        editor.ui.registry.addButton('vue_la_quick_add', {
          icon: 'search',
          tooltip: t('vue_templates.components.rich_text_input.search_learn_amp_for_content'),
          onAction: function () {
            window.emitter.emit('show-quick-add', { type: 'Tinymce', id: 1, editor });
          },
        });
        /* istanbul ignore next */
        editor.ui.registry.addMenuItem('vue_la_quick_add', {
          text: t('vue_templates.components.rich_text_input.search_and_link'),
          icon: 'search',
          onAction: function () {
            window.emitter.emit('show-quick-add', { type: 'Tinymce', id: 1, editor });
          },
        });
        /* istanbul ignore next */
        editor.ui.registry.addButton('vue_selection_ai_rewrite', {
          text: `<i class="ic ic--large ic-sparkle text-muted m-r-1"></i> ${t(
            'vue_templates.components.rich_text_input.rewrite'
          )}`,
          onAction: () => {
            aiRewriteTextModalInit(editor.selection, 'rephrase');
          },
        });
        /* istanbul ignore next */
        editor.ui.registry.addButton('vue_selection_ai_expand', {
          text: `<i class="ic ic--large ic-sparkle text-muted m-r-1"></i> ${t(
            'vue_templates.components.rich_text_input.expand'
          )}`,
          onAction: () => {
            aiRewriteTextModalInit(editor.selection, 'expand');
          },
        });
        /* istanbul ignore next */
        editor.ui.registry.addButton('vue_selection_ai_summarise', {
          text: `<i class="ic ic--large ic-sparkle text-muted m-r-1"></i> ${t(
            'vue_templates.components.rich_text_input.summarise'
          )}`,
          onAction: () => {
            aiRewriteTextModalInit(editor.selection, 'summarise');
          },
        });
        /* istanbul ignore next */
        editor.ui.registry.addMenuButton('vue_selection_ai_change_tone', {
          text: `<i class="ic ic--large ic-sparkle text-muted m-r-1"></i> ${t(
            'vue_templates.components.rich_text_input.change_tone'
          )}`,
          fetch(callback) {
            const items: Ui.Menu.NestedMenuItemContents[] = aiRetoneConfiguration.map(
              ({ caption, value }) => ({
                type: 'menuitem',
                text: caption,
                onAction: function () {
                  aiRetoneTextModalInit(editor.selection, value);
                },
              })
            );
            callback(items);
          },
        });
        editor.ui.registry.addContextToolbar('vue_textselection_ai', {
          predicate: () => {
            if (configType.value !== TinyMceConfigTypes.default_with_ai) return false;

            const selection = editor.selection;
            if (selection.isCollapsed()) return false;

            const content = selection.getContent();
            const minChars = 30;
            const minWords = 10;
            return content.length > minChars && content.split(' ').length >= minWords;
          },
          items:
            'vue_selection_ai_rewrite vue_selection_ai_expand vue_selection_ai_summarise vue_selection_ai_change_tone',
          position: 'selection',
          scope: 'node',
        });

        /* istanbul ignore next */
        editor.ui.registry.addMenuButton('vue_ai_button', {
          text: "<i class='ic-ai-tool' role='presentation'></i>",
          tooltip: t('vue_templates.components.rich_text_input.ai_tools'),
          fetch: function (callback) {
            const items: Ui.Menu.NestedMenuItemContents[] = [
              {
                type: 'menuitem',
                text: 'Regenerate',
                onAction: function () {
                  window.emitter.emit('ai-regenerate-modal-init', {
                    callback: (regeneratedText: string) => {
                      editor.setContent(regeneratedText);
                    },
                  });
                },
              },
              {
                type: 'nestedmenuitem',
                text: t('vue_templates.components.rich_text_input.change_tone'),
                getSubmenuItems: function () {
                  return aiRetoneConfiguration.map(({ caption, value }) => {
                    return {
                      type: 'menuitem',
                      text: caption,
                      onAction: function () {
                        aiRetoneTextModalInit(editor, value);
                      },
                    };
                  });
                },
              },
            ];
            callback(items);
          },
        });
      },
    };
    const mergedEditorConfig = ref(
      _.merge(tinymceConfigs[configType.value], { placeholder }, props.editorConfig, setup)
    );

    watch(configType, () => {
      mergedEditorConfig.value = _.merge(
        tinymceConfigs[configType.value],
        { placeholder: placeholder.value },
        props.editorConfig,
        setup
      );
    });

    const { buildTinymceAtMentionParams, initialiseTinymceAtMention } = useAtMention(apiClient);

    const handleEditorInit = (_event: Event | null, editorInstance: IEditor) => {
      editor.value = editorInstance;

      if (props.atMentionParams) {
        const { endpoint, entityId, entityType = '', teamId, model } = props.atMentionParams;

        if (endpoint && entityId) {
          const params = buildTinymceAtMentionParams(entityType, entityId, teamId);
          initialiseTinymceAtMention(
            editorInstance.getBody(),
            endpoint,
            params,
            (model && t('helpers.common.atwho_header', { model })) || ''
          );
        }
      }
    };

    const clearEditor = () => {
      const richTextEditor = tinymce.get(editorId.value);
      if (richTextEditor) {
        richTextEditor.setContent('');
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleExecCommand = (e: any) => {
      if (e && e.command === 'createlink') {
        emit('link-inserted', e.value);
      }
    };

    const handleSetContent = () => {
      if (!editor.value?.initialized) return;
      emit('update:model-value', editor.value.getContent());
    };

    return {
      localValue,
      handleSetContent,
      mergedEditorConfig,
      handleEditorInit,
      handleExecCommand,
      configType,
      aiRewriteTextModalInit,
      aiRetoneTextModalInit,
      t,
      emit,
      clearEditor,
    };
  },
});

type Themes = 'light' | 'dark';
export type TinymceEditorConfig = typeof editorProps;
export type OverrideTinymceEditorConfig = TinymceEditorConfig[keyof TinymceEditorConfig];
</script>

<style scoped lang="scss">
@mixin light-background {
  background-color: white;
  border: 2px $white-smoke solid;
}

@mixin dark-background {
  background-color: #f3f5f7;
  border: 2px white solid;
}

.rt-input {
  min-height: 40px;
  background-color: white;
  border: 1px $lavendar-gray solid;
  width: 100%;
  border-radius: 5px;
  vertical-align: middle;
  line-height: 20px;
  padding: 10px 10px 0 10px;
}

.rt-input:focus {
  border: solid 1px var(--button-color);
  box-shadow: 0px 0px 5px 1px var(--button-color);
}

.rt-input--dark {
  @include dark-background;
  &:not(.empty) {
    @include light-background;
  }
}

.rt-input--dark:focus {
  @include light-background;
  box-shadow: none;
}

.rt-input--rounded {
  border-radius: 20px;
  padding: 10px 20px 0 20px;

  // Align tinymce placeholder
  &.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
    padding-left: 20px;
  }
}

// Align tinymce placeholder
.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  padding-left: 10px;
}

// remove default tinymce outline
* [contentEditable='true']:focus {
  outline: 0px;
}

// Display placeholder when we consider the input empty
* [contentEditable='true'].empty:before {
  color: #222f3eb3;
  display: block;
  position: absolute;
  content: attr(data-mce-placeholder);
  pointer-events: none;
}

* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>
