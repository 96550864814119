<template>
  <div v-if="json" id="tile-template" data-test-id="component-tile">
    <article :class="classContainer">
      <TileHeader
        :show-thumb-grid="showThumbGrid"
        :image-url="imageUrl"
        :image-urls="json.image_urls"
        :tint="json.tint"
        :is-new="json.is_new"
        :completed="json.completed"
        :joined="json.joined"
        :applied="json.applied"
        :is-private="json.privacy === 'Private'"
        :locked="locked"
        :locked-until-tasked="lockedUntilTasked"
        :url="json.url"
        :name="name"
      />

      <TileBody
        :progress="json.progress"
        :showing-widget="showingWidget"
        :name="name"
        :sub-heading="subHeading"
        :type="type || json.type"
        :type-label="json.type_label"
        :learnlist-item-count="json.learnlist_items_count"
        :questions-count="json.questions_count"
        :time="json.time"
        :total-time="json.total_time"
        :description="json.description"
        :user="json.user"
        :assessor="json.assessor"
        :location="json.location"
        :dates="json.dates"
        :event-sessions-count="json.event_sessions_count"
      >
        <slot name="body-properties"> </slot>
      </TileBody>

      <TileFooter
        :id="json.id"
        :deadline="json.deadline"
        :overdue="json.overdue"
        :mandatory="json.mandatory"
        :expired="json.expired"
        :hidden="json.hidden"
        :liked="json.liked"
        :likeable-type="json.tile_type"
        :use-legacy-menu="useLegacyMenu"
      >
        <slot name="footer-content"> </slot>
        <tile-dropdown
          v-if="useLegacyMenu && !previewing && !locked && json.available_actions_path"
          :up="true"
          :tile="json"
          :is-desktop="isDesktop"
          :user-endpoint="userEndpoint"
          :carousel-notice-displayed="carouselNoticeDisplayed"
          :learnlist-notice-displayed="learnlistNoticeDisplayed"
          :modal-copy="{
            headerCopy: t('vue_templates.quick_add.completion_notice.title'),
            bodyCopy: itemCompleteModalBodyCopy,
            updateLearnlistCopy: t('vue_templates.learnlist_dropdown.add_to_channel_modal.body'),
            continueCopy: t('helpers.common.continue'),
          }"
          :in-tile="true"
        />
        <CustomMenu v-else dropup :available-actions="availableActions" :json="json" />
      </TileFooter>
    </article>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from '@/composables/useI18n';
import { useTileProps } from '@/composables/useTileProps';
import { TileHeader, TileBody, TileFooter } from '@/components/atoms/Tile';
import CustomMenu from '@/components/molecules/Menu.vue';
import { mdAndGreater, useMobileFirst } from '@/composables/useMobileFirst';

export default defineComponent({
  name: 'BaseTile',

  components: {
    CustomMenu,
    TileHeader,
    TileBody,
    TileFooter,
  },

  props: {
    ...useTileProps('partial'),
  },

  setup(props) {
    const { t } = useI18n();
    const menuItems = ref([]);

    const name = computed(() => {
      return props.displayName || props.json?.name;
    });

    const imageUrl = computed(() => {
      return props.itemImageUrl || props.json?.image_url;
    });

    const isDesktop = computed(() => {
      return mdAndGreater.value;
    });

    const responsiveClasses = useMobileFirst('tile');

    const classContainer = computed(() => ({
      ['tile--' + props.type?.toLowerCase()]: true,
      ...responsiveClasses,
    }));

    const showThumbGrid = computed(() => {
      return (
        props.type === 'Learnlist' &&
        props.json?.tile_image_type === 'item_based' &&
        props.json.image_urls &&
        props.json.image_urls.length > 0
      );
    });

    const itemCompleteModalBodyCopy = computed(() => {
      return {
        title: `<p>${t('vue_templates.quick_add.completion_notice.title')}</p>`,
        body: t('vue_templates.quick_add.completion_notice.body'),
        checkbox_label: t('vue_templates.quick_add.completion_notice.dont_show_again'),
      };
    });

    return {
      classContainer,
      imageUrl,
      isDesktop,
      itemCompleteModalBodyCopy,
      menuItems,
      name,
      showThumbGrid,
      t,
    };
  },
});
</script>

<style scoped lang="scss">
@import 'app/javascript/src/components/atoms/Tile/styles.scss';

.tile {
  height: 280px;
  width: $tile-width;
  min-width: $tile-width;
  margin: 20px auto;
  background-color: $tile-bg-color;
  border-radius: 6px;
  box-shadow: 0 0 9px rgba($tile-shadow, 0.34);
  color: $tile-color;
  font-size: 14px;
  position: relative;
  text-decoration: none;
  transition: all 0.2s;
  overflow: hidden;

  &:hover {
    box-shadow: 0 0 10px rgba($new-blue-light, 0.8);
    transform: scale(1.03);
    text-decoration: none;
  }
}

.tile--md-and-greater {
  height: inherit;
  width: $tile-width-desktop;
  min-width: $tile-width-desktop;
}

.tile .new_like-button {
  z-index: 3;
  font-size: 2em;
  margin-top: 3px;
  margin-right: 2px;
  display: inline-block;

  &:focus {
    outline: none;
  }
}

.tile--add {
  height: $header-height + $body-height;
  padding: 14px;
  color: $link-color;
  font-size: 14px;
  text-align: center;

  .tile--add__content {
    width: 100%;
    height: 100%;
    padding: 15px;
    border: 2px dashed $platinum;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $lavendar-gray;
  }

  strong {
    width: 100%; // IE11 fix for long texts
    display: block;
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: 500;
    color: $cool-grey;
  }

  .ic {
    margin-bottom: 42px;
    padding: 29px;
    border: 3px solid $lavendar-gray;
    border-radius: 100px;
    color: $lavendar-gray !important;
    font-size: 32px;
  }
}

.tile--add:hover,
.tile--add:focus,
.tile--add:active {
  color: $dark-primary;

  .ic {
    color: $dark-primary;
  }
}

.tile--team {
  min-width: 360px;
  width: 350px;

  .tile__header {
    height: 204px;
  }

  .tile__body {
    height: 136px;
  }

  .tile__link {
    height: 300px;
  }
}
</style>
