<script lang="ts">
export default {
  name: 'MultipleChoiceQuestion',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps } from 'vue';
import IQuizQuestion from '@/types/QuizQuestion';

defineProps<{ question: IQuizQuestion }>();
</script>

<template>
  <div class="multiple-choice-question">
    <img
      v-if="question.image"
      :src="question.image"
      alt=""
      class="quiz-question__image"
      data-test-id="quiz-question-image"
    />
    <div
      class="quiz-question__sentence"
      data-test-id="quiz-question-sentence"
      v-html="question.sentence"
    ></div>
    <div class="quiz-question__answer-group">
      <button
        v-for="answer in question.answers"
        :key="answer.id"
        class="quiz-question__answer-option"
        data-test-id="quiz-question-answer-option"
        @click="(e) => e.preventDefault()"
        v-html="answer.sentence"
      ></button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/_quiz-question.scss';
</style>
