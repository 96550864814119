import { computed, Ref, ref } from 'vue';
import { useI18n } from '@/composables/useI18n';
import { notify } from '@kyvg/vue3-notification';

function isAbortError(e: unknown): e is DOMException {
  return !!(e && e instanceof DOMException && e.name === 'AbortError');
}

export function useVideoUpload(
  emit: (
    event: 'video-added' | 'video-preview-loaded' | 'video-removed',
    ...args: unknown[]
  ) => void,
  getPresignedHostUrl: (endpoint: string, filename: string) => Promise<IPresignUrl>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadToHost: (endpoint: string, data: any, config: object) => Promise<void>,
  videoPathProp: Ref,
  presignedHostEndpoint: string
) {
  const { t } = useI18n();
  const authenticatedVideoUrl = ref('');
  const showDropzone = ref(false);
  const videoUploading = ref(false);
  const videosAttached: Ref<File[]> = ref([]);
  const abortUploadController = new AbortController();
  const abortSignal = abortUploadController.signal;

  const handleVideoRemoved = () => {
    videosAttached.value = [];
    authenticatedVideoUrl.value = '';
    emit('video-removed');
    deleteVideoFromS3();
  };

  const videoButtonTooltipText = computed(() => {
    return videoPathProp.value
      ? t('team_social_feed.form.video_already_added_tooltip')
      : t('team_social_feed.form.add_video_tooltip');
  });

  const toggleVideoDropzone = (force?: boolean) => {
    if (force !== undefined) {
      showDropzone.value = force;
    } else {
      showDropzone.value = !showDropzone.value;
    }
  };

  const cancelVideoUpload = () => {
    abortUploadController.abort();
    videoUploading.value = false;
    toggleVideoDropzone();
    handleVideoRemoved();
  };

  const deleteVideoFromS3 = () => {
    // TODO: Create request to delete video from S3
  };

  const handleVideoUpload = async (files: File[]) => {
    videoUploading.value = true;
    const file = files[0];
    videosAttached.value = files;

    try {
      const {
        presigned_url,
        authenticated_url,
        stripped_url: fileUrl,
      } = await getPresignedHostUrl(presignedHostEndpoint, file.name);
      await uploadToHost(presigned_url, file, { signal: abortSignal });

      toggleVideoDropzone(false);
      videosAttached.value = [];

      emit('video-added', fileUrl);
      emit('video-preview-loaded', authenticated_url);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // If user intentionally aborts, we want to skip the error notification.
      if (isAbortError(e)) {
        return;
      }

      notify({
        type: 'error',
        title: t('team_social_feed.form.unable_to_upload_video'),
        text: e,
        duration: -1,
      });
    }
    videoUploading.value = false;
  };

  return {
    videosAttached,
    videoButtonTooltipText,
    showDropzone,
    videoUploading,
    toggleVideoDropzone,
    handleVideoRemoved,
    handleVideoUpload,
    cancelVideoUpload,
  };
}
