<template>
  <div class="feed-post-footer">
    <div class="feed-post-footer__input-container">
      <UserAvatar
        v-if="currentUser"
        :src="currentUser.avatar"
        :profile-link="currentUser.show_path"
        :title="currentUser.name"
        class="feed-post-footer__avatar"
        data-test-id="feed-post-footer-avatar"
        size="large"
      />
      <RichTextInput
        ref="feedPostTextInput"
        :model-value="modelValue"
        :editor-config="editorConfig"
        :placeholder="t('team_social_feed.post.add_comment')"
        :at-mention-params="{
          endpoint: atMentionEndpoint,
          entityId: `${postId}`,
          entityType: 'teams_post',
          model: t('activerecord.models.team.one').toLowerCase(),
        }"
        :init-config-type="TinyMceConfigTypes.inline"
        class="feed-post-footer__input"
        data-test-id="feed-post-footer-input"
        rounded
        @update:model-value="$emit('update:model-value', $event)"
      />
    </div>
    <SwapTransition direction="down">
      <div v-if="modelValue" class="feed-post-footer__button-container">
        <BaseButton
          :action="() => $emit('submit')"
          :text="t('team_social_feed.form.comment')"
          :disabled="loading"
          :loading="loading"
          data-test-id="feed-post-footer-post-button"
          size="small"
        />
      </div>
    </SwapTransition>
  </div>
</template>

<script lang="ts">
export default {
  name: 'FeedPostFooter',
  compatConfig: {
    MODE: 3,
  },
};
</script>

<script setup lang="ts">
import { defineProps, Ref, ref, watch } from 'vue';
import { useI18n } from '@/composables/useI18n';
import BaseButton from '@/components/atoms/Button/Index.vue';
import SwapTransition from '@/components/atoms/Transitions/Swap.vue';
import RichTextInput, {
  OverrideTinymceEditorConfig,
} from '@/components/atoms/forms/RichTextInput/Index.vue';
import UserAvatar from '@/components/atoms/Avatar/Index.vue';
import { TinyMceConfigTypes } from '@/configs/tinymce';

const props = defineProps({
  loading: Boolean,
  user: Object,
  currentUser: Object,
  modelValue: String,
  isFocused: Boolean,
  postId: Number,
  atMentionEndpoint: String,
});

const { t } = useI18n();

// Template ref
const feedPostTextInput = ref();

const editorConfig: Ref<OverrideTinymceEditorConfig> = ref({
  toolbar: '',
  plugins: ['emoticons'],
});

watch(
  () => props.isFocused,
  (newFocus) => {
    if (newFocus) {
      const inputElement = $(feedPostTextInput.value.$el);
      const offset = inputElement.offset();
      if (inputElement && offset?.top) {
        const topOffset = offset.top;
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: topOffset - 200,
          },
          500,
          () => {
            inputElement.trigger('focus');
          }
        );
      }
    }
  }
);
</script>

<style lang="scss" scoped>
.feed-post-footer {
  background-color: $white-smoke;
  padding: 20px;
}
.feed-post-footer__input-container {
  display: flex;
  align-items: center;
}
.feed-post-footer__button-container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.feed-post-footer__avatar {
  margin-right: 15px;
}
.feed-post-footer__input {
  flex-grow: 1;
}
</style>
