<template>
  <span
    data-test-id="tooltip-link"
    class="ic tooltip__help"
    :class="{
      [`ic-${icon}`]: icon,
      tooltip__muted: type === 'muted',
      'ic--red': type === 'overdue',
    }"
    :title="formattedTitle"
    :data-html="html"
    :data-template="template"
    data-container="body"
    data-toggle="tooltip"
    data-trigger="hover"
  />
</template>

<script lang="ts">
import { computed, PropType, ref } from 'vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseTooltip',
  props: {
    icon: String,
    type: String as PropType<ToolTipTypes>,
    title: {
      type: String,
      required: true,
    },
    listItems: Array as PropType<string[]>,
    html: Boolean,
  },

  setup(props) {
    const formattedTitle = ref<string>(props.title);

    const buildListTooltip = () => {
      const titleElement = document.createElement('h5');
      titleElement.innerHTML = props.title;

      let ulElement: HTMLUListElement = document.createElement('ul');
      if (props.listItems) {
        props.listItems.forEach((listItem: string) => {
          const liElement = document.createElement('li');
          liElement.innerHTML = listItem;
          ulElement.appendChild(liElement);
        });
      }

      let htmlString = titleElement.outerHTML;
      htmlString += ulElement.outerHTML;
      return htmlString;
    };

    if (props.listItems) {
      formattedTitle.value = buildListTooltip();
    }

    const template = computed(() => {
      let classes = 'tooltip';

      if (props.type === 'list') {
        classes += ' tooltip__list-body';
      }

      return `<div class="${classes}"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>`;
    });

    return {
      template,
      formattedTitle,
    };
  },
});

type ToolTipTypes = 'muted' | 'overdue' | 'list';
</script>

<style scoped lang="scss">
.tooltip__help,
.tooltip__help:hover,
.tooltip__help:focus {
  color: inherit;
  font-size: 14px;
  position: relative;
  top: -4px;
}

.tooltip__muted {
  color: $dark-electric-blue;
}

// Icon specific positioning to account for different sizes
.ic-info,
.ic-info:hover {
  top: -1px;
}

.ic--red,
.ic--red:hover {
  color: $red;
}
</style>

<style lang="scss">
// Styling for custom tooltip body template
.tooltip__list-body {
  .tooltip-inner {
    padding: 3px 10px;
    text-align: left;

    ul {
      padding-left: 0;
      margin-bottom: 3px;
      list-style-position: outside;
      margin-left: 1rem;
    }

    strong {
      display: block;
    }

    li {
      padding-bottom: 6px;
    }

    li:last-child {
      padding-bottom: 3px;
    }
  }
}
</style>
