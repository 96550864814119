<template>
  <div class="text-center">
    <div
      data-test-id="loading-spinner"
      class="loader"
      :class="{
        'loader--xl': type === 'xl',
        'loader--sm': type === 'small',
        'loader--xs': type === 'xs',
        'loader--xxs': type === 'xxs',
        'loader--icon': type === 'icon',
      }"
    />
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    type: {
      type: String,
      validator: (propValue) => {
        const types = ['small', 'xs', 'xxs', 'icon'];
        return types.find((type) => type === propValue);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.loader {
  animation: sk-rotateplane 1.2s infinite linear;
  background-size: cover;
  height: 100px;
  margin: 50px auto;
  width: 100px;
}

.loader--xl {
  height: 100px;
  margin: 25px auto;
  width: 100px;
}

.loader--sm {
  height: 50px;
  margin: 25px auto;
  width: 50px;
}

.loader--xs {
  height: 30px;
  margin: 25px auto;
  width: 30px;
}

.loader--xxs {
  height: 25px;
  margin: 0 auto;
  width: 25px;
}

.loader--icon {
  display: inline-block;
  height: 14px;
  width: 14px;
}

@keyframes sk-rotateplane {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
