<template>
  <a class="text-link__link">
    <slot />
  </a>
</template>

<script>
export default {
  // // TODO: Remove when Vue build is upgraded to full version 3.
  compatConfig: {
    MODE: 3,
  },
};
</script>

<style lang="scss" scoped>
.text-link__link {
  text-decoration: none;
  font-weight: 500;
  color: $link-color;

  &:hover,
  &:focus {
    color: darken($link-color, 20%);
    cursor: pointer;
  }
}
</style>
