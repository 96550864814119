<template>
  <th
    :class="{
      'col--sticky': sticky,
      'table-header--centered': centered,
    }"
  >
    <a
      v-if="sortable"
      data-test-id="table-header-sort-button"
      class="table--data__sorter"
      :class="{
        'table--data__sorter--sorting': currentSort === sortKey,
        'table--data__sorter--asc': sortOrder > 0,
      }"
      @click="onClick"
    >
      {{ heading }}
    </a>
    <template v-else>
      {{ heading }}
      <template v-if="subHeading">
        <br />
        <small class="text-muted">{{ subHeading }}</small>
      </template>
    </template>
  </th>
</template>

<script>
export default {
  name: 'TableHeader',
  props: {
    centered: Boolean,
    heading: String,
    subHeading: String,
    sticky: Boolean,
    sortable: Boolean,
    currentSort: String,
    sortKey: String,
    defaultOrder: Number,
    sortOrder: Number,
    clickHandler: Function,
  },

  methods: {
    onClick: function () {
      this.clickHandler(this.sortKey, this.defaultOrder);
    },
  },
};
</script>

<style scoped lang="scss">
.table-header--centered {
  text-align: center;
}
</style>
