<template>
  <a href="#" @click.prevent="$emit('action', actionName, payload)">
    {{ label }}
  </a>
</template>

<script>
export default {
  name: 'JavascriptActionLink',

  props: {
    actionName: String,
    payload: [String, Array, Object],
    label: String,
  },
};
</script>

<style scoped lang="scss"></style>
