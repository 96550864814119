/* eslint-disable @typescript-eslint/no-explicit-any */

import { IApiClient } from '@/types/composables/useApiClient';

export function useAwsS3(authorizedApiClient: IApiClient, fetchApiClient: IApiClient) {
  function getPresignedS3Url(endpoint: string, filename: string): Promise<IPresignUrl> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return authorizedApiClient
      .post(endpoint, undefined, { params: { filename } })
      .then((response: any) => ({
        presigned_url: response.body.presigned_url,
        authenticated_url: response.body.authenticated_url,
        stripped_url: response.body.stripped_url,
      }));
  }

  function uploadToS3(url: string, file: any, config: object): Promise<void> {
    return fetchApiClient.put(url, file, { 'Content-Type': file.type, ...config });
  }

  return {
    getPresignedS3Url,
    uploadToS3,
  };
}
