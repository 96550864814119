<template>
  <div class="dropdown-list btn-group" :class="{ open: manuallyShowDropdown }">
    <button v-if="filterLabel" class="btn btn-default btn--label">{{ filterLabel }}</button>
    <FilterButton
      v-if="colour"
      :class="{
        'dropdown-list__button--no-label': !filterLabel,
        disabled: disabled,
      }"
      :text="filterLabel"
      aria-expanded="false"
      aria-haspopup="true"
      class="dropdown-toggle"
      data-toggle="dropdown"
      data-test-id="dropdown-toggle-colour"
      selected
      @click="onFilterButtonClick"
    >
      <slot>
        <strong v-if="loading" :class="{ 'dropdown-list__label--bold': filterLabel }">
          {{ t('helpers.common.loading') }}
        </strong>
        <template v-else>
          <Icon v-if="icon" class="dropdown-list__filter-icon" :icon-name="icon" />
          <span :class="{ 'dropdown-list__label--bold': filterLabel }">{{ label }}</span>
          <span class="caret" />
        </template>
      </slot>
    </FilterButton>
    <button
      v-else
      class="btn btn-default dropdown-toggle"
      aria-expanded="false"
      aria-haspopup="true"
      data-toggle="dropdown"
      data-test-id="dropdown-toggle"
      type="button"
      :class="{
        disabled: disabled,
      }"
    >
      <strong v-if="loading" :class="{ 'dropdown-list__label--bold': filterLabel }">
        {{ t('helpers.common.loading') }}
      </strong>
      <template v-else>
        <Icon v-if="icon" class="dropdown-list__filter-icon" :icon-name="icon" />
        <span :class="{ 'dropdown-list__label--bold': filterLabel }">{{ label }}</span>
        <span class="caret" />
      </template>
    </button>
    <ul
      class="dropdown-menu"
      :class="{ [`pull-${alignment}`]: true, 'max-height-dropdown': scrollable }"
      role="menu"
    >
      <slot>
        <li
          v-for="(item, index) in items"
          :key="index"
          :data-test-id="`dropdown-menu-list-${getDropdownIdentifier(item)}`"
          role="menuitem"
        >
          <!-- Element is interactive - href set with v-bind -->
          <!-- eslint-disable vuejs-accessibility/click-events-have-key-events, vuejs-accessibility/no-static-element-interactions -->
          <a
            v-bind="linkAttributes(item)"
            class="dropdown-list__item-link"
            @click.prevent="item.dataRemote ? null : clickHandler($event, item.value)"
          >
            <Icon
              v-if="item.icon"
              class="dropdown-list__item-icon dropdown-list__filter-icon"
              :icon-name="item.icon"
            />
            <span>{{ item.label }}</span>
            <template v-if="isSortMenu">
              <i
                v-if="currentSortOrder > 0 && currentSortKey === item.value"
                class="ic ic-caret-down"
              />
              <i v-else-if="currentSortKey === item.value" class="ic ic-caret-up" />
            </template>
          </a>
        </li>
      </slot>
    </ul>
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';
import Icon from '@/components/atoms/Icon/Index.vue';
import FilterButton from '@/components/atoms/Filter/FilterButton';
import _ from 'lodash';

export default {
  name: 'DropdownList',
  components: {
    Icon,
    FilterButton,
  },

  mixins: [i18n],
  props: {
    // Uses FilterButton instead of button if true
    colour: Boolean,
    /**
     * Alignment of dropdown list
     * @values left, right
     */
    alignment: {
      type: String,
      default: 'right',
    },
    currentSortOrder: Number,
    currentSortKey: String,
    disabled: Boolean,
    filterLabel: String,
    items: Array,
    icon: String,
    loading: Boolean,
    label: String,
    /**
     * Allows the dropdown list to be scrolled after the max-height is exceeded.
     */
    scrollable: {
      type: Boolean,
      default: true,
    },
    /**
     * Function to trigger on dropdown item click
     */
    selectionHandler: Function,
  },
  data: function () {
    return {
      manuallyShowDropdown: false,
    };
  },
  computed: {
    isSortMenu: function () {
      return this.currentSortOrder && this.currentSortKey;
    },
  },

  methods: {
    onFilterButtonClick() {
      this.manuallyShowDropdown = !this.manuallyShowDropdown;
    },
    linkAttributes(item) {
      return item.dataRemote
        ? {
            'data-remote': item.dataRemote,
            href: item.href,
          }
        : {
            href: '#',
          };
    },
    clickHandler: function (event, value) {
      // If this is a sorting menu, keep it open so the user can select
      // another sort order if necessary
      if (this.isSortMenu) {
        event.stopPropagation();
      }

      this.selectionHandler(value);
      this.manuallyShowDropdown = false;
    },
    getDropdownIdentifier(dropdown) {
      return dropdown && dropdown.label ? _.kebabCase(dropdown.label) : 'unknown';
    },
  },
};
</script>

<style scoped lang="scss">
.dropdown-list {
  height: max-content;
}
.dropdown-list__button--no-label {
  padding: 10px 26px;
}

.dropdown-list__label--bold {
  font-weight: bold;
}

.dropdown-list__filter-icon {
  font-size: 18px !important;
  top: -1px;
}

.dropdown-list__item-link {
  display: flex;
  align-items: center;
}

.dropdown-list__item-icon {
  margin-right: 8px;
}
</style>
