<template>
  <Tile
    v-if="json"
    :data-test-id="`event-tile-${json.id}`"
    :display-name="json.name || json.event?.name"
    :sub-heading="json.details"
    :item-image-url="json.image_url || json.event?.image_url"
    :json="json"
    :locked="locked"
    :locked-until-tasked="lockedUntilTasked"
    :type="json.tile_type"
    :user-endpoint="userEndpoint"
    :carousel-notice-displayed="carouselNoticeDisplayed"
    :learnlist-notice-displayed="learnlistNoticeDisplayed"
    :modal-copy="modalCopy"
    :use-legacy-menu="true"
  >
    <template #body-properties> </template>
  </Tile>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from '@/composables/useI18n';
import { useTileProps } from '@/composables/useTileProps';
import Tile from '@/components/organisms/Tiles/BaseTile/Index.vue';

export default defineComponent({
  name: 'EventTile',
  components: {
    Tile,
  },

  props: {
    ...useTileProps('event'),
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});
</script>
