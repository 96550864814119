<template>
  <div ref="commentBubble" class="comment-bubble" :class="{ 'comment-bubble--focused': focused }">
    <div class="comment-bubble__details">
      <a v-if="comment.user" :href="comment.user.show_path" class="comment-bubble__details__name">
        {{ comment.user.name }}
      </a>
      <div class="comment-bubble__details__timestamp">
        {{ comment.created_at_words }}
      </div>
    </div>
    <div
      data-test-id="comment-bubble-text-content"
      class="comment-bubble__text"
      v-html="comment.content"
    />
  </div>
</template>

<script lang="ts">
import IUser from '@/types/User';
import { defineComponent, inject, onMounted, PropType, ref } from 'vue';

export default defineComponent({
  name: 'CommentBubble',
  props: {
    comment: {
      type: Object as PropType<ICommentResponse>,
      required: true,
    },
  },
  setup(props) {
    const focused = ref(false);
    const commentBubble = ref();
    const scrollDelay = ref(500);

    onMounted(() => {
      const getSelectedCommentId: (() => number) | undefined = inject('selectedCommentId');
      if (getSelectedCommentId && Number(getSelectedCommentId()) === props.comment.id) {
        focused.value = true;
        setTimeout(() => {
          commentBubble.value.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, scrollDelay.value);
      }
    });

    return {
      commentBubble,
      focused,
      scrollDelay,
    };
  },
});

interface ICommentResponse {
  id: number;
  user: IUser;
  content: string;
  created_at_words: string;
}
</script>

<style lang="scss" scoped>
@import '@/styles/_animations';

.comment-bubble {
  display: block;
  padding: 12px 18px;
  border-radius: 0 25px 25px 25px;
  font-size: 12px;
  background-color: $white-smoke;
  ::v-deep p {
    margin: 0;
  }

  ::v-deep img {
    max-width: 100%;
  }
}

.comment-bubble--focused {
  animation: glowingBorder ease-in 3s;
}

.comment-bubble__details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 15px;
}

.comment-bubble__details__name {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: $dark-midnight-blue;

  &:hover {
    text-decoration: none;
    color: $dark-midnight-blue;
  }
}

.comment-bubble__details__timestamp {
  white-space: nowrap;
  font-size: 10px;
  color: $dark-gray;
}

.comment-bubble__text {
  white-space: pre-wrap;
  word-break: break-word;
  font-size: 12px;
}
</style>
