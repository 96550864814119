import noUiSlider, { PipsMode } from 'nouislider';
import _ from 'lodash';

interface NoUiSliderOptions {
  start?: string | number;
  min: string | number;
  max: string | number;
  [key: string]: unknown;
}

export function useNoUiSlider(el: HTMLElement, options: NoUiSliderOptions) {
  return noUiSlider.create(el, {
    connect: [true, false],
    direction: 'ltr',
    start: Number(options.start || options.min),
    range: {
      min: Number(options.min),
      max: Number(options.max),
    },
    step: 1,
    pips: {
      mode: 'steps' as PipsMode.Steps,
      density: 10,
      filter: (value) => {
        return value === parseInt(value.toString(), 10) ? 1 : -1;
      },
    },
    ..._.omit(options, ['start', 'min', 'max']),
  });
}
