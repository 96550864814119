<script lang="ts">
export default {
  name: 'TileContainer',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { computed, defineProps } from 'vue';
import LinkTile from '@/components/molecules/LinkTile/Index.vue';
import { ILinkTile } from '@/types/ILinkTile';

interface Props {
  heading?: string;
  tiles: ILinkTile[];
}

const props = defineProps<Props>();
const smallTiles = computed(() => props.tiles.some((tile) => tile.theme === 'small'));
const containerClass = computed(() =>
  smallTiles.value ? 'md:tw-grid-cols-3' : 'md:tw-grid-cols-4'
);
</script>

<template>
  <h1 v-if="heading" class="tw-text-3xl tw-mb-6 tw-pl-2 tw-font-semibold">
    {{ heading }}
  </h1>

  <div class="tw-grid tw-gap-12 tw-grid-cols-1" :class="containerClass">
    <LinkTile v-for="(tile, index) in tiles" :key="index" :tile="tile" :loading="false" />
  </div>
</template>
