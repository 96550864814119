import { computed, watch, ref, Ref } from 'vue';
import { useI18n } from '@/composables/useI18n';

export function useImageUpload(
  emit: (event: 'image-added' | 'image-removed', ...args: unknown[]) => void,
  imagePathProp: Ref
) {
  const { t } = useI18n();
  const imagePath = ref(imagePathProp.value);

  const handleImageRemoved = () => {
    imagePath.value = undefined;
    emit('image-removed');
  };

  const imageButtonTooltipText = computed(() => {
    return imagePath.value
      ? t('team_social_feed.form.image_already_added_tooltip')
      : t('team_social_feed.form.add_image_tooltip');
  });

  watch(
    () => imagePathProp.value,
    async (value) => {
      if (value === undefined) {
        imagePath.value = undefined;
      }
    }
  );

  const uploadCallback = (imageUrl: string, imageDataJsonString: string) => {
    imagePath.value = imageUrl;
    emit('image-added', imageDataJsonString);
  };

  return {
    imageButtonTooltipText,
    imagePath,
    handleImageRemoved,
    uploadCallback,
  };
}
