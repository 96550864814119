<template>
  <div
    :class="{
      'input-hint--error': error,
    }"
    class="input-hint"
  >
    {{ value }}
  </div>
</template>

<script>
export default {
  name: 'InputHint',
  props: {
    error: Boolean,
    value: String,
  },
};
</script>

<style lang="scss" scoped>
.input-hint {
  font-size: 14px;
}
.input-hint--error {
  color: $ou-crimson-red;
}
</style>
