<template>
  <TableFilterWrapper :label="label" label-type="legend">
    <div class="boolean-filter__wrapper">
      <Icon icon-name="check" class="boolean-filter__check" />
      <span>{{ value }}</span>
    </div>
  </TableFilterWrapper>
</template>

<script>
import TableFilterWrapper from './TableFilterWrapper.vue';
import Icon from '@/components/atoms/Icon/Index.vue';
import i18n from '@/mixins/i18n';

export default {
  components: {
    TableFilterWrapper,
    Icon,
  },
  mixins: [i18n],

  props: {
    label: String,
    value: String,
  },
};
</script>

<style scoped lang="scss">
.boolean-filter__wrapper {
  display: block;
}

.boolean-filter__check {
  color: $green;
}
</style>
