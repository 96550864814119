<template>
  <label
    v-show="!(hideOnDrop && filename)"
    :class="{ 'file-over': dragover, error: hasError }"
    class="file-dropzone"
    @drop.prevent="handleDrop"
    @dragleave.prevent="handleDragleave"
    @dragover.prevent="handleDragover"
  >
    <div class="hideable-content">
      <div v-if="image" class="m-y-2">
        <img :src="image" alt="" />
      </div>
      <h4>{{ heading || fallbackHeading }}</h4>
      <div data-test-id="link" class="link underline">
        {{ t('vue_templates.common.forms.or_browse') }}
      </div>
      <div class="m-t-1">
        <small v-if="hint" data-test-id="hint" class="d-block text-muted">{{ hint }}</small>
        <div v-if="hasError" class="d-flex align-center error-text">
          <Icon class="file-dropzone__error__icon m-r-1" icon-name="error-cross" />
          <strong data-test-id="error-text">{{ errorText }}</strong>
        </div>
        <div v-if="filename && showFileName" data-test-id="filename" class="m-t-1">
          [ <span>{{ filename }}</span
          ><span v-if="files.length > 1">
            + {{ files.length - 1 }}
            {{ t('vue_templates.common.forms.file', { count: files.length - 1 }) }}</span
          >
          ]
        </div>
      </div>
    </div>
    <input
      ref="input"
      :accept="accept"
      :disabled="disabled"
      :multiple="multiple"
      type="file"
      @change="handleInput"
    />
  </label>
</template>

<script>
import i18n from '@/mixins/i18n';
import Icon from '@/components/atoms/Icon/Index.vue';

export default {
  name: 'FileDropzone',
  components: { Icon },
  mixins: [i18n],
  props: {
    accept: String,
    disabled: Boolean,
    error: String,
    files: Array,
    heading: String,
    showFileName: Boolean,
    hideOnDrop: Boolean,
    hint: String,
    image: String,
    multiple: Boolean,
  },
  data: () => ({
    blockedFileTypes: [
      'exe',
      'php',
      'rb',
      'msi',
      'bat',
      'sh',
      'js',
      'jar',
      'vb',
      'vbs',
      'dll',
      'sfx',
      'tmp',
      'py',
      'msp',
      'com',
      'rar',
      '7z',
      'gz',
      'html',
      'dmg',
    ],
    dragover: false,
    errorInternal: '',
  }),
  computed: {
    errorText() {
      return this.error || this.errorInternal;
    },
    hasError() {
      return !!this.errorText;
    },
    fallbackHeading() {
      return this.t('vue_templates.common.forms.drag_file_here');
    },
    filename() {
      return this.files?.[0]?.name || '';
    },
  },
  methods: {
    convertFileListToArray(fileList) {
      const array = [];
      Array.prototype.push.apply(array, fileList);
      return array;
    },
    handleInput(e) {
      if (!e?.target?.files?.length) return;
      this.errorInternal = '';
      this.handleFileList(e.target.files);
      this.resetInput();
    },
    handleDrop(e) {
      if (!e?.dataTransfer?.files?.length) return;
      if (this.disabled) return;

      this.dragover = false;
      this.errorInternal = '';
      this.handleFileList(e.dataTransfer.files);
      this.resetInput();
    },
    handleDragover() {
      this.dragover = true;
    },
    handleDragleave() {
      this.dragover = false;
    },
    handleFileList(fileList) {
      if (!fileList?.length) return;

      const files = this.convertFileListToArray(fileList);

      if (files.length > 1 && !this.multiple) {
        this.errorInternal = this.t('vue_templates.common.forms.multiple_file_error');
        return;
      }
      if (files.some((file) => !this.isFileValid(file))) {
        const blockedExtensions = files
          .filter((file) => !this.isFileValid(file))
          .map(({ name }) => `.${name.split('.').pop()}`)
          .join(', ');
        this.errorInternal = this.t('vue_templates.common.forms.unsupported_file_error', {
          entity: blockedExtensions,
        });
        return;
      }

      this.$emit('input', files);
    },
    isFileValid(file) {
      const extension = file.name.split('.').pop();

      if (this.blockedFileTypes.includes(extension)) return false;
      if (!this.accept) return true;
      const acceptedTypes = this.accept.split(',');
      return !!acceptedTypes.find((type) => {
        if (type.includes('.')) {
          return this.isMatchingExtension(type, extension);
        }
        return this.isMatchingMimeType(type, file.type);
      });
    },
    isMatchingExtension(acceptedExt, comparingExt) {
      return acceptedExt.replace(/\./g, '') === comparingExt.replace(/\./g, '');
    },
    isMatchingMimeType(acceptedMime, comparingMime) {
      if (!acceptedMime || acceptedMime.split('/').length < 2) {
        return false;
      } else if (!comparingMime || comparingMime.split('/').length < 2) {
        return false;
      }

      const [acceptedType, acceptedSubtype] = acceptedMime.split('/');
      const [comparingType, comparingSubtype] = comparingMime.split('/');
      if (acceptedType === comparingType) {
        if (acceptedSubtype === '*') {
          return true;
        } else {
          return acceptedSubtype === comparingSubtype;
        }
      }
      return false;
    },
    /**
     * Reset the input's value so the @change event can be fired again.
     * Without resetting the value, the @change or @input event will not be triggered.
     * The file should be handled by the parent via emitted event so this should
     * not affect parent components.
     */
    resetInput() {
      this.$refs.input.value = '';
    },
  },
};
</script>

<style scoped lang="scss">
label {
  width: 100%;
  padding: 10px;
  border: 2px dashed $lavendar-gray;
  text-align: center;
  font-weight: normal;
  background-color: $ghost-white;
  transition: all 0.3s ease;

  &:hover {
    background-color: $bubbles;
  }

  &.file-over {
    background-color: $blizzard-blue;
  }

  &.error {
    border-color: $state-danger-text;
  }
}

.error-text {
  color: $state-danger-text;
}

input {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  height: 0;
}

.file-dropzone__error__icon {
  top: auto;
  height: auto;
}
</style>
