<template>
  <Transition appear name="slide-x" mode="out-in">
    <slot />
  </Transition>
</template>

<script>
export default {
  name: 'TransitionSlideX',
};
</script>

<style lang="scss" scoped>
.slide-x-enter-active,
.slide-x-leave-active {
  transition: all 0.3s ease;
}

.slide-x-enter {
  transform: translateX(20px);
  opacity: 0;
}

.slide-x-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.slide-x-leave,
.slide-x-enter-to {
  transform: translateX(0);
  opacity: 1;
}
</style>
