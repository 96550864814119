<template>
  <BaseAttachment
    v-tippy="tippy"
    v-bind="$attrs"
    :url="attachmentUrl"
    :text="document.recording_original_filename || document.original_filename"
    :icon="icon"
    :icon-type="iconType"
    :show-download-icon="showDownloadIcon"
  />
</template>

<script lang="ts">
// Modules
import { defineComponent, PropType, computed, ComputedRef } from 'vue';
// Components
import BaseAttachment from '@/components/atoms/BaseAttachment/Index.vue';
// Types
import IDocument from '@/types/Document';
// Composables
import { useI18n } from '@/composables/useI18n';

export default defineComponent({
  name: 'DocumentAttachment',
  // Remove when all components are migrated to Vue 3
  compatConfig: { MODE: 3 },
  components: {
    BaseAttachment,
  },
  props: {
    document: {
      type: Object as PropType<IDocument>,
      required: true,
    },
    showDownloadIcon: Boolean,
  },
  setup(props) {
    const { t } = useI18n();

    const attachmentUrl: ComputedRef<string | undefined> = computed(() => {
      return props.document.scan_in_progress ? undefined : props.document.download_path;
    });

    const tippy = computed(() => {
      const content = props.document.scan_in_progress
        ? t('helpers.common.virus_scan_in_progress')
        : !props.document.open_inline
        ? t('helpers.common.auto_download')
        : null;
      return {
        content,
        placement: 'right',
      };
    });

    const iconType: ComputedRef<string | undefined> = computed(() => {
      return props.document.scan_in_progress ? 'danger' : undefined;
    });

    const icon: ComputedRef<string> = computed(() => {
      if (props.document.scan_in_progress) {
        return 'b2-shield-security-virus';
      }
      if (props.document.recording_icon) {
        return props.document.recording_icon.replace('ic-', '');
      }
      return props.document.icon.replace('ic-', '');
    });

    return {
      t,
      tippy,
      attachmentUrl,
      iconType,
      icon,
    };
  },
});
</script>

<style lang="scss" scoped>
//
</style>
