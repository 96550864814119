import _ from 'lodash';

export function useTestHelpers() {
  const generateTestId = (...sections: (string | undefined)[]) => {
    const defaultValue = 'unknown';
    const mappedSections = sections.map((section) =>
      section !== undefined ? section : defaultValue
    );

    return _.kebabCase(mappedSections.join('-'));
  };

  return {
    generateTestId,
  };
}
