/* eslint-disable @typescript-eslint/no-explicit-any */
export function useCustomConfirm() {
  const confirmAction = (callback: any, settings: any, cancelCallback?: any) => {
    window.emitter.emit('confirm-delete', settings);
    window.emitter.on('delete-confirmed', (deleteParams: any) => {
      window.emitter.off('delete-confirmed');
      callback(deleteParams);
    });
    window.emitter.on('delete-cancelled', function () {
      window.emitter.off('delete-confirmed');
      cancelCallback && cancelCallback();
    });
  };

  return {
    confirmArchive: (callback: any, settings: any) => {
      confirmAction(callback, Object.assign({ action: 'archive' }, settings || {}));
    },
    confirmDelete: (
      callback: any,
      settings: IConfirmDeleteSettings,
      cancelCallback: any = null
    ) => {
      confirmAction(callback, Object.assign({ action: 'delete' }, settings || {}), cancelCallback);
    },
    confirmRemoveFrom: (callback: any, settings: any) => {
      confirmAction(callback, Object.assign({ action: 'remove' }, settings));
    },
  };
}

export interface IConfirmDeleteSettings {
  entity?: string;
  header?: string;
  name?: string;
  body?: string;
  hasOptions?: boolean;
  options?: {
    [key: string]: string;
  };
}
