<template>
  <component
    :is="profileLink ? 'a' : 'span'"
    v-tippy="{ content: title }"
    v-bind="bindAttributes"
    data-test-id="avatar-element"
    alt=""
    class="avatar"
    :style="avatarStyle"
    :class="{
      'avatar--clickable': !!profileLink,
      'avatar--size-s': size === 'small',
      'avatar--size-l': size === 'large',
      'avatar--size-xl': size === 'xl',
      'avatar--size-xxl': size === 'xxl',
    }"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProfileAvatar',
  props: {
    src: String,
    title: String,
    size: String,
    profileLink: String,
  },
  computed: {
    avatarStyle() {
      return {
        backgroundImage: `url(${this.src})`,
      };
    },
    linkAttributes() {
      return {
        href: this.profileLink,
        target: '_blank',
        'aria-label': this.title,
      };
    },
    spanAttributes() {
      return {
        'aria-hidden': true,
        tabindex: -1,
      };
    },
    bindAttributes() {
      if (this.profileLink) {
        return this.linkAttributes;
      }
      return this.spanAttributes;
    },
  },
  methods: {
    openProfile() {
      if (this.profileLink) {
        window.open(this.profileLink, '_blank');
      }
    },
  },
});

export interface IAvatar {
  id: number;
  name: string;
  url: string;
}
</script>

<style scoped lang="scss">
.avatar {
  display: block;
  background-size: cover;
  height: 32px;
  width: 32px;
  border-radius: 999px;

  &--clickable {
    cursor: pointer;
  }

  .deactivated {
    filter: grayscale(1);
  }
}

.avatar--size-s {
  @extend .avatar;
  height: 28px;
  width: 28px;
}

.avatar--size-l {
  @extend .avatar;
  height: 35px;
  width: 35px;
}

.avatar--size-xl {
  @extend .avatar;
  height: 42px;
  width: 42px;
}

.avatar--size-xxl {
  @extend .avatar;
  height: 46px;
  width: 46px;
}
</style>
