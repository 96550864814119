export default {
  methods: {
    refreshTooltips: function () {
      this.$nextTick(function () {
        $('[data-toggle="tooltip"], [data-toggle="tooltip-clickable"]').tooltip('destroy');
        $('[data-toggle="tooltip"], [data-toggle="tooltip-clickable"]').tooltip({
          boundary: 'window',
          container: 'body',
        });
      });
    },

    hideTooltips: function () {
      $('[data-toggle="tooltip"], [data-toggle="tooltip-clickable"]').tooltip('hide');
    },

    destroyTooltips: function () {
      $('[data-toggle="tooltip"], [data-toggle="tooltip-clickable"]').tooltip('destroy');
    },

    forceRefreshTooltips: function () {
      this.$nextTick(function () {
        $('[data-toggle="tooltip"], [data-toggle="tooltip-clickable"]').tooltip({
          boundary: 'window',
          container: 'body',
        });
      });
    },
  },
};
