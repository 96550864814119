<template id="bookmark-button-template">
  <a
    href="#"
    :aria-label="bookmarkLabel"
    class="bookmark-button"
    data-test-id="component-bookmark-button"
    :class="{ 'bookmark-button--liked': liked }"
    :title="t('vue_templates.bookmark_button.bookmark')"
    @click.prevent.stop="handleClick()"
  >
    <i v-if="liked" class="ic ic-b1-bookmark" />
    <i v-else class="ic ic-t-bookmark-tag" />
  </a>
</template>

<script>
import i18n from '@/mixins/i18n';
import { useNotification } from '@kyvg/vue3-notification';
const { notify } = useNotification();

export default {
  name: 'BookmarkButton',
  mixins: [i18n],

  props: {
    type: String,
    id: [String, Number],
    initLiked: Boolean,
  },

  data: function () {
    return {
      liked: this.initLiked,
    };
  },

  computed: {
    bookmarkLabel: function () {
      return this.liked
        ? this.t('helpers.common.bookmarked')
        : this.t('vue_templates.bookmark_button.bookmark');
    },
  },

  methods: {
    handleClick: function () {
      if (this.liked) {
        this.$http.delete(`/likes/${this.id}?likeable_type=${this.type}`).then(() => {
          this.liked = false;
        });
        return;
      }

      this.$http
        .post('/likes', {
          like: {
            likeable_type: this.type,
            likeable_id: this.id,
          },
        })
        .then(() => {
          this.liked = true;
        })
        .catch((response) => {
          notify({
            type: 'error',
            text: response?.body?.errors,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.bookmark-button {
  position: relative;
  z-index: 6;
  font-size: 3rem;
  margin-top: 3px;
  margin-right: 2px;
  color: $dark-navy-blue;

  i {
    font-weight: bold;
  }

  &:focus {
    outline: none;
  }
}

i {
  display: inline-block;
  font-weight: bold;
  transform: scaleY(0.6);
}

.bookmark-button:hover,
.bookmark-button:focus,
.bookmark-button--liked {
  color: var(--button-color);
}
</style>
