<template>
  <div class="card-surface__container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CardSurface',
};
</script>

<style lang="scss" scoped>
.card-surface__container {
  background-color: $white;
  box-shadow: 0 5px 6px $pastel-blue;
  margin: 15px 0 30px;
  color: #31353d;
}
</style>
