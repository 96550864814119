<template>
  <div :class="{ 'comment-thread--indented': shouldIndent }">
    <div v-for="comment in filteredComments" :key="comment.id">
      <transition-group name="comment" @after-enter="focusInput">
        <FeedPostComment
          :key="`comment-${comment.id}`"
          class="comment-thread__comment"
          :init-comment="comment"
          :at-mention-endpoint="atMentionEndpoint"
          @delete="onDeleteComment(comment.id)"
          @new-comment="onOpenCommentReply(comment)"
        /><div
          v-if="commentsOpen(comment.id)"
          :key="`reply-${comment.id}`"
          :class="{ 'comment-thread--indented': depthIsLessThanMaxDepth(depth) }"
          class="comment-thread__new-comment m-r-2"
          data-test-id="comment-thread-new-comment"
        >
          <div class="comment-thread__new-comment-container">
            <UserAvatar
              v-if="currentUser"
              :src="currentUser.avatar"
              :profile-link="currentUser.show_path"
              class="comment-thread__new-comment-avatar"
              data-test-id="comment-thread-avatar"
              size="large"
            />
            <RichTextInput
              v-model:model-value="newCommentContent[comment.id]"
              :data-input-identifier="`comment-thread-input-${comment.id}`"
              :editor-config="editorConfig"
              :placeholder="t('team_social_feed.post.add_comment')"
              :at-mention-params="{
                endpoint: atMentionEndpoint,
                model: t('activerecord.models.team.one').toLowerCase(),
                entityId: `${comment.id}`,
                entityType: 'teams_post',
              }"
              :init-config-type="TinyMceConfigTypes.inline"
              class="comment-thread__new-comment-input"
              data-test-id="comment-thread-new-comment-input"
              rounded
            />
          </div>
          <div class="comment-thread__new-comment-submit">
            <BaseButton
              :disabled="
                comment.loading ||
                !newCommentContent[comment.id] ||
                newCommentContent[comment.id] === ''
              "
              :action="() => onNewComment(comment)"
              :text="t('team_social_feed.form.post')"
              :loading="comment.loading"
              data-test-id="comment-thread-submit-new-comment"
              size="small"
            />
          </div>
        </div>
      </transition-group>
      <CommentThread
        v-if="comment.comments && comment.comments.length > 0"
        class="comment-thread__child-thread"
        :current-user="currentUser"
        :parent-depth="depth"
        :parent-comment-id="comment.id"
        :user="user"
        :comments="comment.comments"
        :reply-path="replyPath"
        :at-mention-endpoint="atMentionEndpoint"
      />
    </div>
    <a
      v-if="comments.length > showCount"
      data-test-id="comment-thread-more-less-comments"
      :style="{ display: 'inline-block' }"
      class="m-l-4 m-b-2"
      href="#"
      @click.prevent="toggleShowAll"
    >
      {{ showHideText }}
    </a>
  </div>
</template>

<script lang="ts">
import customConfirm from '@/mixins/customConfirm';
import { useApiClient } from '@/composables/useApiClient';
import { useAtMention } from '@/composables/useAtMention';
import { useCustomConfirm } from '@/composables/useCustomConfirm';
import { useI18n } from '@/composables/useI18n';
import { computed, defineComponent, inject, nextTick, PropType, Ref, ref } from 'vue';
import BaseButton from '@/components/atoms/Button/Index.vue';
import FeedPostComment from '@/components/molecules/FeedPost/Comment.vue';
import RichTextInput, {
  OverrideTinymceEditorConfig,
} from '@/components/atoms/forms/RichTextInput/Index.vue';
import UserAvatar from '@/components/atoms/Avatar/Index.vue';
import IUser from '@/types/User';
import { IFeedPostComment } from '@/types/Team/Post';
import { TinyMceConfigTypes } from '@/configs/tinymce';

export default defineComponent({
  name: 'CommentThread',
  components: {
    BaseButton,
    FeedPostComment,
    RichTextInput,
    UserAvatar,
  },
  mixins: [customConfirm],
  props: {
    currentUser: Object,
    comments: {
      type: Array as PropType<IFeedPostComment[]>,
      required: true,
    },
    user: Object as PropType<IUser>,
    commentsToShow: {
      type: Number,
      default: 3,
    },
    replyPath: {
      type: String,
      required: true,
    },
    parentDepth: {
      type: Number,
      default: 0,
    },
    parentCommentId: Number,
    maxDepth: {
      type: Number,
      default: 2,
    },
    atMentionEndpoint: String,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const showCount = ref(props.commentsToShow);
    const depth = ref(props.parentDepth + 1);
    const shouldIndent = !!(depth.value > 1);
    const comments = ref(props.comments);
    const getSelectedCommentId: (() => number) | undefined = inject('selectedCommentId');
    let showAll: Ref<boolean>;
    if (getSelectedCommentId) {
      const selectedCommentId = Number(getSelectedCommentId());
      showAll = ref(comments.value.findIndex((comment) => comment.id === selectedCommentId) > -1);
    } else {
      showAll = ref(false);
    }
    const newCommentContent: Ref<{ [key: number]: string }> = ref({});
    const openReplies: Ref<number[]> = ref([]);
    const lastOpenedReply: Ref<number | undefined> = ref();
    const editorConfig: Ref<OverrideTinymceEditorConfig> = ref({
      toolbar: '',
      plugins: ['emoticons'],
    });

    const { apiClient } = useApiClient();
    const { buildInsertTemplate } = useAtMention(apiClient);
    const { confirmDelete } = useCustomConfirm();

    const filteredComments = computed((): IFeedPostComment[] => {
      if (showAll.value || comments.value.length <= showCount.value) {
        return comments.value;
      }

      return comments.value.slice(0, showCount.value);
    });

    const showHideText = computed((): string => {
      if (showAll.value) {
        return t('team_social_feed.comments.show_less');
      } else {
        return t('team_social_feed.comments.show_all', {
          count: props.comments.length,
        });
      }
    });

    const toggleShowAll = () => {
      showAll.value = !showAll.value;
    };

    const onOpenCommentReply = async (repliedComment: IFeedPostComment) => {
      const replyIndex = openReplies.value.findIndex(
        (commentId) => commentId === repliedComment.id
      );
      const newReplies = openReplies.value.splice(0);

      if (repliedComment.user && repliedComment.user.name) {
        // Add a span containing at mention
        newCommentContent.value[repliedComment.id] = buildInsertTemplate(
          repliedComment.user.show_path,
          repliedComment.user.id,
          repliedComment.user.name
        );
      }

      if (replyIndex === -1) {
        newReplies.push(repliedComment.id);
        lastOpenedReply.value = repliedComment.id;
      } else {
        newReplies.splice(replyIndex, 1);
      }
      openReplies.value = newReplies;
    };

    const onNewComment = async (repliedComment: IFeedPostComment) => {
      const commentIndex = comments.value.findIndex((comment) => comment.id === repliedComment.id);
      comments.value[commentIndex].loading = true;
      const maxDepthReached = props.parentDepth === props.maxDepth && props.parentCommentId;
      // same parent ID once we hit max depth
      let parent_comment_id = maxDepthReached ? props.parentCommentId : repliedComment.id;

      const response = await apiClient
        .post(props.replyPath, {
          content: newCommentContent.value[repliedComment.id],
          parent_comment_id,
        })
        .finally(async () => {
          comments.value[commentIndex].loading = false;
          newCommentContent.value[repliedComment.id] = '';
          await nextTick();
          onOpenCommentReply(repliedComment);
        });
      if (maxDepthReached) {
        comments.value.push(response.body);
      } else {
        const foundComment = comments.value.find((comment) => comment.id === repliedComment.id);
        if (foundComment) {
          foundComment.comments.push(response.body);
        }
      }
    };

    const onDeleteComment = (commentId: number) => {
      const deleteCallback = () => {
        const commentIndex = comments.value.findIndex((comment) => comment.id === commentId);
        /* istanbul ignore next */
        if (commentIndex === -1) {
          console.log('Unable to delete comment');
        }
        apiClient.delete(comments.value[commentIndex].delete_path).then(
          () => {
            comments.value.splice(commentIndex, 1);
          },
          () => {
            /* istanbul ignore next */
            console.log('Delete error');
          }
        );
      };
      confirmDelete(deleteCallback, {
        entity: t('activerecord.models.teams/comment.one'),
      });
    };

    const commentsOpen = (commentId: number): boolean => {
      return openReplies.value.includes(commentId);
    };

    const focusInput = async () => {
      const activeEd = window.tinymce.activeEditor;
      const node = activeEd.dom.select('span');
      activeEd.focus();
      activeEd.selection.setCursorLocation(node[0], 2);
    };

    const newCommentDisabled = (commentData: string) => {
      return !!(!commentData || commentData === '');
    };

    const depthIsLessThanMaxDepth = (depth: number) => {
      return depth <= props.maxDepth;
    };

    return {
      t,
      depth,
      editorConfig,
      emit,
      focusInput,
      shouldIndent,
      showAll,
      showCount,
      filteredComments,
      newCommentContent,
      newCommentDisabled,
      onDeleteComment,
      depthIsLessThanMaxDepth,
      onNewComment,
      onOpenCommentReply,
      commentsOpen,
      showHideText,
      toggleShowAll,
      TinyMceConfigTypes,
    };
  },
});
</script>

<style lang="scss" scoped>
.comment-thread--indented {
  margin-left: 40px;
}

.comment-thread__comment {
  margin-bottom: 30px;
  &:last-of-type {
    margin-bottom: 20px;
  }
}

.comment-thread__new-comment {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.comment-thread__new-comment-avatar {
  margin-right: 10px;
}

.comment-thread__new-comment-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 10px;
}

.comment-thread__new-comment-input {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.comment-thread__new-comment-submit {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

.comment-move {
  transition: transform ease 0.5s;
}

.comment-enter,
.comment-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.comment-enter {
  transform: translateY(-30px);
}

.comment-enter-active {
  transition: transform ease 0.3s, opacity ease 0.2s;
}

.comment-leave-active {
  transition: transform ease 0.2s, opacity ease 0.2s;
}
</style>
