export default {
  methods: {
    confirmAction: function (callback, settings) {
      window.emitter.emit('confirm-delete', settings);
      window.emitter.on('delete-confirmed', function (deleteParams) {
        window.emitter.off('delete-confirmed');
        callback(deleteParams);
      });
      window.emitter.on('delete-cancelled', function () {
        window.emitter.off('delete-confirmed');
      });
    },
    confirmArchive: function (callback, settings) {
      this.confirmAction(callback, Object.assign({ action: 'archive' }, settings || {}));
    },
    confirmDelete: function (callback, settings) {
      this.confirmAction(callback, Object.assign({ action: 'delete' }, settings || {}));
    },
    confirmRemoveFrom: function (callback, settings) {
      this.confirmAction(callback, Object.assign({ action: 'remove' }, settings));
    },
  },
};
