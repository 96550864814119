<template>
  <div v-if="addItemList" class="add-field-select__wrapper">
    <div
      v-if="currentlyAdding"
      data-test-id="adding-fields-form"
      class="add-field-select__add-field-container"
    >
      <Icon
        data-test-id="stop-adding-button"
        icon-name="trash"
        size="large"
        type="danger"
        @click="stopAdding"
      />
      <SelectInput
        :key="`select-input-${selectIndex}`"
        data-test-id="add-field-select-input"
        class="add-field-select__add-field-input"
        :placeholder="addFieldPlaceholderText"
        :items="formattedList"
        @select="onFieldSelected"
      />
      <button
        data-test-id="add-field-button"
        :disabled="addDisabled"
        type="button"
        class="btn btn-primary"
        @click="add"
      >
        {{ addButtonText }}
      </button>
    </div>

    <button
      v-else
      data-test-id="start-adding-fields-button"
      type="button"
      class="btn btn--cancel pull-left"
      @click="startAdding"
    >
      {{ startAddingText }}
    </button>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon/Index.vue';
import SelectInput from '@/components/atoms/SelectInput.vue';

export default {
  components: {
    Icon,
    SelectInput,
  },
  props: {
    selectedItemList: {
      type: Array,
      default: () => [],
    },
    addItemList: Object,
    addButtonText: String,
    startAddingText: String,
    addFieldPlaceholderText: String,
  },
  data: function () {
    return {
      currentlyAdding: false,
      selected: undefined,
      selectIndex: 0,
    };
  },
  computed: {
    addDisabled: function () {
      return this.selected === undefined;
    },
    formattedList: function () {
      const itemsInList = this.selectedItemList.map((listItem) => listItem.name);
      return Object.keys(this.addItemList).reduce((result, itemName) => {
        // Ignore already added options
        if (!itemsInList.includes(itemName)) {
          result.push({
            title: itemName,
            value: itemName,
          });
        }
        return result;
      }, []);
    },
  },
  methods: {
    add: function () {
      this.$emit('on-field-selected', this.selected);
      this.selected = undefined;
      this.selectIndex = this.selectIndex + 1;
    },
    startAdding: function () {
      this.currentlyAdding = true;
    },
    stopAdding: function () {
      this.currentlyAdding = false;
    },
    onFieldSelected: function (value) {
      this.selected = this.addItemList[value];
    },
  },
};
</script>

<style scoped lang="scss">
.add-field-select__wrapper {
  display: flex;
  height: 100%;
  box-sizing: border-box;
  padding: 20px 0 30px;
}

.add-field-select__add-field-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.add-field-select__add-field-input {
  margin: 0 30px 0 10px;
}
</style>
