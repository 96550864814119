<template>
  <select :id="selector" :data-accessibility-selectize-label="srLabel" class="form-control">
    <option class="placeholder"></option>
  </select>
</template>

<script>
import selectize from '@/mixins/selectize';

export default {
  name: 'SelectizeInput',
  mixins: [selectize],

  props: {
    label: String,
    srLabel: String,
  },

  computed: {
    selectizeOptions: function () {
      const options = {};
      Object.keys(selectize.props).forEach((propName) => {
        if (propName === 'selector') {
          options[propName] = document.getElementById(this[propName]);
          return;
        }

        if (typeof this[propName] !== 'undefined') {
          options[propName] = this[propName];
        }
      });

      options.onItemAdd = function () {
        this.blur();
      };

      return options;
    },
  },

  mounted: function () {
    const selectizeType = this.selectizeType || 'Helper';
    RiseTo.Selectize[selectizeType](this.selectizeOptions);
  },

  beforeUnmount: async function () {
    document.getElementById(this.selector).selectize.clear();
    document.getElementById(this.selector).selectize.destroy();
  },
};
</script>
