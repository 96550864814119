<script lang="ts">
export default {
  name: 'SurveyQuestion',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import sanitizeHTML from 'sanitize-html';
import { defineProps, defineAsyncComponent } from 'vue';
import {
  SurveyQuestion,
  SurveyQuestionType,
  SurveyAnswer,
  SurveyCheckboxChoiceIds,
  SurveyPollChoiceIds,
} from '@/types/Survey';
import { useI18n } from '@/composables/useI18n';
const SurveyQuestionTextInput = defineAsyncComponent(
  () => import('@/components/molecules/surveys/SurveyQuestionTextInput/Index.vue')
);
const SurveyQuestionSliderInput = defineAsyncComponent(
  () => import('@/components/molecules/surveys/SurveyQuestionSliderInput/Index.vue')
);
const SurveyQuestionRadioInput = defineAsyncComponent(
  () => import('@/components/molecules/surveys/SurveyQuestionRadioInput/Index.vue')
);
const SurveyQuestionCheckboxInput = defineAsyncComponent(
  () => import('@/components/molecules/surveys/SurveyQuestionCheckboxInput/Index.vue')
);
const SurveyQuestionDropdownInput = defineAsyncComponent(
  () => import('@/components/molecules/surveys/SurveyQuestionDropdownInput/Index.vue')
);
const SurveyQuestionPollInput = defineAsyncComponent(
  () => import('@/components/molecules/surveys/SurveyQuestionPollInput/Index.vue')
);

const props = defineProps<{
  modelValue: SurveyAnswer;
  question: SurveyQuestion;
  showQuestionNumber?: boolean;
  disabled?: boolean;
  error?: string;
}>();

const { t } = useI18n();
const inputId = `survey-question-input-${props.question.id}`;
const questionSentence = sanitizeHTML(props.question.sentence_for || props.question.sentence, {
  allowedAttributes: {},
  allowedTags: [],
});
</script>

<template>
  <fieldset class="survey-question" :disabled="disabled">
    <template v-if="showQuestionNumber">
      <legend>
        {{ t('surveys.answer_questions.edit.question_number', { number: question.display_order }) }}
      </legend>
      <div
        class="survey-question__question"
        data-test-id="survey-question-question"
        v-html="questionSentence"
      />
    </template>
    <template v-else>
      <legend
        data-test-id="survey-question-question"
        class="survey-question__question"
        v-html="questionSentence"
      />
    </template>

    <div
      v-if="question.description"
      class="survey-question__description"
      data-test-id="survey-question-description"
      v-html="sanitizeHTML(question.description)"
    />

    <SurveyQuestionTextInput
      v-if="question.question_type == SurveyQuestionType.FREE_TEXT"
      :model-value="modelValue"
      :question="question"
      :input-id="inputId"
      :error="error"
      data-test-id="survey-question-input-free-text"
      class="survey-question__answer"
      @update:model-value="$emit('update:model-value', $event)"
    />

    <SurveyQuestionSliderInput
      v-if="question.question_type == SurveyQuestionType.OPINION_SCALE"
      :model-value="modelValue"
      :question="question"
      :input-id="inputId"
      :disabled="disabled"
      :error="error"
      class="survey-question__answer"
      data-test-id="survey-question-input-opinion-scale"
      @update:model-value="$emit('update:model-value', $event)"
    />

    <SurveyQuestionRadioInput
      v-if="question.question_type == SurveyQuestionType.RADIO_BUTTON"
      :model-value="modelValue"
      :input-id="inputId"
      :question="question"
      :error="error"
      data-test-id="survey-question-input-radio-button"
      class="survey-question__answer"
      @update:model-value="$emit('update:model-value', $event)"
    />

    <SurveyQuestionCheckboxInput
      v-if="question.question_type == SurveyQuestionType.MULTIPLE_CHOICE"
      :model-value="(modelValue as SurveyAnswer<SurveyCheckboxChoiceIds>)"
      :question="question"
      :input-id="inputId"
      :error="error"
      data-test-id="survey-question-input-multiple-choice"
      @update:model-value="$emit('update:model-value', $event)"
    />

    <SurveyQuestionDropdownInput
      v-if="question.question_type == SurveyQuestionType.DROP_DOWN"
      :model-value="modelValue"
      :question="question"
      :input-id="inputId"
      :error="error"
      class="survey-question__answer"
      data-test-id="survey-question-input-dropdown"
      @update:model-value="$emit('update:model-value', $event)"
    />

    <SurveyQuestionPollInput
      v-if="question.question_type == SurveyQuestionType.POLL"
      :model-value="(modelValue as SurveyAnswer<SurveyPollChoiceIds>)"
      :question="question"
      :error="error"
      data-test-id="survey-question-input-poll"
      class="survey-question__answer"
      @update:model-value="$emit('update:model-value', $event)"
    />
  </fieldset>
</template>

<style lang="scss" scoped>
legend {
  color: $charcoal;
  font-size: 1.4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.survey-question__question,
legend.survey-question__question {
  color: $charcoal;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 2rem;
  border-bottom: 0;
  padding-bottom: 0;
}
.survey-question__description {
  color: $slate-grey;
  margin-bottom: 2rem;
  font-style: italic;
}

.survey-question__accessibility-label {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.survey-question :deep(.survey-question__answer),
.survey-question__answer {
  width: 100%;
}
</style>
