<!-- Haml implementation: app/views/shared_views/_page_heading.html.haml -->
<script lang="ts">
export default {
  name: 'PageHeading',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps } from 'vue';

defineProps<{
  heading: string;
  subHeading?: string;
}>();
</script>

<template>
  <section class="tw-card tw-page-heading">
    <h1 class="tw-text-4.5xl tw-font-semibold tw-mt-0">
      {{ heading }}
    </h1>
    <span v-if="subHeading">
      {{ subHeading }}
    </span>
  </section>
</template>
