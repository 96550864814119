<template>
  <div class="select-field">
    <InputLabel v-if="label" :value="label" :required="required" :error="error" />
    <SelectInput
      :value="value"
      :placeholder="placeholder"
      :options="options"
      @change="$emit('change', $event)"
    />
    <InputHint
      v-if="hintText"
      :error="error"
      :value="hintText"
      class="select-field__hint"
      data-test-id="select-field-hint"
    />
  </div>
</template>

<script>
import InputLabel from '@/components/atoms/forms/InputLabel/Index.vue';
import InputHint from '@/components/atoms/forms/InputHint/Index.vue';
import SelectInput from '@/components/atoms/forms/SelectInput/Index.vue';

export default {
  name: 'SelectField',
  components: { InputLabel, SelectInput, InputHint },
  model: {
    event: 'change',
  },
  props: {
    error: Boolean,
    errorMessage: String,
    hint: String,
    label: String,
    value: [Number, String],
    placeholder: String,
    required: Boolean,
    options: { type: Array, required: true },
  },
  computed: {
    hintText() {
      return this.error && this.errorMessage ? this.errorMessage : this.hint;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-field__hint {
  display: block;
  margin-top: 5px;
}
</style>
