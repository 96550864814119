<script lang="ts">
export default {
  name: 'QuizPreview',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import BaseButton from '@/components/atoms/BaseButton/Index.vue';
import LoadingSpinner from '@/components/atoms/LoadingSpinner/Index.vue';
import TitleWithUnderline from '@/components/atoms/TitleWithUnderline.vue';
import TrueFalseQuestion from '@/components/molecules/QuizQuestions/TrueFalseQuestion/Index.vue';
import MultipleChoiceQuestion from '@/components/molecules/QuizQuestions/MultipleChoiceQuestion/Index.vue';
import { defineProps, onMounted, ref, Ref } from 'vue';
import { useApiClient } from '@/composables/useApiClient';
import { useI18n } from '@/composables/useI18n';
import { useCustomConfirm } from '@/composables/useCustomConfirm';
import { useNotification } from '@kyvg/vue3-notification';
import IQuizQuestion, { QuestionType } from '@/types/QuizQuestion';

const props = defineProps<{
  endpoint: string;
  name: string;
  description: string;
  allowInlineEdit?: boolean;
}>();

const { apiClient } = useApiClient();
const { t } = useI18n();
const { confirmDelete } = useCustomConfirm();
const { notify } = useNotification();

const questions: Ref<IQuizQuestion[]> = ref([]);
const error: Ref<boolean> = ref(false);
const loading: Ref<boolean> = ref(true);

const getQuestions = async () => {
  try {
    const response = await apiClient.get(props.endpoint);
    if (response.ok) {
      questions.value = response.data;
    }
  } catch (errorMessage) {
    error.value = true;
    console.error(error);
  } finally {
    loading.value = false;
  }
};

const getQuestionInput = (question: IQuizQuestion) => {
  switch (question.question_type) {
    case QuestionType.TRUE_FALSE:
      return TrueFalseQuestion;
    case QuestionType.YES_NO:
      return TrueFalseQuestion;
    case QuestionType.MULTIPLE_CHOICE:
      return MultipleChoiceQuestion;
    case QuestionType.DRAG_AND_DROP:
      return 'drag-and-drop-question';
  }
};

const tippy = (content: string, options = {}) => ({
  ...options,
  content,
});

const onDelete = (question: IQuizQuestion) => {
  const settings = {
    entity: t('vue_templates.quiz_questions.question'),
    header: t('helpers.buttons.confirm'),
    name: t('vue_templates.quiz_questions.one_question'),
  };
  confirmDelete(() => deleteCallback(question), settings);
};

const deleteCallback = async (question: IQuizQuestion) => {
  try {
    const response = await apiClient.delete(question.delete_url);
    if (response.ok) {
      getQuestions();
    }
  } catch (error) {
    notifyDeleteError(error as string);
  }
};

const notifyDeleteError = (text?: string) => {
  notify({
    type: 'error',
    title: t('vue_templates.quiz_questions.delete_error'),
    text,
  });
};

onMounted(() => {
  getQuestions();
});
</script>

<template>
  <div class="quiz-preview">
    <TitleWithUnderline v-if="name" data-test-id="quiz-preview__name" :title="name" />
    <div
      v-if="description"
      class="quiz-preview__description"
      data-test-id="quiz-preview__description"
      v-html="description"
    ></div>
    <div v-if="loading" data-test-id="quiz-preview-loading">
      <LoadingSpinner type="xl" />
    </div>
    <div v-else-if="error" data-test-id="quiz-preview-no-questions">
      {{ t('vue_templates.dashboards.error') }}
    </div>
    <div v-else class="quiz-preview__questions">
      <div v-for="(question, index) in questions" :key="question.id" class="quiz-preview__question">
        <div class="quiz-preview__question-number">
          {{
            t('question_banks.preview.question_number', {
              number: index + 1,
            })
          }}
          <div v-if="allowInlineEdit">
            <!-- hide for quizzes with QQB -->
            <BaseButton
              v-if="question.edit_url"
              v-tippy="tippy(t('vue_templates.quiz_questions.edit_question'))"
              :aria-label="t('vue_templates.quiz_questions.edit_question')"
              variant="icon"
              prepend-icon="pencil"
              theme="link"
              :link="{ href: question.edit_url }"
              data-test-id="edit-question-button"
            />
            <BaseButton
              v-if="question.delete_url"
              v-tippy="tippy(t('vue_templates.quiz_questions.delete_tooltip_html'))"
              :aria-label="t('vue_templates.quiz_questions.delete_question')"
              variant="icon"
              prepend-icon="trash"
              theme="error"
              data-test-id="delete-question-button"
              @click="onDelete(question)"
            />
          </div>
        </div>
        <component :is="getQuestionInput(question) as any" :question="question" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.quiz-preview {
  .quiz-preview__description {
    font-size: 1.5rem;
  }

  .quiz-preview__questions {
    .quiz-preview__question-number {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      margin-top: 3.5rem;
      font-weight: bold;
      line-height: 2.5rem;
    }
  }
}

:deep(.drag_and_drop_container) {
  width: auto;
  max-width: 100%;
}
</style>
