<template>
  <div>
    <div class="announcement-group-header announcement-group-header--high"></div>
    <div class="announcement">
      <h5>
        {{ t('vue_templates.announcements_sidebar.knowledge_base') }}
      </h5>
      <p class="p-t-1">
        {{ t('vue_templates.announcements_sidebar.support_tab.knowledge_base.p1') }}
      </p>
      <p class="p-t-1">
        {{ t('vue_templates.announcements_sidebar.support_tab.knowledge_base.p2') }}
      </p>
      <ul class="p-t-1">
        <li class="m-b-1">
          <Icon size="x-large" icon-name="learnamp" />
          <a
            data-test-id="support-sidebar-kb-content-link"
            href="https://learnamp.atlassian.net/wiki/spaces/KB/pages/200835493/Content"
            rel="noopener noreferrer"
            target="_blank"
            class="m-l-1"
          >
            {{ t('vue_templates.announcements_sidebar.support_tab.knowledge_base.li1') }}
          </a>
        </li>
        <li class="m-b-1">
          <Icon size="x-large" icon-name="learnamp" />
          <a
            data-test-id="support-sidebar-kb-learnlists-link"
            href="https://learnamp.atlassian.net/wiki/spaces/KB/pages/200835469/Learnlists"
            rel="noopener noreferrer"
            target="_blank"
            class="m-l-1"
          >
            {{ t('vue_templates.announcements_sidebar.support_tab.knowledge_base.li2') }}
          </a>
        </li>
        <li class="m-b-1">
          <Icon size="x-large" icon-name="learnamp" />
          <a
            data-test-id="support-sidebar-kb-user-people-management-link"
            href="https://learnamp.atlassian.net/wiki/spaces/KB/pages/201098682/User+People+Management"
            rel="noopener noreferrer"
            target="_blank"
            class="m-l-1"
          >
            {{ t('vue_templates.announcements_sidebar.support_tab.knowledge_base.li3') }}
          </a>
        </li>
        <li class="m-b-1">
          <Icon size="x-large" icon-name="learnamp" />
          <a
            data-test-id="support-sidebar-kb-task-link"
            href="https://learnamp.atlassian.net/wiki/spaces/KB/pages/201293832/Task"
            rel="noopener noreferrer"
            target="_blank"
            class="m-l-1"
          >
            {{ t('vue_templates.announcements_sidebar.support_tab.knowledge_base.li4') }}
          </a>
        </li>
      </ul>
      <p class="p-t-2">
        {{ t('vue_templates.announcements_sidebar.support_tab.knowledge_base.p3') }}
        <a
          data-test-id="support-sidebar-click-here-link"
          href="https://learnamp.atlassian.net/wiki/spaces/KB/overview"
          rel="noopener noreferrer"
          target="_blank"
          class="knowledge-link"
          >{{ t('helpers.common.here') }}</a
        >.
      </p>
    </div>

    <div v-if="adminOwnerUser">
      <div class="announcement-group-header announcement-group-header--high"></div>
      <div class="announcement">
        <h5>
          {{ t('vue_templates.announcements_sidebar.support') }}
        </h5>
        <div class="p-t-1">
          <span
            v-html="t('vue_templates.announcements_sidebar.support_tab.support.knowledge_base1')"
          ></span>
          <a
            data-test-id="support-sidebar-kb-link"
            href="https://learnamp.atlassian.net/wiki/spaces/KB/overview"
            target="_blank"
            class="knowledge-link"
            rel="noopener noreferrer"
          >
            {{ t('vue_templates.announcements_sidebar.support_tab.support.knowledge_base_link') }}
          </a>
          <span
            class="p-t-1"
            v-html="t('vue_templates.announcements_sidebar.support_tab.support.knowledge_base2')"
          ></span>
        </div>
        <div class="p-t-1">
          <span
            v-html="t('vue_templates.announcements_sidebar.support_tab.support.customer_portal')"
          ></span>
          <a
            data-test-id="support-sidebar-customer-portal-link"
            href="https://learnamp.atlassian.net/servicedesk/customer/portal/1/user/login?destination=portal%2F1"
            target="_blank"
            class="knowledge-link"
            rel="noopener noreferrer"
          >
            {{ t('vue_templates.announcements_sidebar.support_tab.support.customer_portal_link') }}.
          </a>
        </div>
        <div class="p-t-1">
          {{ t('vue_templates.announcements_sidebar.support_tab.support.contact_us') }}
          <a
            data-test-id="support-sidebar-email-link"
            href="mailto:success@learnamp.com"
            target="_blank"
            class="knowledge-link"
            rel="noopener noreferrer"
            >success@learnamp.com</a
          >.
        </div>
      </div>

      <div class="announcement-group-header announcement-group-header--high"></div>
      <div class="announcement">
        <h5>
          {{ t('vue_templates.announcements_sidebar.product_feedback') }}
        </h5>
        <span
          class="p-t-1"
          v-html="t('vue_templates.announcements_sidebar.support_tab.feature_request.p1')"
        ></span>
        <a
          data-test-id="support-sidebar-product-feedback-link"
          href="https://forms.monday.com/forms/df11a9f7b0e77e25e0446d75788625c2?r=use1"
          target="_blank"
          class="knowledge-link"
        >
          {{ t('helpers.common.here') }}</a
        >.
        <p class="p-t-1">
          {{ t('vue_templates.announcements_sidebar.support_tab.feature_request.p2') }}
        </p>
      </div>

      <div class="announcement-group-header announcement-group-header--high"></div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon/Index.vue';
import i18n from '@/mixins/i18n';

export default {
  name: 'SidebarSupport',

  components: {
    Icon,
  },

  mixins: [i18n],

  props: {
    adminOwnerUser: Boolean,
  },
};
</script>
