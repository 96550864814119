<template>
  <Tile
    v-if="json"
    :data-test-id="`channel-tile-${json.id}`"
    :json="json"
    :locked="locked"
    :locked-until-tasked="lockedUntilTasked"
    :type="json.tile_type"
    :user-endpoint="userEndpoint"
    :carousel-notice-displayed="carouselNoticeDisplayed"
    :learnlist-notice-displayed="learnlistNoticeDisplayed"
    :modal-copy="modalCopy"
    :use-legacy-menu="true"
  >
    <template #body-properties>
      <TileProperties :data-test-id="`channel-tile-${json.id}-properties`" :properties="properties">
        <Icon
          :data-test-id="`channel-tile-${json.id}-icon`"
          class="channel-tile__properties-icon"
          icon-name="t-line-structure-relations-hierarchy"
        />
      </TileProperties>
    </template>
  </Tile>
</template>

<script lang="ts">
import { ComputedRef, computed, defineComponent } from 'vue';
import { useI18n } from '@/composables/useI18n';
import { useTileProps } from '@/composables/useTileProps';
import Icon from '@/components/atoms/Icon/Index.vue';
import TileProperties from '@/components/atoms/Tile/TileProperties.vue';
import Tile from '@/components/organisms/Tiles/BaseTile/Index.vue';

export default defineComponent({
  name: 'ChannelTile',
  components: {
    Icon,
    TileProperties,
    Tile,
  },

  props: {
    ...useTileProps('channel'),
  },
  setup(props) {
    const { t } = useI18n();

    const properties: ComputedRef<string[]> = computed(() => {
      const learnlists = props.json?.learnlists_count;
      const items = props.json?.carousel_items_count;
      const properties: string[] = [];

      if (learnlists && learnlists > 0) {
        properties.push(
          `${learnlists} ${t('activerecord.models.learnlist', { count: learnlists })}`
        );
      }

      if (items && items > 0) {
        properties.push(`${items} ${t('activerecord.models.item', { count: items })}`);
      }

      // Fallback in case there are only quizzes/surveys in the channel
      if (properties.length === 0) properties.push(t('activerecord.models.carousel.one'));

      return properties;
    });

    return {
      properties,
      t,
    };
  },
});
</script>

<style scoped lang="scss">
.channel-tile__properties-icon {
  top: 2px;
  padding-right: 8px;
}
</style>
