<template>
  <div class="tabs__outer">
    <div :class="{ 'container--full-width': fullWidth }" class="container">
      <ul
        :class="{ 'tabs__list--full-width': fullWidth }"
        class="nav nav-tabs tabs__list noscroll"
        :data-cy="tabConfig.testIdentifier"
      >
        <li
          v-for="tab in filteredTabs"
          :key="`${tabConfig.testIdentifier}-${tab.name}`"
          :class="{ active: tab.active }"
        >
          <a href="#" @click.prevent="tab.action">
            {{ tab.label }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTabs',

  props: {
    tabConfig: Object,
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    filteredTabs: function () {
      return this.tabConfig.tabs.filter((tab) => tab.visible);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  max-width: 1170px;
  padding: 0 60px;
  &--full-width {
    padding: 0;
  }
}

.tabs__outer {
  box-shadow: inset 0 8px 8px -10px $shadow-default;
}

.tabs__list {
  margin-bottom: 0;
  &--full-width {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}
</style>
