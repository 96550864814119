<template>
  <HoverWrapper
    v-bind="$attrs"
    class="base-note"
    :class="{
      'base-note--compact': compact,
      'base-note--loading': loading,
      'base-note--editing': isEditing,
    }"
    :highlight="!isEditing"
    @click="onBaseNoteClick"
  >
    <BaseNoteInput
      v-if="isEditing"
      :note="note"
      :disabled="updating"
      :save-button-text="t('vue_templates.components.base_note.edit_note')"
      :show-privacy-options="showPrivacyOptions"
      :at-mention-params="atMentionParams"
      :editor-config="editorConfig"
      class="m-a-2"
      @save="onEditSave"
      @cancel="onEditCancel"
    />
    <div v-else class="base-note__inner" data-test-id="`base-note-inner-${testId}`">
      <div class="base-note__avatar-wrapper" :data-test-id="`base-note-avatar-${testId}`">
        <!-- Author Avatar -->
        <div v-if="loading" class="skeleton-loading avatar" />
        <BaseAvatar v-else :src="note?.author.avatar" :title="note?.author.name" />
        <!-- Timestamp -->
        <small
          v-if="note && note.created_at"
          class="base-note__timestamp text-muted"
          :data-test-id="`base-note-timestamp-${testId}`"
        >
          {{ note.created_at }}
        </small>
      </div>
      <div class="base-note__body">
        <div class="base-note__author m-b-1" :data-test-id="`base-note-author-${testId}`">
          <!-- Author -->
          <div v-if="loading" class="skeleton-loading small" />
          <template v-else-if="note">
            <a :href="note.author.show_path" @click.stop>{{ note.author.name }}</a>
            <BaseIcon
              v-if="note.private"
              icon-name="t-security-lock"
              :title="t('vue_templates.notes.private')"
              type="danger"
              size="large"
              class="m-l-1"
              :data-test-id="`base-note-private-icon-${testId}`"
            />
          </template>
        </div>

        <div v-if="!loading" class="base-note__crud-button-wrapper">
          <!-- Edit icon -->
          <BaseButton
            v-if="note?.update_path && !compact"
            class="base-note__crud-button"
            variant="icon"
            theme="info"
            prepend-icon="pencil"
            :title="t('helpers.submit.edit', { model: t('activerecord.models.note.one') })"
            :data-test-id="`base-note-edit-button-${testId}`"
            @click.stop="onEditClick"
          />
          <!-- Delete icon -->
          <BaseButton
            v-if="note?.delete_path && showDelete"
            class="base-note__crud-button"
            variant="icon"
            theme="info"
            prepend-icon="trash"
            :title="t('helpers.submit.delete', { model: t('activerecord.models.note.one') })"
            :data-test-id="`base-note-delete-button-${testId}`"
            @click.stop="onDeleteClick"
          />
        </div>

        <!-- Note Content -->
        <div v-if="loading" class="skeleton-loading" />
        <span
          v-else
          class="base-note__content"
          :data-test-id="`base-note-content-${testId}`"
          v-html="noteContent"
        />
        <!-- Attachment -->
        <!-- @click.stop prevents opening modal when in compact mode -->
        <div
          v-if="showAttachment"
          class="base-note__attachment"
          :data-test-id="`base-note-attachment-${testId}`"
        >
          <DocumentAttachment
            :document="(note?.document as Document)"
            :show-download-icon="showAttachmentDownloadIcon"
            :tabindex="compact ? -1 : 0"
            @click="onAttachmentClick"
          />
        </div>
      </div>
    </div>
  </HoverWrapper>
  <DocumentAttachmentPreview
    v-if="showEmbeddedAttachment"
    :document="(note?.document as Document)"
    class="m-t-2"
  />
</template>

<script lang="ts">
export default {
  name: 'BaseNote',
};
</script>

<script lang="ts" setup>
// Modules
import { withDefaults, defineProps, defineEmits, computed, ref, Ref, watch } from 'vue';
import sanitizeHtml from 'sanitize-html';
import _ from 'lodash';
import { notify } from '@kyvg/vue3-notification';
// Components
import BaseAvatar from '@/components/atoms/Avatar/Index.vue';
import DocumentAttachment from '@/components/atoms/DocumentAttachment/Index.vue';
import BaseIcon from '@/components/atoms/Icon/Index.vue';
import HoverWrapper from '@/components/atoms/HoverWrapper/Index.vue';
import BaseButton from '@/components/atoms/BaseButton/Index.vue';
import BaseNoteInput from '@/components/molecules/BaseNoteInput/Index.vue';
import DocumentAttachmentPreview from '@/components/molecules/DocumentAttachmentPreview/Index.vue';
// Composables
import { useI18n } from '@/composables/useI18n';
import { useApiClient } from '@/composables/useApiClient';
import { useCustomConfirm } from '@/composables/useCustomConfirm';
// Types
import INote, { NoteableTypes, NoteableTypeConfig } from '@/types/Note';
import Document from '@/types/Document';
import CrudAction from '@/types/CrudAction';
import { ITinyMceAtMentionProps } from '@/composables/useAtMention';
import {
  CopyProps,
  IPropTypes,
} from '@tinymce/tinymce-vue/lib/cjs/main/ts/components/EditorPropTypes';

interface Props {
  editorConfig?: CopyProps<IPropTypes>;
  atMentionParams?: ITinyMceAtMentionProps;
  loading?: boolean;
  initialNote?: INote;
  noteableType?: NoteableTypes;
  showAttachmentPreview?: boolean;
  compact?: boolean;
  showDelete?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  showDelete: true,
});

const emit = defineEmits([
  'open',
  'deleted',
  'updated',
  'attachment-click',
  'edit-click',
  'edit-cancel',
]);

const { t } = useI18n();
const { apiClient } = useApiClient();
const { confirmDelete } = useCustomConfirm();
const isEditing = ref(false);
const updating = ref(false);
const note: Ref<INote | undefined> = ref(_.cloneDeep(props.initialNote));

watch(
  () => props.initialNote,
  (newNote) => {
    note.value = _.cloneDeep(newNote);
  }
);

const showPrivacyOptions = computed(() => {
  if (!props.noteableType) {
    return false;
  }
  return NoteableTypeConfig[props.noteableType].showCommentPrivacyOptions;
});

const noteContent = computed(() => {
  if (!note.value?.content) {
    return '';
  }

  if (props.compact) {
    return sanitizeHtml(note.value.content, { allowedTags: [], allowedAttributes: {} });
  }

  return note.value.content;
});

const testId = computed(() => {
  return props.initialNote?.id || 'loading';
});

const showEmbeddedAttachment = computed(() => {
  if (props.loading) return false;
  if (isEditing.value) return false;
  if (!props.showAttachmentPreview) return false;
  if (!note.value?.document) return false;
  if (!note.value.document.open_inline) return false;
  if (note.value.document.scan_in_progress) return false;

  return true;
});

const showAttachment = computed(() => {
  if (props.loading) return false;
  if (!note.value?.document) return false;
  if (showEmbeddedAttachment.value) return false;

  return true;
});

const showAttachmentDownloadIcon = computed(() => {
  return (
    !props.compact && !note.value?.document?.open_inline && !note.value?.document?.scan_in_progress
  );
});

const onAttachmentClick = (e: Event) => {
  if (
    note.value?.document?.download_path &&
    note.value.document.open_inline &&
    !note.value.document.scan_in_progress
  ) {
    e.preventDefault();
    emit('attachment-click', note.value.document);
  }
};

const notifyError = (type: CrudAction, error?: string) => {
  const title =
    type === CrudAction.DELETE
      ? t('vue_templates.components.base_note.unable_to_delete')
      : t('vue_templates.components.base_note.unable_to_update');
  notify({
    type: 'error',
    duration: -1,
    title,
    text: error,
  });
};

const deleteNote = async () => {
  if (!note.value?.delete_path) {
    notifyError(CrudAction.DELETE);
    return;
  }

  try {
    const response = await apiClient.delete(note.value.delete_path);

    if (response.ok) {
      emit('deleted', note.value);
    } else {
      const error = typeof response?.body === 'string' ? response.body : undefined;
      notifyError(CrudAction.DELETE, error);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    notifyError(CrudAction.DELETE, e?.message);
  }
};

const onDeleteClick = () => {
  confirmDelete(deleteNote, {
    entity: t('activerecord.models.note.one'),
  });
};

const onEditClick = () => {
  isEditing.value = true;
  emit('edit-click');
};

const onEditCancel = () => {
  isEditing.value = false;
  emit('edit-cancel');
};

const onEditSave = async (updatedNote: INote) => {
  if (!note.value?.update_path) {
    notifyError(CrudAction.UPDATE);
    return;
  }

  updating.value = true;
  try {
    const response = await apiClient.patch(note.value.update_path, {
      note: updatedNote,
    });

    if (response.ok) {
      notify({
        type: 'success',
        title: t('vue_templates.components.base_note.update_successful'),
      });
      note.value = _.cloneDeep(response.body);
      isEditing.value = false;
      emit('updated', note.value);
    } else {
      const error = typeof response?.body === 'string' ? response.body : undefined;
      notifyError(CrudAction.UPDATE, error);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    notifyError(CrudAction.UPDATE, e?.message);
  }
  updating.value = false;
};

const onBaseNoteClick = () => {
  if (props.compact && !props.loading) {
    emit('open', note.value);
  }
};
</script>

<style scoped lang="scss">
.base-note__inner {
  display: flex;
  flex-direction: row;
  padding: 15px 10px;
  font-size: 14px;

  & ::v-deep .ic {
    top: auto;
  }
}

.base-note__avatar-wrapper {
  color: $dark-gray;
  font-size: 12px;
  text-align: center;
}

.base-note__timestamp {
  display: block;
  margin-top: 5px;
}

.base-note__body {
  position: relative;
  flex-grow: 1;
  padding-right: 60px;
  margin-left: 2rem;
  overflow: hidden;
}

.base-note__author {
  display: flex;
  align-items: center;
}

.base-note__crud-button-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;

  @media screen and (pointer: fine) {
    display: none;

    .base-note:hover &,
    .base-note:focus-within & {
      display: flex;
    }
  }
}

.base-note__content ::v-deep > p:first-of-type {
  // Remove default browser margin from wysiwyg content.
  margin-top: 0;
}

.base-note__edit-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.base-note__private-input {
  display: inline-block;
}

.base-note__private-input-hint {
  display: block;
}

.base-note__attachment {
  display: inline-flex;
  align-items: center;
  margin-top: 2rem;
}

// MODIFIERS
// ====================================================

.base-note--compact {
  &:not(.base-note--loading) {
    cursor: pointer;
  }

  .base-note__body {
    padding-right: 40px;
    margin-left: 1rem;
  }

  .base-note__content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    hyphens: auto;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .base-note__attachment {
    pointer-events: none;
  }
}
</style>
