import { ReportSegment } from './Report';
import { ActivityableModels } from './content-search/ActivityableTypes';

type ButtonVariant = 'default' | 'text';
export interface ButtonFilter {
  label: string;
  value?: string | Array<string>;
  disabled?: boolean;
  prependIcon?: string;
  items?: Array<{ label: string; value: string }>;
  variant?: ButtonVariant;
}
export enum SegmentOption {
  GROUP = 'advanced_grouping',
  COMPANY = 'company',
  TEAM = 'team',
  COACHED = 'coached',
  MANAGED = 'managed',
}

export enum TileCategories {
  TOP_HIGHLIGHT = 'top-highlight',
  LOGS = 'logs',
  ANALYTICS = 'analytics',
}

export type SegmentType = `${SegmentOption}`;

export enum SelectedScopes {
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  OVERDUE = 'overdue',
  INCOMPLETE = 'incomplete',
}
export interface SegmentFilter {
  segment: SegmentType;
  label: string;
  selected: boolean;
  endpoint?: string;
  options?: Array<SegmentFilterArrayOption>;
  additional_options?: Array<{ param: TeamAdditionalOption; label: string; selected: boolean }>;
  disabled?: boolean;
}

export enum ManagedAdditionalOption {
  DIRECTLY_MANAGED_USERS = 'directly_managed_users',
  ALL_DIRECTLY_MANAGED = 'all_directly_managed',
}

export enum TeamAdditionalOption {
  PRIMARY_TEAM_MEMBERS_ONLY = 'primary_team_members_only',
  INCLUDE_SUB_TEAMS = 'include_sub_teams',
}

export interface SegmentParams {
  segment: SegmentType;
  team_ids?: string;
  advanced_grouping_ids?: string;
  primary_team_members_only?: boolean;
  include_sub_teams?: boolean;
  manager_id?: number;
  directly_managed_users?: boolean;
  all_directly_managed?: boolean;
}

export interface SegmentFilterArrayOption {
  name: string;
  header?: string;
  id: number;
}

export interface DateRangeOption {
  label?: string;
  starts_at?: string;
  ends_at?: string;
  type?: string;
}

export interface AdvancedFilter {
  global_connector: 'and' | 'or';
  filter_groups: Array<FilterGroup>;
}

interface FilterGroup {
  connector: 'and' | 'or';
  queries: Array<FilterQuery>;
}

export interface FilterQuery {
  key: string;
  property_id: string;
  valid: boolean;
  property_unique_id: string;
  model: ActivityableModels;
  operator?: null | 'and' | 'or';
  // Time operators are as follows:
  // '*' - Any time
  // '<' - Before
  // '>' - After
  // '=' - On
  // '!=' - Not on
  // 'BETWEEN' - Between
  time_operator: '*' | '<' | '>' | '=' | '!=' | 'BETWEEN' | 'NULL' | string;
  behaviour_operator: null | 'has' | 'has_not';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  time_value: string;
  time_value_to: string;
  event_id: string;
  polymorphic_type: ActivityableModels;
  polymorphic_id: string;
  attribute: string;
  boolean: boolean;
  count: number;
}

export interface FilterProperties {
  deadline_from?: string;
  deadline_to?: string;
  filters: AdvancedFilter;
  include_sub_teams?: boolean;
  primary_team_members_only?: boolean;
  scheduled?: boolean;
  segment?: ReportSegment;
  team_ids?: string;
  advanced_grouping_ids?: string;
}
