<template>
  <label :class="{ 'input-label--error': error }" class="input-label">
    <span class="input-label__span">
      <div class="input-label__span-container">
        <BaseIcon v-if="icon" :icon-name="icon" align-middle="true" />
        <slot>
          {{ value }}
        </slot>
        <abbr v-if="required" aria-hidden="true" class="input-label__abbr" title="required">*</abbr>
        <span v-if="required" class="visually-hidden">required</span>
      </div>
    </span>
  </label>
</template>

<script>
import BaseIcon from '@/components/atoms/Icon/Index.vue';

export default {
  name: 'InputLabel',
  components: { BaseIcon },
  props: {
    value: String,
    required: Boolean,
    error: Boolean,
    icon: String,
  },
};
</script>

<style lang="scss" scoped>
.input-label {
  font-weight: 700;

  &[disabled] {
    opacity: 0.6;
  }
}

.input-label__span {
  display: inline-block;
}

.input-label__span-container {
  display: flex;
  align-items: center;
  gap: 3px;
}

.input-label__abbr {
  border: 0;
  cursor: default;
}
.input-label--error {
  color: $ou-crimson-red;
}
</style>
