<template>
  <div
    :class="{
      'post-link--preview': preview,
      'post-link--loading': loading,
    }"
    class="post-link"
  >
    <div class="post-link__inner">
      <BaseButton
        v-if="preview"
        :action="onRemoveClick"
        :title="t('team_social_feed.form.remove_link_preview')"
        button-style="fab"
        class="post-link__remove"
        data-test-id="post-link-remove-fab"
        icon="error-cross"
        top="20"
        right="20"
      >
        <BaseIcon icon-name="t-cross-circle" size="large" />
      </BaseButton>
      <a :href="link.url" class="post-link__link" target="_blank" rel="noopener noreferrer">
        <div class="post-link__image-wrap">
          <div
            v-if="showLoadingSkeleton"
            class="post-link__loading-skeleton skeleton-loading"
            data-test-id="post-link-loading-skeleton"
          />
          <img
            v-if="link.image_url"
            :src="link.image_url"
            class="post-link__image"
            data-test-id="post-link-image"
            @load="onImageLoaded"
            @error="onImageError"
          />
          <BaseIcon
            v-if="showFallbackIcon"
            aria-hidden="true"
            class="post-link__fallback-icon"
            icon-name="link"
            size="xx-large"
          />
        </div>
        <div class="post-link__text">
          <span v-if="loading">{{ t('team_social_feed.form.loading_link_preview') }}</span>
          <template v-else>
            <div class="post-link__title" data-test-id="post-link-title">{{ link.title }}</div>
            <div
              v-if="link.supplier_name"
              class="post-link__supplier"
              data-test-id="post-link-supplier"
            >
              {{ link.supplier_name }}
            </div>
          </template>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/atoms/Button/Index.vue';
import BaseIcon from '@/components/atoms/Icon/Index.vue';
import i18n from '@/mixins/i18n';

export default {
  name: 'PostLink',
  components: {
    BaseButton,
    BaseIcon,
  },
  mixins: [i18n],
  props: {
    loading: Boolean,
    preview: Boolean,
    link: {
      type: Object,
      default: () => ({
        url: null,
        title: null,
        image_url: null,
        image: null,
        supplier_name: null,
      }),
    },
  },
  data: () => ({
    imageLoaded: false,
    imageError: false,
  }),
  computed: {
    showLoadingSkeleton() {
      return this.loading || (this.link.image_url && !this.imageLoaded && !this.imageError);
    },
    showFallbackIcon() {
      return !this.loading && (!this.link.image_url || this.imageError);
    },
  },
  methods: {
    onRemoveClick() {
      this.$emit('remove');
    },
    onImageLoaded() {
      this.imageLoaded = true;
    },
    onImageError() {
      this.imageError = true;
    },
  },
};
</script>

<style scoped lang="scss">
.post-link__inner {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  display: flex;
}
.post-link__remove {
  z-index: 10;
}
.post-link__link {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-basis: 100%;
  color: $charcoal;

  &:hover {
    text-decoration: none;
  }
}
.post-link__text {
  min-height: 50px;
  flex-basis: 100%;
  background-color: $platinum;
  padding: 10px 20px;
}
.post-link__title {
  font-weight: 500;
  font-size: 20px;
}
.post-link__supplier {
  color: $slate-grey;
  font-size: 14px;
  margin-top: 10px;
}
.post-link__image-wrap {
  flex-basis: 100%;
  position: relative;
  min-height: 70px;
}
.post-link__image {
  max-width: 100%;
  position: relative;
  z-index: 5;
  width: 100%;
  max-height: 350px;
  object-fit: cover;
  object-position: center;
}
.post-link__fallback-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  z-index: 1;
}
.post-link__loading-skeleton {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

// MODIFIERS
// =====================
.post-link--preview {
  padding: 15px 35px;
  background-color: $platinum;

  .post-link__text {
    background-color: $white;
  }
  .post-link__title {
    font-size: 16px;
  }
  .post-link__supplier {
    font-size: 12px;
  }
}
.post-link--loading {
  .post-link__text {
    background-color: $white;
    font-size: 16px;
    font-weight: 500;
  }
}
</style>
