export default {
  methods: {
    // This method can be used with only a title which
    // will always result in a tooltip. Alternatively, pass a
    // shouldTruncate value to show the tooltip conditionally.
    tooltipAttributes(title, shouldTruncate = true) {
      return title && shouldTruncate
        ? {
            'data-toggle': 'tooltip-clickable',
            'data-placement': 'top',
            'data-html': true,
            title,
          }
        : null;
    },

    // This method is separate so it may be used independently
    // in situations such as showing "Read more" links.
    shouldTruncate: function (text, truncateLength = 30) {
      return text && text.length > truncateLength;
    },
  },
};
