<template>
  <div>
    <span
      v-for="option in options"
      :key="option.label"
      class="radio"
      :class="{ 'radio--inline': inline }"
    >
      <input
        :id="option.id"
        class="radio_buttons"
        type="radio"
        :value="option.value"
        :name="option.name"
        :checked="option.value === modelValue"
        @change="$emit('update:modelValue', option.value)"
      />
      <label class="collection_radio_buttons" :for="option.id">
        <span v-html="option.label" />
        <small v-if="option.subLabel" class="text-muted" v-html="option.subLabel" />
      </label>
    </span>
  </div>
</template>

<script>
export default {
  name: 'RadioInput',
  compatConfig: {
    MODE: 3,
  },
};
</script>

<script setup>
import { defineProps, defineEmits } from 'vue';

defineProps({
  options: Array,
  modelValue: [String, Number, Boolean],
  inline: Boolean,
});

defineEmits(['update:modelValue']);
</script>

<style scoped lang="scss">
.radio--inline {
  display: inline-block;
  margin-right: 10px;
}
.avatar {
  max-height: 32px;
  max-width: 32px;
  border-radius: 18px;
  margin-right: 15px;

  .deactivated {
    filter: grayscale(1);
  }
}
</style>
