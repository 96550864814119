import IUser from './User';
import IDocument from './Document';

export interface DocumentAttributes {
  document_url: string;
  file_type: string;
  file_size: number;
  original_filename: string;
  _destroy?: boolean;
}

export type DocumentAttributesDestroy = Pick<DocumentAttributes, '_destroy'>;

export interface INoteDraft {
  content: string;
  private: boolean;
  document?: IDocument | null;
  document_attributes?: DocumentAttributes | null;
}

export default interface INote extends INoteDraft {
  id: number;
  created_at: string;
  update_path?: string;
  delete_path?: string;
  author: IUser;
}

export enum NoteableTypes {
  ADVANCED_GROUPING = 'AdvancedGrouping',
  OBJECTIVE = 'Objective',
  ONE_TO_ONE = 'OneToOne',
  TEAM = 'Team',
  USER = 'User',
}

export const NoteableTypeConfig = {
  [NoteableTypes.ADVANCED_GROUPING]: {
    showCommentPrivacyOptions: false,
    defaultPrivate: false,
  },
  [NoteableTypes.OBJECTIVE]: {
    showCommentPrivacyOptions: true,
    defaultPrivate: false,
  },
  [NoteableTypes.ONE_TO_ONE]: {
    showCommentPrivacyOptions: true,
    defaultPrivate: false,
  },
  [NoteableTypes.TEAM]: {
    showCommentPrivacyOptions: false,
    defaultPrivate: false,
  },
  [NoteableTypes.USER]: {
    showCommentPrivacyOptions: true,
    defaultPrivate: true,
  },
};
