<script lang="ts">
export default {
  name: 'SurveyQuestion',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import { SurveyQuestion, SurveyAnswer, SurveyPollChoiceIds } from '@/types/Survey';
import InputHint from '@/components/atoms/forms/InputHint/Index.vue';

const emit = defineEmits<{
  (e: 'update:model-value', value: SurveyAnswer<SurveyPollChoiceIds>): void;
}>();
const props = defineProps<{
  modelValue: SurveyAnswer<SurveyPollChoiceIds>;
  question: SurveyQuestion;
  error?: string;
}>();

const handleInput = (choiceId: number, columnId: number) => {
  const choiceIds = Object.assign(props.modelValue?.choice_ids || {}, { [choiceId]: columnId });

  const payload = {
    ...props.modelValue,
    choice_ids: choiceIds,
  };
  emit('update:model-value', payload);
};
</script>

<template>
  <table v-bind="$attrs">
    <thead>
      <tr>
        <td />
        <th v-for="column in question.poll_columns_attributes" :key="column.id">
          {{ column.value }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="choice in question.choices_attributes" :key="choice.id">
        <td>{{ choice.sentence }}</td>
        <td v-for="column in question.poll_columns_attributes" :key="column.id">
          <label
            :for="`poll-${choice.id}-${column.id}`"
            class="survey-question-poll-input__accessibility-label"
            data-test-id="survey-question-poll-input-accessibility-label"
            >{{ `${choice.sentence} - ${column.value}` }}</label
          >
          <input
            :id="`poll-${choice.id}-${column.id}`"
            :name="`poll-${question.id}-${choice.id}`"
            type="radio"
            :value="choice.id"
            :checked="modelValue?.choice_ids?.[choice.id] === column.id"
            @input="handleInput(choice.id, column.id)"
          />
        </td>
      </tr>
    </tbody>
  </table>
  <InputHint v-if="error" :value="error" :error="!!error" class="m-t-1" />
</template>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

thead {
  font-weight: 600;
}

tbody,
tr + tr {
  border-top: 1px solid $platinum;
}

th,
td {
  padding: 0.8rem;
}

th:not(:first-child),
td:not(:first-child) {
  text-align: center;
}

.survey-question-poll-input__accessibility-label {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
</style>
