<template>
  <div class="showing-count__wrapper">
    <TextLabel
      type="muted"
      :text="
        t('helpers.common.showing_of', { showing_count: showingCount, total_count: totalCount })
      "
    />
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';
import TextLabel from '@/components/atoms/TextLabel.vue';
export default {
  name: 'ShowingCount',
  components: {
    TextLabel,
  },
  mixins: [i18n],
  props: {
    showingCount: Number,
    totalCount: Number,
  },
};
</script>

<style scoped lang="scss">
.showing-count__wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}
</style>
