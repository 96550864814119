<template>
  <a :href="item.payload">
    {{ item.label }}
  </a>
</template>

<script>
export default {
  name: 'MenuLink',

  props: {
    item: Object,
  },
};
</script>

<style scoped lang="scss"></style>
