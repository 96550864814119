<template>
  <div class="panel filter">
    <div class="panel-heading filter__heading">
      <a
        class="table--data__sorter table--data__sorter--sorting"
        :class="{ 'table--data__sorter--asc': !expandFilters }"
        @click.prevent="expandFilters = !expandFilters"
      >
        {{ t('vue_templates.activity_feed.filters') }}
      </a>
    </div>

    <div v-show="expandFilters" class="filter-container__panel-body panel-body">
      <slot />
      <a class="filter-container__link pull-right" href="#" @click.prevent="onClearAll">
        <i class="ic ic-error-cross" />
        <span class="filter-container__link-label">
          {{ t('vue_templates.activity_feed.clear_all') }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';

export default {
  mixins: [i18n],

  props: {
    onClearAll: Function,
  },

  data: function () {
    return {
      expandFilters: true,
    };
  },
};
</script>

<style scoped lang="scss">
.filter ::v-deep .filter-container__panel-body > div:not(:last-of-type) {
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 20px;
  padding-bottom: 6px;
}

.filter-container__panel-body div:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 10px;
}

.filter-container__link-label {
  position: relative;
  top: 1px;
}
</style>
