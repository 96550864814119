import { Environment } from '@/types/Rails';
import { Ref, ref } from 'vue';
import _ from 'lodash';

function getDefaultWidgetOptions(cloudName: string, environment: Environment) {
  return {
    client_allowed_formats: ['jpg', 'png', 'gif', 'svg'],
    max_files: 1,
    multiple: false,
    resource_type: 'image',
    show_powered_by: false,
    sources: ['local'],
    tags: [`${environment}_env`, 'attachinary_tmp'],
    theme: 'default',
    upload_preset: 'images',
    google_api_key: 'AIzaSyDF9bHzw9IW-zVmEL_q3qTFEG3m5dmmvVk',
    search_by_rights: true,
    showAdvancedOptions: false,
    showSkipCropButton: false,
    upload_prefix:
      cloudName === 'production-eu'
        ? 'https://api-eu.cloudinary.com/'
        : 'https://api.cloudinary.com/',
    styles: {
      palette: {
        window: '#FFFFFF',
        windowBorder: '#99A5B2',
        tabIcon: '#00B3A1',
        menuIcons: '#5A616A',
        textDark: '#3C4853',
        textLight: '#FFFFFF',
        link: '#00B3A1',
        action: '#E5F3FF',
        inactiveTabIcon: '#606F7B',
        error: '#CC0000',
        inProgress: '#e66b00',
        complete: '#00993F',
        sourceBg: '#F5FAFF',
      },
      fonts: {
        default: null,
        "'Work Sans', sans-serif": {
          url: 'https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,600',
          active: true,
        },
      },
    },
  };
}

function getWidgetOptions(
  cloudName: string,
  environment: Environment,
  overrides?: Partial<WidgetOptions>
): WidgetOptions {
  return _.merge(getDefaultWidgetOptions(cloudName, environment), overrides);
}

export function getImageUrl(
  data: ICloudinaryInfo,
  width: number | 'iw' = 'iw',
  height: number | 'ih' = 'ih'
) {
  const trans = `w_${width},h_${height}`;
  let url = data.secure_url;
  const pattern = /upload\/[^]+\//;

  if (data.coordinates) {
    url = url.replace(
      pattern,
      `upload/c_crop,g_custom/a_exif,c_fill,dpr_1.0,f_auto,q_auto,${trans}/`
    );
  } else {
    url = url.replace(pattern, `upload/q_auto,${trans}/`);
  }
  return url;
}

export function getImageDataJsonString(data: ICloudinaryInfo): string {
  const imageProperties = ['public_id', 'version', 'width', 'height', 'format', 'resource_type'];
  return JSON.stringify(_.pick(data, imageProperties));
}

export function useCloudinaryWidget(
  environment: Environment,
  configOverrides?: Partial<WidgetOptions>
) {
  const cloudName: Ref<string> = ref(
    window.cloudinaryCloudName || document.body.dataset.cloudinaryCloudName || ''
  );

  const widget: Ref<ICloudinaryWidget | undefined> = ref();

  const openWidget = () => {
    if (widget.value) {
      widget.value.open();
    }
  };

  const widgetOptions = getWidgetOptions(cloudName.value, environment, configOverrides);

  const initCloudinaryWidget = (
    attachinaryCallback?: (_error: Error, response: CloudinaryResponse) => void
  ) => {
    if (cloudName.value) {
      window.cloudinary.setCloudName(cloudName.value);
    }
    widget.value = window.cloudinary.createUploadWidget(widgetOptions, attachinaryCallback);
  };

  return {
    initCloudinaryWidget,
    openWidget,
    getImageUrl,
    getImageDataJsonString,
  };
}

type WidgetOptions = ReturnType<typeof getDefaultWidgetOptions> & {
  cropping?: string;
  cropping_aspect_ratio?: number;
};

type AttachinaryCallback = (error: Error, response: CloudinaryResponse) => void;

export interface ICloudinary {
  setCloudName: (cloudName: string) => void;
  createUploadWidget: (
    options: WidgetOptions,
    attachinaryCallback?: AttachinaryCallback
  ) => ICloudinaryWidget;
}

export interface ICloudinaryWidget {
  open: () => void;
}

export interface ICloudinaryInfo {
  access_mode: string;
  asset_id: string;
  batchId: string;
  bytes: number;
  coordinates: {
    custom: number[];
  };
  created_at: string;
  etag: string;
  folder: string;
  format: string;
  grayscale: boolean;
  height: number;
  id?: string;
  illustration_score: number;
  image_metadata: {
    JFIFVersion: string;
    ResolutionUnit: string;
    XResolution: string;
    YResolution: string;
    ProfileDescription: string;
  };
  original_filename: string;
  pages: number;
  path: string;
  placeholder: boolean;
  public_id: string;
  resource_type: string;
  secure_url: string;
  semi_transparent: boolean;
  signature: string;
  tags: string[];
  thumbnail_url: string;
  type: string;
  url: string;
  version: number;
  version_id: string;
  width: number;
}

export type CloudinaryResponse = {
  event: string;
  info: ICloudinaryInfo;
};
