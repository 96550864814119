<template>
  <select :id="labelForId" v-model="value" class="form-control" @change="updateModel">
    <option v-if="placeholder" :selected="!initialValue" :value="undefined" :disabled="true">
      {{ placeholder }}
    </option>
    <option
      v-for="item in items"
      :key="item.value"
      :value="item.value"
      :selected="initialValue && initialValue === item.value"
    >
      {{ item.title || item.name }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'SelectInput',
  props: {
    items: Array,
    placeholder: String,
    initialValue: [String, Number],
    labelForId: String,
  },

  data: function () {
    return {
      value: this.initialValue,
    };
  },

  methods: {
    updateModel: function (event) {
      this.$emit('select', event.target.value);
    },
  },
};
</script>
