<script lang="ts">
export default {
  name: 'SurveyQuestion',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import sanitizeHTML from 'sanitize-html';
import { defineProps, defineEmits } from 'vue';
import { SurveyQuestion, SurveyAnswer } from '@/types/Survey';
import { useI18n } from '@/composables/useI18n';
import SelectInput from '@/components/atoms/forms/SelectInput/Index.vue';
import InputHint from '@/components/atoms/forms/InputHint/Index.vue';

const emit = defineEmits<{
  (e: 'update:model-value', value: SurveyAnswer): void;
}>();
const props = defineProps<{
  modelValue: SurveyAnswer;
  question: SurveyQuestion;
  inputId: string;
  error?: string;
}>();

const { t } = useI18n();
const inputId = `question-set-item-input-${props.question.id}`;
const questionSentence = sanitizeHTML(props.question.sentence_for || props.question.sentence);

const handleChange = (choiceId: string) => {
  const payload = {
    ...props.modelValue,
    choice_id: Number(choiceId),
  };
  emit('update:model-value', payload);
};
</script>

<template>
  <label
    :for="inputId"
    class="survey-question-dropdown-input__accessibility-label"
    data-test-id="survey-question-dropdown-input-accessibility-label"
    v-html="questionSentence"
  />
  <SelectInput
    v-bind="$attrs"
    :placeholder="t('surveys.answer_questions.edit.please_select')"
    :options="
      question.choices_attributes.map(({ id, sentence }) => ({
        label: sentence,
        id,
        value: id,
      }))
    "
    :value="modelValue?.choice_id"
    @change="handleChange"
  />
  <InputHint v-if="error" :value="error" :error="!!error" class="m-t-1" />
</template>

<style lang="scss" scoped>
.survey-question-dropdown-input {
  color: inherit;
}

.survey-question-dropdown-input__accessibility-label {
  pointer-events: none;
  opacity: 0;
  position: absolute;
}
</style>
