export default {
  props: {
    csvEndpoint: String,
    initCsvExportUrl: String,
  },

  data: function () {
    return {
      csvExportProcessing: false,
    };
  },

  methods: {
    exportCsv: function () {
      window.emitter.emit('export-csv');
    },
  },
};
