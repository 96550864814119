<template>
  <div class="tile__properties">
    <slot />
    <div
      v-for="(property, index) in properties"
      :key="`property-${index}`"
      class="tile__info-property"
    >
      {{ property }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TileProperties',
  props: {
    properties: Array,
  },
};
</script>

<style scoped lang="scss">
.tile__properties {
  display: flex;
  justify-content: left;

  .tile__info-property {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
  }

  .tile__info-property + .tile__info-property:before {
    content: '•';
    color: #d8d8d8;
    padding: 0 5px;
  }
}
</style>
