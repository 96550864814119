<template>
  <div
    :class="{
      'expansion-panel--flat': flat,
      'expansion-panel--outlined': outlined,
      'expansion-panel--rounded': rounded,
      'expansion-panel--expanded': expanded,
      'expansion-panel--disabled': disabled,
      'expansion-panel--no-rotate': !rotateHeaderIcon,
      'expansion-panel--padded': padded,
      'expansion-panel--destroyed': destroyed,
      'expansion-panel--prominent': prominent,
    }"
    class="expansion-panel"
  >
    <!-- Header -->
    <slot v-if="destroyed" name="header">
      {{ headerLabel }}
    </slot>

    <button
      v-else
      type="button"
      :aria-expanded="expanded"
      :disabled="disabled"
      :aria-controls="id"
      class="expansion-panel__header"
      data-test-id="expansion-panel-header"
      @click="toggleExpanded"
    >
      <div
        class="expansion-panel__header-slot"
        :data-test-id="generateTestId('expansion panel header slot', headerLabel)"
      >
        <slot v-bind="{ expanded }" name="header">
          {{ headerLabel }}
        </slot>
      </div>
      <div class="expansion-panel__header__icon">
        <slot v-bind="{ expanded }" name="header-icon">
          <BaseIcon :icon-name="headerIcon" size="x-large" />
        </slot>
      </div>
    </button>

    <!-- Content -->
    <slot v-if="destroyed" name="default" />

    <Transition
      v-else
      :css="false"
      @before-enter="beforeEnter"
      @enter="enter"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
      @leave="leave"
      @after-leave="afterLeave"
    >
      <div
        v-show="expanded"
        :id="id"
        :aria-hidden="!expanded"
        class="expansion-panel__content"
        data-test-id="expansion-panel-content"
        role="region"
      >
        <div ref="contentPanelInner" class="expansion-panel__content__inner">
          <slot v-bind="{ expanded }" name="default" />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
export default {
  name: 'ExpansionPanel',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid';
import BaseIcon from '@/components/atoms/Icon/Index.vue';
import { useTestHelpers } from '@/composables/useTestHelpers';
import { useBreakpoints } from '@/composables/useBreakpoints';
import { computed, defineProps, ref, watch, withDefaults } from 'vue';

interface Props {
  headerLabel?: string;
  headerIcon?: string;
  rotateHeaderIcon?: boolean;
  padded?: boolean;
  destroyBreakpoint?: 'sm' | 'md' | 'lg';
  id?: string;
  outlined?: boolean;
  rounded?: boolean;
  flat?: boolean;
  open?: boolean;
  disabled?: boolean;
  prominent?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  headerIcon: 'angle-down',
  rotateHeaderIcon: true,
  padded: true,
  id: uuidv4(),
});

const { smAndUp, mdAndUp, lgOnly } = useBreakpoints();
const { generateTestId } = useTestHelpers();

const initialExpanded = props.open || false;
const expanded = ref(initialExpanded);
const transitionDurationMs = ref(300);

const destroyed = computed(() => {
  const bp = props.destroyBreakpoint;
  return (
    bp &&
    ((bp === 'sm' && smAndUp.value) ||
      (bp === 'md' && mdAndUp.value) ||
      (bp === 'lg' && lgOnly.value))
  );
});

watch(
  () => props.open,
  (value) => {
    expanded.value = value;
  }
);

// Enter methods start
const beforeEnter = (panel: Element) => {
  (panel as HTMLElement).style.transition = `height ${transitionDurationMs.value}ms ease`;
  (panel as HTMLElement).style.overflow = 'hidden';
  (panel as HTMLElement).style.height = '0';
};

const contentPanelInner = ref<HTMLElement | null>(null);
const enter = (panel: Element, done: () => void) => {
  if (contentPanelInner.value) {
    const contentHeight = contentPanelInner.value.clientHeight;
    (panel as HTMLElement).style.height = `${contentHeight}px`;
    setTimeout(done, transitionDurationMs.value);
  }
};

const afterEnter = (panel: Element) => {
  (panel as HTMLElement).style.removeProperty('height');
  (panel as HTMLElement).style.removeProperty('overflow');
  (panel as HTMLElement).style.removeProperty('transition');
};
// Enter methods end

// Leave methods start
const beforeLeave = (panel: Element) => {
  if (contentPanelInner.value) {
    const contentHeight = contentPanelInner.value.clientHeight;
    (panel as HTMLElement).style.transition = `height ${transitionDurationMs.value}ms ease`;
    (panel as HTMLElement).style.overflow = 'hidden';
    (panel as HTMLElement).style.height = `${contentHeight}px`;
  }
};

const leave = (panel: Element, done: () => void) => {
  requestAnimationFrame(() => {
    (panel as HTMLElement).style.height = '0';
  });
  setTimeout(done, transitionDurationMs.value);
};

const afterLeave = (panel: Element) => {
  (panel as HTMLElement).style.removeProperty('height');
  (panel as HTMLElement).style.removeProperty('overflow');
  (panel as HTMLElement).style.removeProperty('transition');
};
// Leave methods end

const toggleExpanded = () => {
  expanded.value = !expanded.value;
};
</script>

<style lang="scss" scoped>
@import 'variables';
@import '@/components/atoms/SheetSurface/variables.scss';

.expansion-panel {
  box-shadow: $sheet-surface-box-shadow;
  border: $sheet-surface-border;
}
.expansion-panel__header {
  font-size: 1.4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: left;
  background-color: $white;
  box-shadow: none;
  padding: $expansion-panel-padding-vertical $expansion-panel-padding-horizontal;
  width: 100%;
  border: none;

  &:focus {
    outline: 1px solid var(--button-color);
  }

  &:hover {
    background-color: $white-smoke;
  }
}
.expansion-panel__header-slot {
  flex-grow: 1;
  margin-right: 15px;
}
.expansion-panel__header__icon {
  transition: transform 0.2s ease;

  & ::v-deep .ic {
    top: auto;
    height: auto;
  }
}
.expansion-panel__content {
  background-color: $white;
}

// MODIFIERS
// =====================
.expansion-panel--expanded {
  &:not(.expansion-panel--no-rotate) .expansion-panel__header__icon {
    transform: rotate(-180deg);
    transform-origin: center;
  }
}

.expansion-panel--rounded {
  border-radius: 8px;
  .expansion-panel__header,
  .expansion-panel__content {
    border-radius: 8px;
  }
  &.expansion-panel--expanded:not(.expansion-panel--flat) {
    .expansion-panel__header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .expansion-panel__content {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.expansion-panel--outlined {
  box-shadow: none;
}

.expansion-panel--flat {
  box-shadow: none;
  border: 0;
}

.expansion-panel--disabled {
  opacity: 0.6;
  .expansion-panel__header {
    background-color: $white-smoke;
    cursor: not-allowed;
  }
  .expansion-panel__content {
    background-color: $white-smoke;
  }
}

.expansion-panel--padded {
  .expansion-panel__content__inner {
    padding: $expansion-panel-padding-vertical $expansion-panel-padding-horizontal;
  }
}

.expansion-panel--prominent {
  .expansion-panel__header {
    padding: 2rem;
    font-size: 1.8rem;
  }
}
</style>
