<template>
  <div>
    <div class="announcement-group-header">
      <Icon size="small" :align-middle="true" icon-name="t-clock" />
      {{ t('vue_templates.announcements_sidebar.past_month') }}
    </div>

    <div
      v-for="announcement in announcements"
      :key="announcement.id"
      class="announcement"
      :class="{ unseen: announcement.unseen }"
    >
      <div class="announcement-subtype">
        <span v-html="announcement.icon" />
      </div>
      <h5>
        {{ announcement.header }}
      </h5>
      <img v-if="announcement.image_url" class="m-y-2" :src="announcement.image_url" />
      <div v-html="announcement.body"></div>
      <a v-if="announcement.url" :href="announcement.url">
        {{ t('vue_templates.announcements_sidebar.read_more') }}
      </a>
      <div class="m-t-1 text-muted"></div>
      <Icon icon-name="t-calendar-date" />
      {{ announcement.datetime }}
    </div>

    <div
      v-if="announcementsLoaded && (!announcements || announcements.length === 0)"
      class="no-announcements"
    >
      <Icon size="xx-large" icon-name="t-folder" />
      <div class="text-muted">
        {{ t('vue_templates.announcements_sidebar.empty') }}
      </div>
    </div>

    <div v-if="announcementsLoaded" class="announcement border-bottom">
      <div class="announcement-subtype">
        <Icon icon-colour="$teal" size="large" :align-middle="true" icon-name="t-info-circle" />
        {{ t('vue_templates.announcements_sidebar.announcements_tab.previous.title') }}
      </div>
      <p class="p-t-1">
        {{ t('vue_templates.announcements_sidebar.announcements_tab.previous.p1') }}
      </p>
      <p>
        {{ t('vue_templates.announcements_sidebar.announcements_tab.previous.p2') }}
        <a
          href="https://trello.com/b/7nr8Me82/product-roadmap"
          target="_blank"
          class="knowledge-link"
        >
          {{ t('helpers.common.here') }}</a
        >.
      </p>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon/Index.vue';
import i18n from '@/mixins/i18n';

export default {
  name: 'SidebarAnnouncements',

  components: {
    Icon,
  },

  mixins: [i18n],

  props: {
    announcements: Array,
    announcementsLoaded: Boolean,
  },
};
</script>

<style scoped lang="scss"></style>
