<script lang="ts">
export default {
  name: 'EmptyListState',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import BaseButton from '@/components/atoms/BaseButton/Index.vue';
import { defineProps } from 'vue';

defineProps<{
  imgSrc?: string;
  text?: string;
  buttonText?: string;
  showButton?: boolean;
}>();
</script>

<template>
  <div class="empty-list-state text-center p-a-2" data-test-id="empty-list-state">
    <img :src="imgSrc" alt="" data-test-id="empty-list-state-image" />
    <p v-if="text" class="m-t-1" data-test-id="empty-list-state-text">
      <strong>
        {{ text }}
      </strong>
    </p>
    <BaseButton
      v-if="showButton"
      :text="buttonText"
      variant="outlined"
      class="m-t-2"
      data-test-id="empty-list-state-button"
      @click="$emit('button-click')"
    />
  </div>
</template>

<style lang="scss" scoped>
.empty-list-state {
  color: inherit;
}
</style>
