<template>
  <HoverWrapper>
    <template #default="hover">
      <button
        v-bind="$attrs"
        data-test-id="filter-button"
        class="filter-button"
        :class="{
          'filter-button--active': selected,
        }"
        :style="hover || selected ? buttonStyles : null"
      >
        <slot>{{ text }}</slot>
      </button>
    </template>
  </HoverWrapper>
</template>

<script>
import HoverWrapper from '@/components/atoms/HoverWrapper/Index.vue';

export default {
  // Remove when all components are migrated to Vue 3
  compatConfig: { MODE: 3 },
  name: 'FilterButton',
  components: {
    HoverWrapper,
  },
  inject: {
    gradientStart: {
      default: undefined,
    },
    gradientEnd: {
      default: undefined,
    },
  },
  inheritAttrs: false,
  props: {
    text: String,
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonStyles() {
      if (!this.gradientStart || !this.gradientEnd) {
        return {};
      }

      return {
        backgroundImage: `linear-gradient(96.69deg, ${this.gradientStart} 28.75%, ${this.gradientEnd} 128.68%)`,
        color: '#fff',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin active-styles {
  color: white;
  background-color: var(--button-color);
}

.filter-button {
  border: none;
  background-color: $platinum;
  color: $dark-electric-blue;
  padding: 10px 40px;
  border-radius: 5px;
}

.filter-button:not(.filter-button--active) {
  &:hover {
    @include active-styles;
    opacity: 0.65;
  }
}

.filter-button--active {
  @include active-styles;
}
</style>
