<template>
  <footer data-test-id="tile-footer" class="tile__footer">
    <div class="tile__properties">
      <div
        v-if="deadline"
        data-test-id="tile-footer-deadline"
        class="tile__property--deadline"
        :class="{ 'tile__property--overdue': overdue }"
        :title="overdue ? t('vue_templates.task_feed.task_is_overdue') : ''"
      >
        <i v-if="mandatory" class="ic ic-t-warning-triangle" :title="t('tasks.mandatory')" />
        <Icon class="tile__property-icon" icon-name="t-bell" />
        {{ deadline }}
      </div>
      <div
        v-if="expired"
        data-test-id="tile-footer-expired"
        class="tile__property tile__property--expired"
      >
        <Icon icon-name="t-eye-hidden" />
        {{ t('helpers.common.expired') }}
      </div>
      <div
        v-else-if="hidden"
        data-test-id="tile-footer-hidden"
        class="tile__property tile__property--hidden"
      >
        <Icon icon-name="t-eye-hidden" />
        {{ t('activerecord.attributes.carousel.visibilities.hidden') }}
      </div>
    </div>
    <div>
      <slot />
    </div>
  </footer>
</template>

<script>
import i18n from '@/mixins/i18n';
import Icon from '@/components/atoms/Icon/Index.vue';

export default {
  name: 'TileFooter',
  components: {
    Icon,
  },

  mixins: [i18n],
  props: {
    deadline: String,
    availableActionsPath: String,
    overdue: Boolean,
    mandatory: Boolean,
    expired: Boolean,
    hidden: Boolean,
    liked: Boolean,
    likeableType: String,
    useLegacyMenu: Boolean,
  },
};
</script>

<style scoped lang="scss">
@import 'styles.scss';

.tile__footer {
  justify-content: space-between;
  font-size: 12px;

  .tile__properties {
    padding-top: 5px;
  }
}

.tile__property-icon {
  display: inline-block;
  top: 0px;
  right: 2px;
}

.tile__property--deadline,
.tile__property--hidden,
.tile__property--expired {
  padding-top: 0;
  padding-right: 8px;
}
</style>
