<template>
  <label
    :class="{ 'button-radio--active': active, 'button-radio--small': small }"
    role="label"
    class="button-radio"
    @keydown.space="$emit('change', $event.target.value)"
    @keydown.enter="$emit('change', $event.target.value)"
  >
    {{ label }}
    <input
      :aria-checked="active"
      :checked="active ? 'checked' : null"
      :name="name"
      :value="value"
      class="button-radio__input"
      role="radio"
      type="radio"
      @change="$emit('change', value)"
    />
  </label>
</template>

<script>
export default {
  name: 'ButtonRadio',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Boolean],
      required: true,
    },
    small: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.button-radio {
  padding: 12px 26px;
  background-color: $white;
  color: #333;
  border: 1px solid #ccc;
  font-weight: 400;
  font-size: 14px;
  font-family: inherit;
  cursor: pointer;
  text-align: center;

  &:focus-within {
    outline: 1px solid -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  &:hover {
    background-color: #e6e6e6;
  }
}

.button-radio--active {
  background-color: #e6e6e6;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);

  &:hover {
    background-color: #d4d4d4;
  }
}

.button-radio__input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.button-radio--small {
  padding-top: 9px;
  padding-bottom: 9px;
}
</style>
