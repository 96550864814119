import { RawEditorSettings } from 'tinymce';
import * as $ from 'jquery';

const baseConfig = {
  convert_urls: false, // prevents absolute URLs being converted to relative URLs
  branding: false, // hides "POWERED BY TINY" watermark
};

const defaultConfig = Object.assign({}, baseConfig, {
  menubar: 'edit insert format table tools',
  menu: {
    insert: {
      title: 'Insert',
      items: 'image vue_la_quick_add link media | table hr | charmap',
    },
  },
  toolbar:
    'vue_la_quick_add bold italic underline link | blockquote bullist numlist | forecolor backcolor | removeformat code | vue_simple_toggle',
  plugins: [
    'media',
    'image',
    'link',
    'table',
    'charmap',
    'code',
    'lists',
    'searchreplace',
    'paste',
    'fullscreen',
    'hr',
    'nonbreaking',
    'anchor',
    'advlist',
    'lists',
    'wordcount',
    'textpattern',
  ],
  font_formats:
    'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats; Work Sans=Work Sans,sans-serif;',
  fontsize_formats: '10px 12px 14px 16px 18px 24px 36px 48px',
  height: 400,
  image_list: '/company_images.json',
  file_browser_callback_types: 'image',
  image_advtab: true,
  spellchecker_dialog: true,
  table_appearance_options: true,
  table_style_by_css: true,
  file_picker_callback: function (callback) {
    window.LearnAmp.tinymceCallback = callback;
    $.ajax({
      url: '/company_images/new',
      dataType: 'script',
    });
  },
  visual: false,
});

const simpleConfig = Object.assign({}, baseConfig, {
  menubar: false,
  toolbar: 'vue_la_quick_add bold italic underline link | vue_advanced_toggle',
  plugins: ['link', 'vue_la_quick_add'],
  height: 400,
});

const inlineConfig = Object.assign({}, baseConfig, {
  menubar: false,
  inline: true,
  browser_spellcheck: true,
  plugins: ['lists', 'autolink'],
  toolbar: 'undo redo | bold italic underline | numlist bullist',
  link_default_protocol: 'https',
  default_link_target: '_blank',
});

const notesConfig = Object.assign({}, baseConfig, {
  menubar: false,
  browser_spellcheck: true,
  plugins: ['lists', 'link'],
  toolbar: 'bold italic underline link numlist bullist',
});

const defaultWithAIConfig = Object.assign({}, defaultConfig, {
  toolbar:
    'vue_la_quick_add bold italic underline link vue_ai_button | blockquote bullist numlist | forecolor backcolor | removeformat code | vue_simple_toggle',
});

const configs: TinyMceConfigs = {
  default: defaultConfig,
  simple: simpleConfig,
  inline: inlineConfig,
  notes: notesConfig,
  default_with_ai: defaultWithAIConfig,
};

export default configs;

export enum TinyMceConfigTypes {
  default = 'default',
  simple = 'simple',
  inline = 'inline',
  notes = 'notes',
  default_with_ai = 'default_with_ai',
}

type TinyMceConfigs = {
  [key in TinyMceConfigTypes]: ITinyMceSettings;
};

export interface ITinyMceSettings extends RawEditorSettings {
  image_advtab?: boolean;
  image_list?: string;
  // Used by URL unfurling plugin
  link_default_protocol?: string;
  default_link_target?: string;
}
