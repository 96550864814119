<template>
  <div>
    <div class="feed-post-input__container">
      <Avatar
        v-if="currentUser && currentUser.avatar"
        :src="currentUser.avatar"
        :profile-link="currentUser.show_path"
        :title="currentUser.name"
        class="feed-post-input__avatar"
        data-test-id="avatar"
        size="large"
      />
      <div class="feed-post-input__input-wrapper">
        <RichTextInput
          ref="richText"
          :focus-on-load="focusOnLoad"
          :model-value="modelValue"
          :editor-config="editorConfig"
          :at-mention-params="atMentionParams"
          :init-config-type="TinyMceConfigTypes.inline"
          theme="dark"
          rounded
          @update:model-value="handleInput"
          @link-inserted="handleLinkInserted"
        />
        <!-- #region Toolbar -->
        <div
          v-if="showImageUploadButton || showVideoUploadButton"
          class="feed-post-input__input-toolbar"
        >
          <ToolbarButton
            v-if="showImageUploadButton"
            ref="addImageButton"
            :disabled="disableAttachmentButtons || showDropzone"
            data-test-id="feed-post-image-button"
            icon="image-photo"
            class="feed-post-input__image-button m-l-1 p-r-0"
            :action="openImageUploadModal"
            data-container="body"
            data-toggle="tooltip"
            data-trigger="hover"
            :title="imageButtonTooltipText"
            :data-original-title="imageButtonTooltipText"
            data-delay="1000"
            container="body"
          />
          <ToolbarButton
            v-if="showVideoUploadButton"
            ref="addVideoButton"
            :disabled="disableAttachmentButtons"
            data-test-id="feed-post-video-button"
            icon="t-video-camera"
            class="feed-post-input__video-button m-l-1 p-r-0"
            :action="toggleVideoDropzone"
            data-container="body"
            data-toggle="tooltip"
            data-trigger="hover"
            :title="videoButtonTooltipText"
            :data-original-title="videoButtonTooltipText"
            data-delay="1000"
            container="body"
          />
        </div>
        <!-- #endregion Toolbar -->
      </div>
    </div>

    <!-- #region Image Upload Preview -->
    <div v-if="imagePath" class="feed-post-input__image-container m-t-2">
      <BaseButton
        data-test-id="feed-post-remove-image-button"
        :action="handleImageRemoved"
        :title="t('team_social_feed.form.remove_link_preview')"
        button-style="fab"
        class="post-link__remove"
        icon="error-cross"
        top="30"
        right="30"
      />
      <img class="feed-post-input__image" :src="imagePath" alt="" />
    </div>
    <!-- #endregion Image Upload Preview -->

    <!-- #region Video Upload Preview -->
    <SwapTransition direction="down">
      <div v-if="videoPreview" class="feed-post-input__video-preview">
        <BaseButton
          :action="handleVideoRemoved"
          :title="t('team_social_feed.form.remove_link_preview')"
          button-style="fab"
          class="feed-post-input__video-preview__close-button"
          icon="error-cross"
          top="20"
          right="20"
        />
        <PlayerEmbed :media-url="videoPreview" provider="html5" class="m-t-2" />
      </div>
      <div v-else-if="showDropzone" class="m-t-2">
        <FileDropzone
          :disabled="videoUploading"
          :heading="t('team_social_feed.form.drag_and_drop_video')"
          :files="videosAttached"
          show-file-name
          accept="video/mp4,video/webm"
          @input="handleVideoUpload"
        />
        <div v-if="videoUploading" class="team-post-input__video-preview__progress m-t-1">
          <BaseButton
            :action="cancelVideoUpload"
            :text="t('team_social_feed.form.cancel_upload')"
            button-style="link"
          />
          <ProgressBar indeterminate class="team-post-input__video-preview__progress-bar" />
        </div>
      </div>
    </SwapTransition>
    <!-- #endregion Video Upload Preview -->

    <!-- #region Link Preview -->
    <template v-if="!imagePath && !videoPreview">
      <SwapTransition direction="down">
        <div
          v-if="linkError"
          class="m-t-2 m-l-4 feed-post-input__link-error"
          data-test-id="feed-post-input-link-error"
        >
          <span v-html="t('team_social_feed.form.link_failed')" />
        </div>
        <PostLink
          v-else-if="showPostLink"
          :link="link"
          :loading="linkLoading"
          class="m-t-2"
          data-test-id="feed-post-input-link-preview"
          preview
          @remove="$emit('link-removed')"
        />
      </SwapTransition>
    </template>
    <!-- #endregion Link Preview -->

    <!-- #region Buttons (Cancel/Post) -->
    <SwapTransition direction="down">
      <div
        v-if="modelValue || imagePath || video || alwaysShowButtons"
        class="feed-post-input__button-container"
      >
        <BaseButton
          :action="handleCancel"
          :text="t('team_social_feed.form.cancel')"
          :disabled="loading"
          class="feed-post-input__cancel-button"
          data-test-id="clear-button"
          button-style="cancel"
        />
        <BaseButton
          :action="submit"
          :text="buttonText"
          :disabled="loading || disabled || videoUploading"
          :loading="loading"
          data-test-id="post-button"
          size="small"
        />
        <div
          v-if="error"
          class="m-t-2 feed-post-input__post-error"
          data-test-id="feed-post-input-post-error"
        >
          <span>{{ t('team_social_feed.form.post_failed') }}</span>
        </div>
      </div>
    </SwapTransition>
    <!-- #endregion Buttons (Cancel/Post) -->
  </div>
</template>

<script lang="ts">
export default {
  name: 'TeamFeedPostInput',
  compatConfig: {
    MODE: 3,
  },
};
</script>

<script setup lang="ts">
// Composition API
import {
  computed,
  defineEmits,
  defineProps,
  inject,
  nextTick,
  onMounted,
  ref,
  toRef,
  watch,
  withDefaults,
} from 'vue';
// Composables
import { useI18n } from '@/composables/useI18n';
import { useImageUpload } from '@/composables/feed-posts/useImageUpload';
import { useVideoUpload } from '@/composables/feed-posts/useVideoUpload';
import {
  useCloudinaryWidget,
  CloudinaryResponse,
  getImageUrl,
  getImageDataJsonString,
} from '@/composables/useCloudinaryWidget';
// Types
import { Environment } from '@/types/Rails';
import User from '@/types/User';
// Components
import Avatar from '@/components/atoms/Avatar/Index.vue';
import BaseButton from '@/components/atoms/Button/Index.vue';
import ToolbarButton from '@/components/atoms/ToolbarButton.vue';
import SwapTransition from '@/components/atoms/Transitions/Swap.vue';
import RichTextInput from '@/components/atoms/forms/RichTextInput/Index.vue';
import PostLink from '@/components/molecules/PostLink/Index.vue';
import PlayerEmbed from '@/components/molecules/PlayerEmbed/Index.vue';
import FileDropzone from '@/components/molecules/forms/FileDropzone/Index.vue';
import ProgressBar from '@/components/atoms/ProgressBar/Index.vue';
import { useAwsS3 } from '@/composables/useAwsS3';
import { ITinyMceAtMentionProps } from '@/composables/useAtMention';
import { useApiClient } from '@/composables/useApiClient';
import { TinyMceConfigTypes } from '@/configs/tinymce';
import {
  CopyProps,
  IPropTypes,
} from '@tinymce/tinymce-vue/lib/cjs/main/ts/components/EditorPropTypes';

interface Props {
  modelValue?: string;
  focusOnLoad: boolean;
  currentUser?: User;
  error: boolean;
  link?: ILink;
  video: string | undefined;
  videoPreview: string | undefined;
  image?: string;
  linkLoading: boolean;
  linkError: boolean;
  loading?: boolean;
  disabled: boolean;
  atMentionParams?: ITinyMceAtMentionProps;
  alwaysShowButtons: boolean;
  teamPostsPresignsPath: string;
  primaryButtonText: string;
  showImageUploadButton?: boolean;
  showVideoUploadButton?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  showImageUploadButton: true,
  showVideoUploadButton: true,
});

const emit = defineEmits([
  'update:model-value',
  'submit',
  'cancel',
  'link-inserted',
  'link-removed',
  'image-added',
  'image-removed',
  'video-added',
  'video-removed',
  'video-preview-loaded',
]);

const environment = inject('railsEnv') as Environment;
const { t } = useI18n();

const addImageButton = ref();
const addVideoButton = ref();
const richText = ref();

const handleInput = (value: string) => {
  emit('update:model-value', value);
};
const handleCancel = () => {
  richText.value.clearEditor();
  emit('cancel');
};
const submit = () => {
  emit('submit');
  richText.value.clearEditor();
};
const handleLinkInserted = (url: string) => {
  if (!props.link?.url) {
    emit('link-inserted', url);
  }
};

const editorConfig = computed(() => {
  return {
    toolbar: 'undo redo | bold italic underline | numlist bullist | emoticons',
    plugins: ['emoticons', 'lists', 'autolink'],
  } as CopyProps<IPropTypes>;
});

const showPostLink = computed(() => {
  return (props.link && props.link.url && props.link.title) || props.linkLoading;
});

/* istanbul ignore next */
const refreshTooltips = async () => {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  if (addImageButton.value?.$el) {
    ($(addImageButton.value.$el) as any).tooltip('destroy');
    await nextTick();
    ($(addImageButton.value.$el) as any).tooltip({
      boundary: 'window',
      container: 'body',
    });
  }
  if (addVideoButton.value?.$el) {
    ($(addVideoButton.value.$el) as any).tooltip('destroy');
    await nextTick();
    ($(addVideoButton.value.$el) as any).tooltip({
      boundary: 'window',
      container: 'body',
    });
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */
};

const buttonText = computed(() => {
  return props.primaryButtonText || t('team_social_feed.form.post');
});

// #region Image Upload
const { initCloudinaryWidget, openWidget: openImageUploadModal } = useCloudinaryWidget(
  environment,
  {
    sources: ['local', 'image_search', 'url', 'camera', 'dropbox'],
  }
);
const { handleImageRemoved, imagePath, imageButtonTooltipText, uploadCallback } = useImageUpload(
  emit,
  toRef(props, 'image')
);

initCloudinaryWidget((_error: Error, response: CloudinaryResponse) => {
  if (response.event !== 'success') {
    return;
  }

  const result = response.info;
  const imageUrl = getImageUrl(result);
  const imageDataJsonString = getImageDataJsonString(result);
  uploadCallback(imageUrl, imageDataJsonString);
});
// #endregion Image Upload

// #region Video Upload

const { apiClient } = useApiClient();
const { apiClient: fetchApiClient } = useApiClient(true);
const { getPresignedS3Url, uploadToS3 } = useAwsS3(apiClient, fetchApiClient);

const {
  showDropzone,
  videosAttached,
  videoButtonTooltipText,
  videoUploading,
  handleVideoRemoved,
  toggleVideoDropzone,
  handleVideoUpload,
  cancelVideoUpload,
} = useVideoUpload(
  emit,
  getPresignedS3Url,
  uploadToS3,
  toRef(props, 'video'),
  props.teamPostsPresignsPath
);
// #endregion Video Upload

onMounted(() => refreshTooltips());

watch([() => props.video, () => props.image], async () => {
  await nextTick();
  /* istanbul ignore next */
  refreshTooltips();
});

const disableAttachmentButtons = computed(() => {
  return videoUploading.value || props.video || props.image || imagePath.value;
});

interface ILink {
  url: string;
  title: string;
}
</script>

<style scoped lang="scss">
.feed-post-input__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.feed-post-input__button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 0 0 10px;
}

.feed-post-input__avatar {
  margin-top: 4px;
  margin-right: 10px;
}

.feed-post-input__post-error {
  flex-basis: 100%;
  color: $boston-university-red;
  font-weight: 500;
  font-size: 12px;
}

.feed-post-input__link-error {
  color: $dark-goldenrod;
  font-weight: 500;
  font-size: 12px;

  & ::v-deep .icon {
    top: auto;
  }
}

.feed-post-input__video-preview {
  position: relative;
}

.feed-post-input__video-preview__close-button {
  z-index: 10;
}

.team-post-input__video-preview__progress {
  display: flex;
  align-items: center;
}

.team-post-input__video-preview__progress-bar {
  flex-grow: 1;
}

.feed-post-input__image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $platinum;
  padding: 20px;
}

.feed-post-input__image {
  border-radius: 4px;
  max-width: 100%;
}

.feed-post-input__input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.feed-post-input__input-toolbar {
  display: flex;
  flex-direction: row;
}
</style>
