<template>
  <component
    :is="attachmentWrapperElement"
    v-bind="attachmentWrapperAttributes"
    class="base-attachment"
    data-test-id="base-attachment"
  >
    <div class="base-attachment__inner">
      <BaseIcon
        :icon-name="icon"
        :type="iconType"
        size="x-large"
        class="m-r-1"
        data-test-id="base-attachment-icon"
      />
      <small class="base-attachment__text">{{ text }}</small>
      <BaseIcon
        v-if="showDownloadIcon"
        role="presentation"
        icon-name="download"
        align-middle
        data-test-id="document-attachment-download-icon"
        class="m-l-1"
      />
    </div>
  </component>
</template>

<script lang="ts">
import _ from 'lodash';
import { defineComponent, computed } from 'vue';
import BaseIcon from '@/components/atoms/Icon/Index.vue';

export default defineComponent({
  name: 'BaseAttachment',
  compatConfig: { MODE: 3 },
  components: {
    BaseIcon,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    showDownloadIcon: Boolean,
    iconType: String,
    url: String,
    openInNewTab: Boolean,
  },
  setup(props) {
    const attachmentWrapperElement = computed((): string => {
      return props.url ? 'a' : 'div';
    });

    const attachmentWrapperAttributes = computed(() => {
      return _.omitBy(
        {
          href: props.url,
          target: props.openInNewTab ? '_blank' : undefined,
          'aria-label': props.text,
        },
        _.isUndefined
      );
    });

    return {
      attachmentWrapperAttributes,
      attachmentWrapperElement,
    };
  },
});
</script>

<style lang="scss" scoped>
a.base-attachment {
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
.base-attachment__inner {
  display: flex;
  align-items: center;
}

.base-attachment__text {
  word-break: break-all;
  color: $dark-electric-blue;
}
</style>
