<template>
  <div>
    <div v-if="webinars && webinars.length > 0">
      <div class="announcement-group-header announcement-group-header--high"></div>
      <h5 class="announcement-in-header">
        {{ t('vue_templates.announcements_sidebar.upcoming_webinars') }}
      </h5>
      <div
        v-for="webinar in webinars"
        :key="webinar.id"
        class="announcement"
        :class="{ unseen: webinar.unseen }"
      >
        <h5>
          {{ webinar.header }}
        </h5>
        <img v-if="webinar.image_url" class="m-y-2" :src="webinar.image_url" />
        <div v-html="webinar.body"></div>
        <div class="m-y-1 text-muted">
          <Icon icon-name="t-calendar-date" />
          {{ webinar.datetime }}
        </div>
        <CustomButton
          v-if="webinar.url"
          :text="t('vue_templates.announcements_sidebar.rsvp_now')"
          size="small"
          :action="() => openLink(webinar.url)"
        />
      </div>
    </div>
    <div v-if="pastWebinarsUrl">
      <div class="announcement-group-header announcement-group-header--high"></div>
      <h5 class="announcement-in-header">
        {{ t('vue_templates.announcements_sidebar.past_webinars') }}
      </h5>
      <div class="announcement">
        <h5 v-html="t('vue_templates.announcements_sidebar.past_webinars_title')"></h5>
        <div class="m-y-1 text-muted">
          {{ t('vue_templates.announcements_sidebar.available_now') }}
        </div>
        <CustomButton
          :text="t('helpers.buttons.view')"
          size="small"
          :action="() => openLink(pastWebinarsUrl)"
        />
      </div>
    </div>
    <div
      v-if="webinarsLoaded && !pastWebinarsUrl && (!webinars || webinars.length === 0)"
      class="no-announcements"
    >
      <Icon size="xx-large" icon-name="t-folder" />
      <div class="text-muted">
        {{ t('vue_templates.announcements_sidebar.empty') }}
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/atoms/Button/Index.vue';
import Icon from '@/components/atoms/Icon/Index.vue';
import i18n from '@/mixins/i18n';

export default {
  name: 'SidebarWebinars',

  components: {
    CustomButton,
    Icon,
  },

  mixins: [i18n],

  props: {
    webinars: Array,
    webinarsLoaded: Boolean,
    pastWebinarsUrl: String,
  },

  methods: {
    openLink: function (link) {
      window.open(link, '_blank');
    },
  },
};
</script>
