<template>
  <div class="navigation-link">
    <a href="#" @click.stop.prevent="action">
      <i
        v-show="direction === 'left'"
        data-test-id="navigate-left-chevron"
        class="ic ic-chevron-left"
      />
      {{ item.label }}
      <i
        v-show="direction === 'right'"
        data-test-id="navigate-right-chevron"
        class="ic ic-chevron-right"
      />
    </a>
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';

export default {
  name: 'NavigationLink',

  mixins: [i18n],

  props: {
    direction: {
      type: String,
      default: 'right',
    },
    item: Object,
  },

  methods: {
    action: function () {
      this.$emit('navigate', this.item.navigateToMenu);
    },
  },
};
</script>

<style scoped lang="scss">
.navigation-link {
  &:hover,
  &:focus,
  &:active {
    background-color: $cultured;
  }
  a {
    color: $charcoal;
  }
  .ic {
    vertical-align: middle;
  }
}
</style>
