<template>
  <component :is="labelType === 'legend' ? 'fieldset' : 'div'">
    <div class="form-group">
      <span v-if="labelType === 'text'" class="strong">{{ label }}</span>
      <legend v-else-if="labelType === 'legend'" class="legend--as-label">{{ label }}</legend>
      <label v-else :for="labelFor" class="table-filter-wrapper__label control-label">{{
        label
      }}</label>
      <slot :props="$props" />
    </div>
    <a
      data-test-id="table-filter-wrapper-remove-filter-button"
      class="filter__remove"
      role="button"
      tabindex="0"
      :aria-label="t('vue_templates.activity_feed.remove_filter')"
      :title="t('vue_templates.activity_feed.remove_filter')"
      @click.prevent="removeFilter"
      @keyup.enter="removeFilter"
    >
      <i class="ic ic-error-cross" />
    </a>
  </component>
</template>

<script>
import i18n from '@/mixins/i18n';

export default {
  mixins: [i18n],

  props: {
    label: String,
    emitToParent: {
      type: Boolean,
      default: true,
    },
    labelType: String,
    labelFor: String,
  },

  data: function () {
    return {
      expandFilters: false,
    };
  },

  methods: {
    /*
      TableFilterWrapper is intended to be used by the other filter types i.e. TextFilter.vue and
      will usually have a parent. However, in the case that TableFilterWrapper is used directly
      the close event must be emitted from the component itself to avoid requiring a wrapper
      component.
    */
    removeFilter: function () {
      if (this.emitToParent) {
        this.$parent.$emit('filterRemoved');
        return;
      }

      this.$emit('filterRemoved');
    },
  },
};
</script>

<style scoped lang="scss">
.table-filter-wrapper__label {
  position: relative;
  left: 2px;
}
</style>
