import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { reactive } from 'vue';

const breakpoints = useBreakpoints(breakpointsTailwind);
export const mdAndGreater = breakpoints.greaterOrEqual('md');

export function useMobileFirst(className: string) {
  return reactive({
    [className]: true,
    [`${className}--md-and-greater`]: mdAndGreater,
  });
}
