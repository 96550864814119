<template>
  <TableFilterWrapper :label="label" :label-for="$.uid.toString()">
    <SelectInput
      :placeholder="placeholder"
      :label-for-id="$.uid.toString()"
      :items="items"
      @select="onChange"
    />
  </TableFilterWrapper>
</template>

<script>
import SelectInput from '@/components/atoms/SelectInput.vue';
import TableFilterWrapper from './TableFilterWrapper.vue';
import i18n from '@/mixins/i18n';

export default {
  compatConfig: { MODE: 3 },
  components: {
    TableFilterWrapper,
    SelectInput,
  },
  mixins: [i18n],

  props: {
    items: Array,
    label: String,
    modelValue: String,
    placeholder: String,
  },

  data: function () {
    return {
      expandFilters: false,
    };
  },

  methods: {
    onChange: function (value) {
      this.$emit('update:modelValue', value);
    },
  },
};
</script>
