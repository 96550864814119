<template>
  <div :title="tooltipTitle" data-toggle="tooltip" data-html="true" class="average-skill-rating">
    <div v-for="n in maxRating" :key="n" class="average-skill-rating__score-wrapper">
      <Icon
        data-test-id="average-skill-rating-filled-circle"
        icon-name="circle"
        :style="{ width: circleWidths[n - 1] + 'px' }"
        class="widget__dashboard-color average-skill-rating__score"
      />
      <Icon icon-name="circle" class="text-muted--light average-skill-rating__background" />
    </div>
    <small
      data-test-id="average-skill-rating-rating-text"
      class="average-skill-rating__label-text text-muted text-center"
      >{{ ratings[wholeScore] }}
    </small>
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';
import Icon from '@/components/atoms/Icon/Index.vue';

export default {
  components: {
    Icon,
  },
  mixins: [i18n],
  props: {
    skillRatingFramework: {
      type: Object,
      required: true,
    },
    tooltipTitle: {
      type: String,
      required: false,
    },
    averageRating: {
      type: String,
      required: true,
    },
  },

  data: function () {
    return {
      circleWidths: [],
      wholeScore: 0,
      fractionalScore: 0,
    };
  },

  computed: {
    maxRating: function () {
      return Object.keys(this.ratings).length;
    },
    ratings: function () {
      let labels = this.skillRatingFramework && this.skillRatingFramework.labels;
      let ratings = {};
      labels && labels.map((label) => (ratings[label.level] = label.label));
      return ratings;
    },
  },
  mounted: function () {
    const maxCircleWidth = 14;

    const splitValue = this.averageRating.split('.');
    this.wholeScore = parseInt(splitValue[0], 10);
    this.fractionalScore = parseInt(splitValue[1], 10);

    for (let index = 1; index <= this.maxRating; index++) {
      if (index <= this.wholeScore) {
        this.circleWidths.push(maxCircleWidth);
      } else if (index - 1 === this.wholeScore && this.fractionalScore > 0) {
        this.circleWidths.push(Math.round((maxCircleWidth / 100) * this.fractionalScore));
      } else {
        this.circleWidths.push(0);
      }
    }
  },
};
</script>

<style scoped lang="scss">
$circle-width: 14px;
.average-skill-rating {
  display: inline-block;
  height: $circle-width;

  .average-skill-rating__label-text {
    display: block;
  }

  .average-skill-rating__score-wrapper {
    height: 100%;
    position: relative;
    display: inline-block;
    width: 24px;
  }
  .average-skill-rating__background {
    position: absolute;
    font-size: $circle-width;
  }
  .average-skill-rating__score {
    display: inline-block;
    position: absolute;
    overflow: hidden;
    z-index: 1;
    font-size: $circle-width;
  }
}
</style>
