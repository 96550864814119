<template>
  <PlayerResumer :show-resume-prompt="showResumePrompt" @resume="handleResume">
    <div v-if="useYoutubePlayer" class="player-embed__youtube-wrapper">
      <div :id="youtubeEmbedId" class="player-embed-youtube" data-test-id="youtube-embed" />
    </div>
    <video
      v-else-if="provider === 'html5'"
      :id="plyrEmbedId"
      style="max-width: 100%"
      playsinline
      controls
      data-test-id="html5-embed"
    >
      <source :src="mediaUrl" />
    </video>
    <div
      v-else
      :id="plyrEmbedId"
      :data-plyr-provider="provider"
      :data-plyr-embed-id="mediaUrl"
      data-test-id="vimeo-embed"
    />
  </PlayerResumer>
</template>
<script>
// Components
import PlayerResumer from '@/components/atoms/PlayerResumer/Index.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'PlayerEmbed',
  components: { PlayerResumer },
  props: {
    autoplay: Boolean,
    fullscreen: Boolean,
    mediaUrl: String,
    mediaId: String,
    provider: {
      type: String,
      required: true,
      validator(provider) {
        return ['youtube', 'vimeo', 'html5'].includes(provider);
      },
    },
    triggerCompletion: Boolean,
  },
  data: () => ({
    randomId: '',
    endedListenerSet: false,
    hasPlayerStarted: false,
    player: null,
    rewindLastTimeSeconds: 3.0,
    showResumePrompt: false,
    shouldAskAboutResuming: true,
    startTrackingFromSeconds: 15.0,
    trackProgressInterval: null,
    trackTotalPlaybackInterval: null,
    youtubeScriptURL: 'https://www.youtube.com/iframe_api',
  }),
  computed: {
    /**
     * We cannot programmatically set fullscreen for Youtube player
     * due to security reasons so we have to default back to Plyr
     * if this prop is set.
     *
     */
    useYoutubePlayer() {
      return this.provider === 'youtube' && !this.fullscreen;
    },
    plyrEmbedId() {
      return `player-embed-plyr-${this.randomId}`;
    },
    youtubeEmbedId() {
      return `player-embed-youtube-${this.randomId}`;
    },
  },
  watch: {
    /**
     * The "autoplay" prop is passed from dashboard widget. We
     * need to be able to play when the widget overlay is clicked.
     */
    autoplay() {
      this.handleAutoplay();
    },
  },
  created() {
    this.randomId = String(uuidv4());
  },
  mounted() {
    this.initializePlayer();
  },
  beforeUnmount() {
    clearInterval(this.trackProgressInterval);
  },
  methods: {
    attemptResuming() {
      if (this.getResumeAtFromLocalStorage() < this.startTrackingFromSeconds) return;
      if (!this.shouldAskAboutResuming) return;

      this.pause();
      this.showResumePrompt = true;
      this.shouldAskAboutResuming = false;
    },
    enterFullscreen() {
      if (!this.useYoutubePlayer) {
        this.player.fullscreen.enter();
      }
    },
    getAllTotalPlaybackTimesFromLocalStorage() {
      return JSON.parse(localStorage.getItem('videos_time_watched')) || {};
    },
    getTotalPlaybackFromLocalStorage() {
      if (typeof localStorage !== 'undefined' && localStorage !== null) {
        const progresses = this.getAllTotalPlaybackTimesFromLocalStorage();
        return parseFloat(progresses[this.mediaUrl]) || 0.0;
      }
    },
    getProgressesFromLocalStorage() {
      return JSON.parse(localStorage.getItem('videos_progress')) || {};
    },
    getResumeAtFromLocalStorage() {
      if (typeof localStorage !== 'undefined' && localStorage !== null) {
        const progresses = this.getProgressesFromLocalStorage();
        return parseFloat(progresses[this.mediaUrl]) || 0.0;
      }
    },
    /**
     * Method used to play video using a prop to trigger from outside of
     * this component.
     */
    handleAutoplay() {
      if (this.player && this.autoplay) this.play();
    },
    /**
     * Handle end of playback by clearing intervals and storage
     * and emitting event.
     */
    handleEnded() {
      clearInterval(this.trackProgressInterval);
      this.trackProgressInterval = null;
      this.setResumeAtInLocalStorage(0.0);

      if (!this.triggerCompletion) return;
      window.emitter.emit('playback-complete');
    },
    /**
     * Trigger the start handler and set an event for "ended"
     * when Plyr instance plays for the first time.
     */
    handlePlyrInstancePlaying() {
      if (this.hasPlayerStarted) return;

      this.handleStarted();
      if (this.endedListenerSet) return;

      this.player.on('ended', this.handleEnded);
      this.player.on('ended pause', this.stopTrackingTotalPlayback);
      this.endedListenerSet = true;
    },
    /**
     * Handle resuming the video at a specific time and closing resume prompt.
     * @param {Boolean} fromStart Play from start if true, otherwise use tracked time in local storage.
     */
    handleResume(fromStart = true) {
      this.showResumePrompt = false;
      if (fromStart) {
        this.play();
        this.setResumeAtInLocalStorage(0.0);
      } else {
        this.play(this.getResumeAtFromLocalStorage() - this.rewindLastTimeSeconds);
      }
      if (this.fullscreen) this.enterFullscreen();
    },
    /**
     * Handle starting the video for the first time, prompting a resume
     * option for user and triggering intervals.
     */
    handleStarted() {
      this.startTrackingTotalPlayback();

      if (this.hasPlayerStarted) return;
      window.emitter.emit('playback-started');
      this.hasPlayerStarted = true;
      this.attemptResuming();
      this.startTrackingProgress();
      if (this.fullscreen && !this.showResumePrompt) this.enterFullscreen();
    },
    /**
     * Checks the progress time is greater than the minimum progress tracking
     * time before storing in local storage.
     * @param {Number} time Time in seconds the video has progressed.
     */
    handleTrackingProgress(time) {
      const parsedTime = parseFloat(time);
      if (!parsedTime || parsedTime < this.startTrackingFromSeconds) return;
      this.setResumeAtInLocalStorage(parsedTime);
    },
    initializePlayer() {
      if (this.useYoutubePlayer) {
        this.inializeYoutube();
      } else {
        this.itializePlyr();
      }
    },
    /**
     * Initialize a Plyr instance and add listeners.
     * https://github.com/sampotts/plyr
     */
    itializePlyr() {
      this.player = new Plyr(`#${this.plyrEmbedId}`, {});
      this.player.on('playing', this.handlePlyrInstancePlaying);
    },
    /**
     * Starts the initialisation of a Youtube instance.
     * A script tag is appended which downloads the youtube iframe api
     * then triggers the onYouTubeIframeAPIReady event.
     * https://developers.google.com/youtube/iframe_api_reference
     */
    inializeYoutube() {
      if (!document.querySelector(`[src="${this.youtubeScriptURL}"]`)) {
        window.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady;

        const tag = document.createElement('script');
        tag.src = this.youtubeScriptURL;
        document.getElementsByTagName('head')[0].append(tag);
      } else if (window.youtubeIframeLoaded) {
        this.onYouTubeIframeAPIReady();
      } else {
        // TODO: Replace with Vuex/Pinia when state management is implemented!
        window.emitter.on('youtube-iframe-loaded', this.onYouTubeIframeAPIReady);
      }
    },
    /**
     * State handler for the Youtube iframe api
     * @param {Object} event An event emitted by the Youtube iframe.
     * @param {number} event.data Indicates the state of the video.
     */
    onPlayerStateChange({ data: state }) {
      switch (state) {
        case 0 /* ended */:
          this.handleEnded();
          this.stopTrackingTotalPlayback();
          return;
        case 1 /* playing */:
          this.handleStarted();
          return;
        case 2 /* paused */:
          this.stopTrackingTotalPlayback();
          return;
        default:
          return;
      }
    },
    /**
     * Initialises the youtube iframe which custom config.
     */
    onYouTubeIframeAPIReady() {
      if (!window.youtubeIframeLoaded) {
        window.youtubeIframeLoaded = true;
        window.emitter.emit('youtube-iframe-loaded');
      }

      this.player = new YT.Player(this.youtubeEmbedId, {
        videoId: this.mediaId || this.extractVideoIdFromUrl(this.mediaUrl),
        playerVars: {
          cc_lang_pref: 'en', // Required for closed-captions!
          cc_load_policy: 1, // Display closed-captions by default.
          modestbranding: 1, // Remove 'Watch on Youtube' button from toolbar.
          playsinline: 1, // Prevents auto-fullscreen on mobile.
          rel: 0, // Only show related videos from current channel.
        },
        events: {
          onStateChange: this.onPlayerStateChange,
          onApiChange: this.turnOnYTAutoGenCaptions,
        },
      });
    },
    extractVideoIdFromUrl(url) {
      return url.split('/embed/')[1];
    },
    pause() {
      if (!this.player) return;

      if (this.useYoutubePlayer) {
        this.player.pauseVideo();
      } else {
        this.player.pause();
      }
    },
    play(time = 0) {
      if (!this.player) return;

      if (this.useYoutubePlayer) {
        this.player.seekTo(time);
        this.player.playVideo();
      } else {
        this.player.currentTime = time;
        this.player.play();
      }
    },
    /**
     * Set the tracked time in seconds for a specific video in local storage.
     * @param {Number} time The video's current time in seconds.
     */
    setResumeAtInLocalStorage(time) {
      if (typeof localStorage !== 'undefined' && localStorage !== null) {
        const progresses = this.getProgressesFromLocalStorage();
        progresses[this.mediaUrl] = time;
        return localStorage.setItem('videos_progress', JSON.stringify(progresses));
      }
    },
    /**
     * Set the accumulated watch time for a specific video in local storage.
     * @param {Number} time The video's accumulated watch time.
     */
    setTotalPlaybackInLocalStorage(time) {
      if (typeof localStorage !== 'undefined' && localStorage !== null) {
        const progresses = this.getAllTotalPlaybackTimesFromLocalStorage();
        progresses[this.mediaUrl] = time;
        return localStorage.setItem('videos_time_watched', JSON.stringify(progresses));
      }
    },
    /**
     * Sets an interval or listener to track the video's progress.
     */
    startTrackingProgress() {
      if (this.useYoutubePlayer) {
        this.trackProgressInterval = setInterval(() => {
          this.handleTrackingProgress(this.player.getCurrentTime());
        }, 3000);
      } else {
        this.player.on('timeupdate', () => {
          /* istanbul ignore next */
          this.handleTrackingProgress(this.player.currentTime);
        });
      }
    },
    /**
     * Sets an interval to track the video's total playback time.
     */
    startTrackingTotalPlayback() {
      this.timeAtRecordedTotalPlayback = new Date().getTime();

      clearInterval(this.trackTotalPlaybackInterval);
      this.trackTotalPlaybackInterval = setInterval(() => {
        this.updateTotalPlaybackTime(this.timeAtRecordedTotalPlayback);
        this.timeAtRecordedTotalPlayback = new Date().getTime();
      }, 5000);
    },
    /**
     * Clears interval for tracking playback time (when paused/ended).
     */
    stopTrackingTotalPlayback() {
      clearInterval(this.trackTotalPlaybackInterval);
      this.updateTotalPlaybackTime(this.timeAtRecordedTotalPlayback);
      this.timeAtRecordedTotalPlayback = new Date().getTime();
    },
    /**
     * This code is to turn on auto-captions because closed-captions
     * will only appear if they are set on the video.
     * This is not in the official docs so there is no guarentee
     * that this will work in future.
     */
    turnOnYTAutoGenCaptions() {
      if (typeof this.player.setOption === 'function') {
        this.player.setOption('captions', 'track', { languageCode: 'en' });
      }
    },
    /**
     * Sets the total playback time for a video and emits to event bus to
     * record in Activity model.
     * @param {Number} startTime Representing a date in milliseconds.
     */
    updateTotalPlaybackTime(startTime) {
      const seconds = (new Date().getTime() - startTime) / 1000;
      const totalTime = this.getTotalPlaybackFromLocalStorage() + seconds;
      this.setTotalPlaybackInLocalStorage(totalTime);
      window.emitter.emit('tracking-time-update', totalTime);
    },
  },
};
</script>

<style lang="scss">
.player-embed__youtube-wrapper {
  position: relative;
  padding-bottom: 56.5%;
  height: 0;
  width: 100%;

  .fit-height & {
    padding-bottom: 0;
    height: 100%;
  }
}

.player-embed-youtube {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
