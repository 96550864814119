<script lang="ts">
export default {
  name: 'SurveyPanel',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
// Modules
import { defineProps, defineEmits, ref } from 'vue';
// Components
import BaseIcon from '@/components/atoms/Icon/Index.vue';
import BasePill from '@/components/atoms/BasePill/Index.vue';
import ExpansionPanel from '@/components/molecules/ExpansionPanel/Index.vue';
import SurveyPanelQuestions from '@/components/organisms/one-to-one/SurveyPanelQuestions/Index.vue';
import TabGroup, { Tab } from '@/components/organisms/TabGroup/Index.vue';
// Types & Configs
import { ReviewRole, Survey, SurveyAnswersFrom, SurveyAttempt } from '@/types/Survey';
// Composables
import { useI18n } from '@/composables/useI18n';

const emit = defineEmits<{
  (e: 'survey-update', survey: Survey): void;
}>();
const props = defineProps<{ survey: Survey; oneToOneName: string }>();

const { t } = useI18n();

const isSurveyForBoth = props.survey.answers_from === SurveyAnswersFrom.BOTH;

const revieweeTabKey = ReviewRole.REVIEWEE;
const reviewerTabKey = ReviewRole.REVIEWER;
const tabs: Tab[] = isSurveyForBoth
  ? [
      {
        key: revieweeTabKey,
        text: t('vue_templates.components.one_to_one.check_in.reviewee'),
      },
      {
        key: reviewerTabKey,
        text: t('vue_templates.components.one_to_one.check_in.reviewer'),
        disabled: !props.survey.reviewer_attempt,
      },
    ]
  : [];

const lockTooltip = {
  content: t('vue_templates.one_to_one.private'),
  placement: 'right',
};

const isReviewer = props.survey?.reviewer_attempt && props.survey.reviewer_attempt?.can_answer;

const initialTabKey =
  isReviewer && !props.survey?.reviewer_attempt?.submitted_at ? reviewerTabKey : revieweeTabKey;

const revieweeTabSlotName = `tab-${ReviewRole.REVIEWEE}`;
const reviewerTabSlotName = `tab-${ReviewRole.REVIEWER}`;

const attemptHasErrors = ref(false);
const getStatusPillAttributes = (attempt: SurveyAttempt) => {
  if (!attempt) return {};

  if (attempt.can_answer) {
    if (attemptHasErrors.value) {
      return {
        content: t('vue_templates.components.one_to_one.survey_panel.missing_answers'),
        theme: 'danger',
      };
    } else if (attempt.submitted_at) {
      return {
        content: t('vue_templates.components.one_to_one.survey_panel.answers_submitted'),
        theme: 'success',
        icon: 'check',
      };
    } else if (attempt.started_at) {
      return {
        content: t('vue_templates.components.one_to_one.survey_panel.draft_saved'),
        theme: 'warning',
      };
    } else {
      return {
        content: t('vue_templates.components.one_to_one.survey_panel.add_your_answers'),
        theme: 'learnamp',
      };
    }
  } else {
    if (attempt.submitted_at) {
      return {
        content: t('vue_templates.components.one_to_one.survey_panel.answers_provided'),
        theme: 'success',
        icon: 'check',
      };
    } else {
      return {
        content: t('vue_templates.components.one_to_one.survey_panel.pending_answers'),
        theme: 'warning',
      };
    }
  }
};

const onUserAttemptUpdated = (role: SurveyAnswersFrom, attempt: SurveyAttempt) => {
  if (!role) return;

  attemptHasErrors.value = false;
  const updatedSurvey = Object.assign({}, props.survey, {
    [`${role}_attempt`]: attempt,
  });
  emit('survey-update', updatedSurvey);
};

const onUserAttemptError = () => {
  attemptHasErrors.value = true;
};
</script>

<template>
  <ExpansionPanel open rounded prominent class="survey-panel" :header-label="survey.name">
    <template #header>
      <span>{{ survey.name }}</span>
      <BasePill
        v-if="!isSurveyForBoth"
        v-bind="getStatusPillAttributes(survey.reviewer_attempt || survey.reviewee_attempt)"
        size="small"
        class="survey-panel__status-pill"
        data-test-id="survey-panel-panel-status"
      />
    </template>

    <div class="survey-panel__content">
      <TabGroup v-if="isSurveyForBoth" :tabs="tabs" :initial-tab-key="initialTabKey">
        <template #[revieweeTabSlotName]>
          <span>
            <span>{{ t('vue_templates.components.one_to_one.check_in.reviewee') }}</span>
            <BasePill
              v-bind="getStatusPillAttributes(survey.reviewee_attempt)"
              size="small"
              class="survey-panel__status-pill"
              data-test-id="survey-panel-tab-status"
            />
          </span>
        </template>

        <template #[reviewerTabSlotName]>
          <span>
            <span>{{ t('vue_templates.components.one_to_one.check_in.reviewer') }}</span>
            <BaseIcon
              v-if="survey.private_survey"
              v-tippy="lockTooltip"
              icon-name="t-security-lock"
              class="survey-panel__tab-icon"
            />
            <BasePill
              v-if="survey.reviewer_attempt"
              v-bind="getStatusPillAttributes(survey.reviewer_attempt)"
              size="small"
              class="survey-panel__status-pill"
              data-test-id="survey-panel-tab-status"
            />
          </span>
        </template>

        <template #[revieweeTabKey]>
          <SurveyPanelQuestions
            :survey="survey"
            :one-to-one-name="oneToOneName"
            :init-attempt="survey.reviewee_attempt"
            :survey-for="SurveyAnswersFrom.REVIEWEE"
            @updated="onUserAttemptUpdated(SurveyAnswersFrom.REVIEWEE, $event)"
            @error="onUserAttemptError"
          />
        </template>

        <template #[reviewerTabKey]>
          <SurveyPanelQuestions
            v-if="survey.reviewer_attempt"
            :survey="survey"
            :one-to-one-name="oneToOneName"
            :init-attempt="survey.reviewer_attempt"
            :survey-for="SurveyAnswersFrom.REVIEWER"
            @updated="onUserAttemptUpdated(SurveyAnswersFrom.REVIEWER, $event)"
            @error="onUserAttemptError"
          />
        </template>
      </TabGroup>

      <SurveyPanelQuestions
        v-else
        :survey="survey"
        :one-to-one-name="oneToOneName"
        :init-attempt="survey.reviewer_attempt || survey.reviewee_attempt"
        :survey-for="survey.answers_from"
        @updated="onUserAttemptUpdated(survey.answers_from, $event)"
        @error="onUserAttemptError"
      />
    </div>
  </ExpansionPanel>
</template>

<style lang="scss" scoped>
.survey-panel__content {
  position: relative;
  padding-top: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
}

.survey-panel__tab-icon {
  font-weight: bold;
  margin-left: 0.5rem;
}

.survey-panel__status-pill {
  margin-left: 1rem;
}
</style>
