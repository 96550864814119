<template>
  <div
    class="tab-group"
    :class="{
      'tab-group--grow': grow,
      'tab-group--no-animate': !animate,
    }"
  >
    <div class="tab-group__tabs" role="tablist">
      <div v-if="animate" :style="animatedTabStyles" class="tab-group__animated-active-border" />
      <TabGroupButton
        v-for="tab in tabs"
        :key="tab.key"
        ref="tabs"
        :disabled="tab.disabled"
        :animate="animate"
        :panel-id="getTabPanelId(tab.key)"
        :active="isTabActive(tab.key)"
        :data-active="isTabActive(tab.key) || null"
        :data-test-id="`tab-button-${tab.key}`"
        class="tab-group__tab"
        @click="handleTabSelected(tab.key)"
      >
        <slot :name="`tab-${tab.key}`">
          {{ tab.text }}
        </slot>
      </TabGroupButton>
    </div>
    <div
      v-for="tab in tabs"
      v-show="isTabActive(tab.key)"
      :id="getTabPanelId(tab.key)"
      :key="tab.key"
      :data-test-id="`tab-content-${tab.key}`"
      class="tab-group__content"
      role="tabpanel"
    >
      <slot :name="tab.key" />
    </div>
  </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { ComponentPublicInstance, PropType } from 'vue';
import TabGroupButton from '@/components/atoms/TabGroupButton/Index.vue';

export type Tab = {
  text: string;
  key: string;
  disabled?: boolean;
};

export default {
  name: 'TabGroup',
  components: { TabGroupButton },
  props: {
    tabs: {
      type: Array as PropType<Tab[]>,
      required: true,
    },
    animate: {
      type: Boolean,
      default: true,
    },
    initialTabKey: String,
    grow: Boolean,
  },
  data() {
    return {
      activeTabKey: this.initialTabKey,
      activeTabWidth: null,
      activeTabPositionLeft: 0,
    };
  },
  computed: {
    animatedTabStyles() {
      return {
        width: `${this.activeTabWidth}px`,
        left: `${this.activeTabPositionLeft}px`,
      };
    },
  },
  mounted() {
    if (this.tabs?.length) {
      if (!this.initialTabKey || !this.tabs.find(({ key }: Tab) => key === this.initialTabKey)) {
        this.handleTabSelected(this.tabs[0].key);
      } else {
        this.handleTabSelected(this.initialTabKey);
      }
    }
  },
  methods: {
    getTabPanelId(key: string): string {
      return `tab-panel-${_.kebabCase(key)}`;
    },
    isTabActive(key: string): boolean {
      return this.activeTabKey === key;
    },
    handleTabSelected(key: string): void {
      this.activeTabKey = key;
      this.$nextTick(() => {
        const tabs = this.$refs?.tabs as ComponentPublicInstance[];
        const activeTabElement = tabs?.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (component: any) => component.$el.dataset.active
        )?.$el;
        if (activeTabElement) {
          this.activeTabWidth = activeTabElement.clientWidth;
          this.activeTabPositionLeft = activeTabElement.offsetLeft;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-group__tabs {
  position: relative;
  display: inline-flex;
  align-content: center;
  margin-bottom: 20px;
}

.tab-group__animated-active-border {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: var(--button-color);
  z-index: 0;
  pointer-events: none;
  transition: width ease 0.3s, left ease 0.3s;
}

.tab-group__tab {
  flex-grow: 1;
  flex-basis: 0;

  @media screen and (min-width: $screen-sm-min) {
    flex-grow: initial;
    flex-basis: auto;
  }
}

// MODIFIERS
// ==============================

.tab-group--grow {
  .tab-group__tabs {
    display: flex;
  }

  .tab-group__tab {
    flex-grow: 1;
  }
}
</style>
