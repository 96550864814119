<template>
  <TableFilterWrapper :label="label" label-type="text">
    <SelectizeInput v-bind="$props" :label="label" />
  </TableFilterWrapper>
</template>

<script>
import SelectizeInput from '@/components/atoms/forms/SelectizeInput/Index.vue';
import TableFilterWrapper from './TableFilterWrapper.vue';
import i18n from '@/mixins/i18n';
import selectizeMixin from '@/mixins/selectize';

export default {
  components: {
    TableFilterWrapper,
    SelectizeInput,
  },
  mixins: [selectizeMixin, i18n],

  props: {
    label: String,
    srLabel: String,
  },
};
</script>
