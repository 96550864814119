<script lang="ts">
export default {
  name: 'DocumentAttachmentPreview',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, computed } from 'vue';
import { useI18n } from '@/composables/useI18n';
import Document from '@/types/Document';
import BaseButton from '@/components/atoms/BaseButton/Index.vue';

const props = defineProps<{ document: Document }>();
const { t } = useI18n();
const isImage = computed(() => props.document.image_path);
const isPDF = computed(() => props.document.pdf_path);
const isVideo = computed(() => props.document.video_path);
const isAudio = computed(() => props.document.audio_path);
</script>

<template>
  <div
    v-bind="$attrs"
    :class="{ 'document-attachment-preview--image': isImage }"
    class="document-attachment-preview"
    data-test-id="document-attachment-preview"
  >
    <template v-if="isImage">
      <img
        :src="document.image_path"
        alt=""
        class="document-attachment-preview__image"
        data-test-id="document-attachment-preview-image"
      />
      <div
        class="document-attachment-preview__image-title"
        data-test-id="document-attachment-preview-image-title"
      >
        {{ document.original_filename }}
      </div>
    </template>
    <template v-else-if="isPDF">
      <iframe
        :src="document.pdf_path"
        :title="document.original_filename"
        class="document-attachment-preview__pdf-viewer"
        data-test-id="document-attachment-preview-pdf"
      ></iframe>
    </template>
    <template v-else-if="isAudio">
      <player-audio
        :src="document.audio_path"
        :type="document.file_type || document.recording_file_type"
        :media-id="document.media_id"
        data-test-id="document-attachment-preview-audio"
      />
    </template>
    <template v-else-if="isVideo">
      <player-video
        :document-file="{
          src: document.video_path,
          size: document.file_size_in_mb || document.recording_file_size_in_mb,
          type: document.file_type,
          mediaId: document.media_id,
        }"
        data-test-id="document-attachment-preview-video"
      />
    </template>
  </div>
  <div
    v-if="document.download_path"
    class="document-attachment-preview__actions"
    data-test-id="document-attachment-preview-actions"
  >
    <BaseButton
      :text="t('helpers.common.download')"
      prepend-icon="download"
      :link="{ href: document.download_path, target: '_blank' }"
      data-test-id="document-attachment-preview-download-button"
    />
  </div>
</template>

<style lang="scss" scoped>
.document-attachment-preview--image {
  background-color: $white-smoke;
  padding: 1rem;
  border-radius: 0.4rem;
}

.document-attachment-preview__image {
  max-width: 100%;
  max-height: 800px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.document-attachment-preview__image-title {
  margin-top: 0.8rem;
  color: $feldgrau;
  font-size: 1.2rem;
}

.document-attachment-preview__pdf-viewer {
  width: 100%;
  border: 0;
  height: 400px;
  max-height: 70vh;

  @media screen and (min-width: $screen-sm-min) {
    height: 500px;
  }

  @media screen and (min-width: $screen-md-min) {
    height: 600px;
  }
}

.document-attachment-preview__actions {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}
</style>
