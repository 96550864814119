<template>
  <span class="tile-label__label">{{ text }}</span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TileLabel',
  props: {
    text: String,
  },
});
</script>

<style lang="scss" scoped>
.tile-label__label {
  font-size: 10px;
  border: 2px solid $north-texas-green;
  background-color: $pale-green;
  display: inline-block;
  padding: 1px 6px;
  font-weight: 600;
  border-radius: 3px;
}
</style>
