<template>
  <Tile
    v-if="json"
    :data-test-id="`survey-tile-${json.id}`"
    :json="json"
    :locked="locked"
    :locked-until-tasked="lockedUntilTasked"
    :type="json.tile_type"
    :user-endpoint="userEndpoint"
    :carousel-notice-displayed="carouselNoticeDisplayed"
    :learnlist-notice-displayed="learnlistNoticeDisplayed"
    :modal-copy="modalCopy"
    :use-legacy-menu="true"
  >
    <template #body-properties>
      <TileProperties
        :data-test-id="`survey-tile-${json.id}-properties`"
        :properties="properties"
      />
      <div class="tile__details m-t-2">
        <i class="ic ic-b1-list-checkbox" />
        {{ itemCountPluralize(json.questions_count) }}
      </div>
      <div v-if="json.total_time && mdAndGreater" class="tile__details m-t-1">
        <i class="ic ic-t-clock" />
        {{ json.total_time }}
      </div>
    </template>
    <template #footer-content>
      <BookmarkButton
        :id="json.id"
        :data-test-id="`survey-tile-${json.id}-bookmark`"
        :type="json.tile_type"
        :init-liked="json.liked"
      />
    </template>
  </Tile>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useI18n } from '@/composables/useI18n';
import { useTileProps } from '@/composables/useTileProps';
import BookmarkButton from '@/components/molecules/BookmarkButton.vue';
import TileProperties from '@/components/atoms/Tile/TileProperties.vue';
import Tile from '@/components/organisms/Tiles/BaseTile/Index.vue';
import pluralizeWord from '@/helpers/pluralize-word';
import { mdAndGreater } from '@/composables/useMobileFirst';

export default defineComponent({
  name: 'SurveyTile',
  components: {
    BookmarkButton,
    TileProperties,
    Tile,
  },
  props: {
    ...useTileProps('survey'),
  },
  setup() {
    const { t } = useI18n();
    const properties = computed(() => {
      return [t('activerecord.models.survey.one')];
    });

    const itemCountPluralize = (itemCount) => {
      return pluralizeWord(
        t('activerecord.models.question.one'),
        t('activerecord.models.question.other'),
        itemCount
      );
    };

    return {
      itemCountPluralize,
      mdAndGreater,
      properties,
      t,
    };
  },
});
</script>

<style scoped lang="scss"></style>
