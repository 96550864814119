<template>
  <b :class="{ 'text-label--muted': type === 'muted' }">
    {{ text }}
  </b>
</template>

<script>
export default {
  name: 'TextLabel',
  props: {
    text: String,
    type: {
      type: String,
      validator: (propValue) => {
        const types = ['muted'];
        const isValid = types.find((type) => type === propValue);
        return isValid;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.text-label--muted {
  color: $feldgrau;
  font-weight: normal;
}
</style>
