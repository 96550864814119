<template>
  <Tile
    v-if="json"
    :data-test-id="`item-tile-${json.id}`"
    :json="json"
    :locked="locked"
    :locked-until-tasked="lockedUntilTasked"
    :type="json.tile_type"
    :user-endpoint="userEndpoint"
    :carousel-notice-displayed="carouselNoticeDisplayed"
    :learnlist-notice-displayed="learnlistNoticeDisplayed"
    :modal-copy="modalCopy"
    :use-legacy-menu="true"
  >
    <template #body-properties>
      <TileProperties :data-test-id="`item-tile-${json.id}-properties`" :properties="properties">
        <TileLabel v-if="json.show_label_new" class="m-r-1" :text="newLabelText" />
      </TileProperties>
    </template>
    <template #footer-content>
      <BookmarkButton
        :id="json.id"
        :data-test-id="`item-tile-${json.id}-bookmark`"
        :type="json.tile_type"
        :init-liked="json.liked"
      />
    </template>
  </Tile>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useI18n } from '@/composables/useI18n';
import { useTileProps } from '@/composables/useTileProps';
import BookmarkButton from '@/components/molecules/BookmarkButton.vue';
import TileProperties from '@/components/atoms/Tile/TileProperties.vue';
import Tile from '@/components/organisms/Tiles/BaseTile/Index.vue';
import TileLabel from '@/components/atoms/Tile/TileLabel.vue';

export default defineComponent({
  components: {
    BookmarkButton,
    TileProperties,
    Tile,
    TileLabel,
  },
  props: {
    ...useTileProps('item'),
  },
  setup(props) {
    const { t } = useI18n();
    const newLabelText = ref(t('helpers.common.new').toUpperCase());
    const properties = computed(() => {
      const propArray: string[] = [];
      if (props.json?.item_type) {
        propArray.push(props.json.type_label);
      }

      if (props.json?.total_time) {
        propArray.push(props.json.total_time);
      }

      return propArray;
    });
    return {
      newLabelText,
      properties,
      t,
    };
  },
});
</script>

<style scoped lang="scss"></style>
