<script lang="ts">
export default {
  name: 'InputSlider',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineEmits, defineProps, Ref, ref, onMounted, withDefaults } from 'vue';
import { useNoUiSlider } from '@/composables/useNoUiSlider';
import * as nouislider from 'nouislider';

const props = withDefaults(defineProps<{ modelValue?: number; min?: number; max?: number }>(), {
  modelValue: 0,
  min: 0,
  max: 5,
});
const emit = defineEmits(['update:model-value']);

const slider: Ref<HTMLElement | null> = ref(null);
const noUiSliderInstance: Ref<nouislider.API | null> = ref(null);

const onSliderInput = (value: (string | number)[]) => {
  if (value?.length) {
    emit('update:model-value', Number(value[0]));
  }
};

onMounted(() => {
  if (slider.value) {
    noUiSliderInstance.value = useNoUiSlider(slider.value, {
      start: props.modelValue,
      min: props.min,
      max: props.max,
    });

    noUiSliderInstance.value?.on('change', onSliderInput);
  }
});
</script>

<template>
  <slot />
  <div v-bind="$attrs" ref="slider" class="slider-input"></div>
</template>
