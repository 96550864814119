<script lang="ts">
export default {
  name: 'BaseModal',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import {
  defineEmits,
  defineProps,
  withDefaults,
  ref,
  Ref,
  watch,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import * as focusTrap from 'focus-trap';
import { useI18n } from '@/composables/useI18n';
import BaseButton from '@/components/atoms/BaseButton/Index.vue';

interface Props {
  modelValue: boolean;
  title?: string;
  showFooter?: boolean;
  showOverlay?: boolean;
  showExpandButton?: boolean;
  showCancelButton?: boolean;
  showConfirmButton?: boolean;
  paddedBody?: boolean;
  persistent?: boolean;
  size?: 'large' | 'medium';
  disabled?: boolean;
  focusTrapEnabled?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  showFooter: true,
  showCancelButton: true,
  showConfirmButton: true,
  showOverlay: true,
  paddedBody: true,
  focusTrapEnabled: true,
});
const emit = defineEmits(['update:modelValue', 'clicked:confirm']);

const { t } = useI18n();
const expanded = ref(false);
const modal: Ref<HTMLElement | null> = ref(null);
const closeButton: Ref<InstanceType<typeof BaseButton> | null> = ref(null);
const trap: Ref<focusTrap.FocusTrap | null> = ref(null);
const isAnimatingShake = ref(false);

const createFocusTrap = () => {
  trap.value = focusTrap.createFocusTrap(modal.value as HTMLElement, {
    allowOutsideClick: true, // required to allow shake animation when persistent
    escapeDeactivates: !props.persistent,
    initialFocus: false,
  });
  trap.value.activate();
};

const deactivateFocusTrap = () => {
  trap.value?.deactivate();
};

const getScrollbarWidth = () => {
  try {
    return window.innerWidth - document.body.offsetWidth;
  } catch (_e) {
    return 0;
  }
};

const setOverflowHidden = () => {
  // Add padding to prevent content from shifting when scrollbar disappears
  document.body.style.paddingRight = `${getScrollbarWidth()}px`;
  if (document.querySelector('.navbar-fixed-top')) {
    (
      document.querySelector('.navbar-fixed-top') as HTMLElement
    ).style.paddingRight = `${getScrollbarWidth()}px`;
  }
  document.body.style.overflow = 'hidden';
};

const removeOverflowHidden = () => {
  document.body.style.removeProperty('padding-right');
  if (document.querySelector('.navbar-fixed-top')) {
    (document.querySelector('.navbar-fixed-top') as HTMLElement).style.removeProperty(
      'padding-right'
    );
  }
  document.body.style.removeProperty('overflow');
};

watch(
  () => modal.value,
  async (value) => {
    if (value) {
      setOverflowHidden();
      if (!trap.value?.active) {
        createFocusTrap();
      }
    } else {
      removeOverflowHidden();
      if (trap.value?.active) {
        deactivateFocusTrap();
      }
    }
  }
);

watch(
  () => props.focusTrapEnabled,
  (value) => {
    if (value) {
      createFocusTrap();
    } else {
      deactivateFocusTrap();
    }
  }
);

const onOverlayClick = () => {
  if (!props.persistent && !props.disabled) {
    emit('update:modelValue', false);
  } else {
    handlePersistent();
  }
};

const onKeydown = (event: KeyboardEvent) => {
  if (event.key === 'Escape' && !props.persistent && !props.disabled) {
    emit('update:modelValue', false);
  } else if (event.key === 'Escape' && props.persistent) {
    handlePersistent();
  }
};

const handlePersistent = () => {
  animateShake();
  focusCloseButton();
};

const animateShake = () => {
  if (isAnimatingShake.value) return;

  isAnimatingShake.value = true;
  setTimeout(() => {
    isAnimatingShake.value = false;
  }, 1000);
};

const focusCloseButton = () => {
  if (closeButton.value) {
    closeButton.value.$el.focus({ focusVisible: true });
  }
};

onMounted(() => {
  window.addEventListener('keydown', onKeydown);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', onKeydown);
});
</script>

<template>
  <teleport to="body">
    <transition name="fade">
      <div
        v-if="modelValue"
        :class="{ 'base-modal__overlay--visible': showOverlay }"
        class="base-modal__overlay"
        data-test-id="base-modal-overlay"
        role="presentation"
        @click="onOverlayClick"
        @keydown.esc="onOverlayClick"
      />
    </transition>
    <transition name="fade-down">
      <div
        v-if="modelValue"
        v-bind="$attrs"
        ref="modal"
        :class="{
          'base-modal--expanded': expanded,
          [`base-modal--size-${size}`]: size,
        }"
        class="base-modal"
        data-test-id="base-modal"
      >
        <div
          class="base-modal__inner"
          :class="{ 'animate-shake': isAnimatingShake }"
          data-test-id="base-modal-inner"
        >
          <slot name="header">
            <div class="base-modal__header" data-test-id="base-modal-header">
              <div class="base-modal__header-title" data-test-id="base-modal-header-title">
                <slot name="header-title">
                  {{ title }}
                </slot>
              </div>
              <div class="base-modal__header-controls m-l-4">
                <BaseButton
                  v-if="showExpandButton"
                  variant="icon"
                  :prepend-icon="expanded ? 'shrink' : 'expand'"
                  size="large"
                  theme="learnamp"
                  data-test-id="base-modal-toggle-expand-button"
                  :disabled="disabled"
                  :title="
                    t(`vue_templates.components.base_modal.${expanded ? 'shrink' : 'expand'}`)
                  "
                  @click="expanded = !expanded"
                />
                <BaseButton
                  ref="closeButton"
                  variant="icon"
                  prepend-icon="error-cross"
                  size="large"
                  theme="learnamp"
                  :disabled="disabled"
                  data-test-id="base-modal-close-button"
                  class="m-l-2"
                  :title="t('vue_templates.components.base_modal.close')"
                  @click="$emit('update:modelValue', false)"
                />
              </div>
            </div>
          </slot>
          <div
            :class="{ 'base-modal__body--padded': paddedBody }"
            class="base-modal__body"
            data-test-id="base-modal-body"
          >
            <slot />
          </div>
          <slot v-if="showFooter" name="footer">
            <div class="base-modal__footer" data-test-id="base-modal-footer">
              <BaseButton
                v-if="showCancelButton"
                variant="text"
                theme="learnamp"
                :disabled="disabled"
                data-test-id="base-modal-cancel-button"
                @click="$emit('update:modelValue', false)"
                >{{ t('vue_templates.components.base_modal.cancel') }}</BaseButton
              >
              <div class="base-modal__footer-spacer" />
              <BaseButton
                v-if="showConfirmButton"
                variant="default"
                theme="learnamp"
                :disabled="disabled"
                data-test-id="base-modal-confirm-button"
                @click="$emit('clicked:confirm')"
                >{{ t('vue_templates.components.base_modal.confirm') }}</BaseButton
              >
            </div>
          </slot>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<style lang="scss" scoped>
$modal-vertical-padding: 2rem;
$modal-horizontal-padding: 2rem;
$modal-border-color: $platinum;
$modal-z-index: 1050;
$modal-top: 5vh;
$modal-top-sm: 6vh;

.base-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: $modal-z-index;
}

.base-modal__overlay--visible {
  background-color: rgba($black, 0.5);
}

.base-modal {
  position: fixed;
  z-index: $modal-z-index;
  top: $modal-top;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (min-width: $screen-sm-min) {
    top: $modal-top-sm;
  }
}

.base-modal__inner {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-top: 4px solid $persian-green;
  border-radius: 4px;
  width: 90vw;
  max-height: 80vh;
  max-width: 50rem;

  @media screen and (min-width: $screen-sm-min) {
    width: 50rem;
  }
}

.base-modal--size-large {
  .base-modal__inner {
    max-width: 85rem;
    width: 90vw;
    max-height: calc(100vh - #{$modal-top-sm} * 2);

    @media screen and (min-width: $screen-sm-min) {
      width: 70vw;
    }
  }
}

.base-modal--size-medium {
  .base-modal__inner {
    max-width: 70rem;
    width: 60vw;
    max-height: calc(100vh - #{$modal-top-sm} * 2);

    @media screen and (min-width: $screen-sm-min) {
      width: 70vw;
    }
  }
}

.base-modal--expanded {
  top: 0;
  .base-modal__inner {
    max-width: none;
    max-height: none;
    width: calc(100vw - 1rem);
    height: calc(100vh - 1rem);
  }
}

.base-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: $modal-vertical-padding $modal-horizontal-padding;
  border-bottom: 1px solid $modal-border-color;
}

.base-modal__header-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: $teal-green;

  @media screen and (min-width: $screen-sm-min) {
    font-size: 1.8rem;
  }
}

.base-modal__header-controls {
  flex-shrink: 0;
}

.base-modal__body {
  overflow: auto;
}

.base-modal__body--padded {
  padding: $modal-vertical-padding $modal-horizontal-padding;
}

.base-modal__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $modal-border-color;
  padding: $modal-vertical-padding $modal-horizontal-padding;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-down-enter-active {
  transition: margin-top 0.4s ease, opacity 0.2s ease;
}

.fade-down-leave-active {
  transition: margin-top 0.4s ease, opacity 0.2s ease 0.1s;
}

.fade-down-enter-from,
.fade-down-leave-to {
  margin-top: -40px;
  opacity: 0;
}

.animate-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
