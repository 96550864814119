<template>
  <input
    ref="input"
    data-test-id="text-input"
    class="text-input"
    :class="{
      'text-input--rounded': rounded,
      'text-input--flat': flat,
      'text-input--error': error,
      'text-input--disabled': disabled,
    }"
    :disabled="disabled"
    :value="modelValue"
    :placeholder="placeholder"
    @input="$emit('update:model-value', $event.target.value)"
  />
</template>

<script>
export default {
  name: 'TextInput',
  // remove compatConfig when using full vue 3 build
  compatConfig: { MODE: 3 },
  props: {
    disabled: Boolean,
    modelValue: [String, Number],
    placeholder: String,
    rounded: Boolean,
    error: Boolean,
    flat: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.text-input {
  height: 40px;
  line-height: 20px;
  border: 1px $lavendar-gray solid;
  padding: 8px 15px;
  border-radius: 5px;
  max-height: 30vh;
}

.text-input:focus {
  outline: solid 1px var(--button-color);
  box-shadow: 0px 0px 5px 1px var(--button-color);
}

// MODIFIERS
// =====================
.text-input--rounded {
  border-radius: 20px;
}
.text-input--flat {
  border-color: transparent;
}
.text-input--error {
  border-color: $ou-crimson-red;
}
</style>
