/* eslint-disable @typescript-eslint/no-explicit-any */
import { IApiClient } from '@/types/composables/useApiClient';
import Vue from 'vue';

const vueHttpClient: IApiClient = {
  get: (url: string, ...args: any): any => {
    return Vue.prototype.$http.get(url, ...args);
  },
  post: (url: string, params: object, ...args: any): any => {
    return Vue.prototype.$http.post(url, params, ...args);
  },
  put: (url: any, params: any, config: object = {}): any => {
    return Vue.prototype.$http.put(url, params, config);
  },
  patch: (url: string, params: object, ...args: any): any => {
    return Vue.prototype.$http.patch(url, params, ...args);
  },
  delete: (url: string, ...args: any): any => {
    return Vue.prototype.$http.delete(url, ...args);
  },
};

const fetchClient: IApiClient = {
  get: (url: string, ...args: any): any => {
    return fetch(url, ...args);
  },
  post: (url: string, params: object, ...args: any): any => {
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      ...args,
    });
  },
  put: (url: any, file: any, config: object = {}): any => {
    return fetch(url, {
      method: 'PUT',
      body: file,
      ...config,
    });
  },
  patch: (url: string, params: object, ...args: any): any => {
    return fetch(url, {
      method: 'PATCH',
      body: JSON.stringify(params),
      ...args,
    });
  },
  delete: (url: string, ...args: any): any => {
    return fetch(url, {
      method: 'DELETE',
      ...args,
    });
  },
};

export function useApiClient(useFetch = false) {
  const client = useFetch ? fetchClient : vueHttpClient;
  return {
    apiClient: client,
  };
}
