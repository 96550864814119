<script lang="ts">
export default {
  name: 'TrueFalseQuestion',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps } from 'vue';
import { useI18n } from '@/composables/useI18n';
import IQuizQuestion, { QuestionType } from '@/types/QuizQuestion';
defineProps<{ question: IQuizQuestion }>();
const { t } = useI18n();
</script>

<template>
  <div class="true-false-question">
    <img
      v-if="question.image"
      :src="question.image"
      alt=""
      class="quiz-question__image"
      data-test-id="quiz-question-image"
    />
    <div
      class="quiz-question__sentence"
      data-test-id="quiz-question-sentence"
      v-html="question.sentence"
    ></div>
    <div class="quiz-question__answer-group">
      <button
        v-for="answer in question.answers"
        :key="answer.value?.toString()"
        class="quiz-question__answer-option"
        data-test-id="quiz-question-answer-option"
        @click="(e) => e.preventDefault()"
      >
        <span v-if="answer.value">
          {{
            question.question_type === QuestionType.TRUE_FALSE
              ? t('vue_templates.quiz_questions.true_false_question.true')
              : t('vue_templates.quiz_questions.yes_no_question.true')
          }}
        </span>
        <span v-else>
          {{
            question.question_type === QuestionType.TRUE_FALSE
              ? t('vue_templates.quiz_questions.true_false_question.false')
              : t('vue_templates.quiz_questions.yes_no_question.false')
          }}
        </span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/_quiz-question.scss';
</style>
