<template>
  <BaseButton
    class="tab-group-button"
    :class="{
      'tab-group-button--active': active,
      'tab-group-button--no-animate': !animate,
    }"
    role="tab"
    type="button"
    size="large"
    :rounded="false"
    variant="text"
    :aria-controls="panelId"
    :aria-selected="active"
    @click="onTabClick"
  >
    <slot>{{ text }}</slot>
  </BaseButton>
</template>

<script lang="ts">
import BaseButton from '@/components/atoms/BaseButton/Index.vue';

export default {
  name: 'TabGroupButton',
  components: { BaseButton },
  props: {
    text: String,
    panelId: String,
    active: Boolean,
    animate: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onTabClick() {
      this.$emit('click', this.text);
    },
  },
};
</script>

<style lang="scss" scoped>
$border-bottom-width: 2px;

.tab-group-button.tab-group-button {
  box-shadow: inset 0 -#{$border-bottom-width} $pastel-blue;

  & ::v-deep .base-button__inner {
    color: $charcoal;
    height: 100%;

    @media screen and (max-width: $screen-xs-max) {
      padding: 12px;
    }

    &:before {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

// MODIFIERS
// ==============================

.tab-group-button.tab-group-button--no-animate {
  &.tab-group-button--active {
    box-shadow: inset 0 -#{$border-bottom-width} var(--button-color);

    & ::v-deep .base-button__inner {
      color: var(--button-color);
    }
  }
}

.tab-group-button--active ::v-deep .base-button__inner {
  color: var(--button-color);
}
</style>
