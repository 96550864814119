import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { debounce } from 'lodash';

const xs = 320;
const sm = 768;
const md = 992;
const lg = 1200;

export function useBreakpoints() {
  const breakpoints = ref({
    xs: {
      min: xs,
      max: sm - 1,
    },
    sm: {
      min: sm,
      max: md - 1,
    },
    md: {
      min: md,
      max: lg - 1,
    },
    lg: {
      min: lg,
      max: null,
    },
  });

  const screenWidth = ref(0);

  const breakpoint = computed(() => {
    const bp = Object.entries(breakpoints.value).find(([, { min, max }]) => {
      if (screenWidth.value < min) {
        return false;
      }
      if (max && screenWidth.value > max) {
        return false;
      }
      return true;
    });
    return (bp && bp[0]) || 'xs';
  });

  const smAndUp = computed(() => ['sm', 'md', 'lg'].includes(breakpoint.value));
  const mdAndUp = computed(() => ['md', 'lg'].includes(breakpoint.value));
  const xsOnly = computed(() => breakpoint.value === 'xs');
  const smOnly = computed(() => breakpoint.value === 'sm');
  const mdOnly = computed(() => breakpoint.value === 'md');
  const lgOnly = computed(() => breakpoint.value === 'lg');

  const getScreenWidth = () => {
    screenWidth.value = document?.body?.clientWidth;
  };

  const debouncedGetScreenWidth = debounce(getScreenWidth, 50);

  onMounted(() => {
    getScreenWidth();
    window.addEventListener('resize', debouncedGetScreenWidth);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', debouncedGetScreenWidth);
  });

  return {
    breakpoint,
    smAndUp,
    mdAndUp,
    xsOnly,
    smOnly,
    mdOnly,
    lgOnly,
  };
}
