<script lang="ts">
export default {
  name: 'SurveyQuestion',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import { SurveyQuestion, SurveyAnswer, SurveyCheckboxChoiceIds } from '@/types/Survey';
import InputHint from '@/components/atoms/forms/InputHint/Index.vue';

const emit = defineEmits<{
  (e: 'update:model-value', value: SurveyAnswer<SurveyCheckboxChoiceIds>): void;
}>();
const props = defineProps<{
  modelValue: SurveyAnswer<SurveyCheckboxChoiceIds>;
  question: SurveyQuestion;
  inputId: string;
  error?: string;
}>();
const inputName = `survey-question-radio-${props.question.id}`;

const handleChange = (event: Event) => {
  if (event.target) {
    const choiceId = Number((event.target as HTMLInputElement).value);
    let choiceIds = props.modelValue?.choice_ids || [];

    if ((event.target as HTMLInputElement).checked) {
      choiceIds.push(choiceId);
    } else {
      choiceIds = choiceIds.filter((id) => {
        return id !== choiceId;
      });
    }

    const payload = {
      ...props.modelValue,
      choice_ids: choiceIds,
    };

    emit('update:model-value', payload);
  }
};
</script>

<template>
  <div>
    <div
      v-for="choice in question.choices_attributes"
      :key="choice.id"
      class="survey-question-checkbox-input"
    >
      <input
        :id="String(choice.id)"
        type="checkbox"
        :name="inputName"
        :value="choice.id"
        :checked="modelValue?.choice_ids?.includes(choice.id)"
        @change="handleChange"
      />
      <label :for="String(choice.id)">{{ choice.sentence }}</label>
    </div>
    <InputHint v-if="error" :value="error" :error="!!error" class="m-t-1" />
  </div>
</template>

<style lang="scss" scoped>
.survey-question-checkbox-input {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  input[type='checkbox'] {
    margin-top: 0;
    cursor: pointer;
  }

  label {
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 0;
    padding-left: 0.8rem;
  }
}
</style>
