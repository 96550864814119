<template>
  <Teleport to="body">
    <div
      :id="id"
      class="modal fade"
      :class="{
        'modal--centred': isCentred === true,
      }"
      tabindex="-1"
      role="dialog"
      :aria-labelledby="labelName"
      aria-hidden="true"
      :data-backdrop="persistent ? 'static' : undefined"
      :data-keyboard="persistent ? 'false' : undefined"
    >
      <div :class="{ 'modal-lg': large }" class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 :id="labelName" class="modal-title">{{ header }}</h4>
            <Icon
              :data-test-id="`${id}-modal-close-icon`"
              :class="{ 'close--disabled': disableAllButtons }"
              icon-name="error-cross"
              class="close pull-right"
              data-dismiss="modal"
              @click="close"
            />
          </div>
          <div class="modal-body">
            <slot />
          </div>
          <div
            v-if="primaryButtonText || backText"
            :data-test-id="`${id}-modal-footer`"
            :class="{
              'modal-footer': footerBorder,
              'modal-footer--no-border': !footerBorder,
            }"
          >
            <slot name="footerContent" />
            <button
              :data-test-id="`${id}-modal-back-action`"
              :disabled="disableAllButtons"
              type="button"
              class="btn btn--cancel pull-left"
              data-dismiss="modal"
              @click.prevent="close"
            >
              {{ backText }}
            </button>
            <button
              :data-test-id="`${id}-modal-primary-action`"
              :disabled="disableAllButtons || disablePrimaryButton"
              type="button"
              class="btn btn-primary"
              @click.prevent="primaryButtonAction"
            >
              {{ primaryButtonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script>
// DEPRECATED COMPONENT.
// This component is deprecated and should not be used in new code.
// It is only kept for backwards compatibility with existing code.
// Please use the new BaseModal component instead.
import Icon from '@/components/atoms/Icon/Index.vue';

export default {
  name: 'DialogModal',

  components: {
    Icon,
  },

  props: {
    id: String,
    header: String,
    backText: String,
    primaryButtonText: String,
    primaryButtonAction: Function,
    isCentred: Boolean,
    onClose: Function,
    onOpen: Function,
    large: Boolean,
    disableAllButtons: Boolean,
    disablePrimaryButton: Boolean,
    persistent: Boolean,
    footerBorder: {
      // The standard Learn Amp style modal removes the default bootstrap footer
      type: Boolean, // border. In some cases however we do need to restore the border.
      default: false,
    },
  },

  computed: {
    labelName: function () {
      return `${this.id}-label`;
    },
  },

  methods: {
    open: function () {
      $(`#${this.id}`).modal('show');

      if (this.onOpen) {
        this.onOpen();
      }
    },
    close: function () {
      if (!this.disableAllButtons) {
        $(`#${this.id}`).modal('hide');

        if (this.onClose) {
          this.onClose();
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal-open .modal--centred {
  display: flex !important;
  height: 100%;
  align-items: center;
}

.modal {
  z-index: 9000;
}

.modal-title {
  display: inline-block;
}

.modal--quickadd {
  z-index: 100001;
}

.modal--delete {
  z-index: 100002;
}

.modal-body {
  img {
    height: auto;
    max-width: 100%;
  }

  h5 {
    font-size: 18px;
  }
}

.modal-body--underlined {
  border-bottom: 1px solid $platinum;
}

.modal-body--section:last-child {
  border-bottom: none !important;
  padding-bottom: 30px !important;
}

.modal-header {
  background-color: $white;
  border-radius: 4px 4px 0 0;
  border-top: 4px solid $persian-green;
  color: $teal-green;
  padding: 20px 30px;

  h4 {
    font-size: 22px;
    width: 96%;
  }

  .avatar {
    border-radius: 25px;
    width: 40px;
  }

  .close {
    font-size: 16px;
    margin-top: 2px;
    color: $persian-green;
    opacity: 1;

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &:hover {
      color: darken($persian-green, 10);
    }
  }
}

.modal-body {
  padding: 20px 30px;

  &.with-top-border {
    border-top: 4px solid $persian-green;
  }
}

.modal-footer {
  border-top: inherit; // Use default bootstrap border
  padding: 20px 30px;
}

.modal-footer--no-border {
  text-align: right;
  border-top: 0; // Remove default bootstrap border
  padding: 0 30px 20px;
}

.modal-header--danger {
  background-color: $brand-danger;
  border-radius: 4px 4px 0 0;
  border-top: 0;
  color: $white;
}

.modal--fullscreen {
  bottom: 15px;
  left: 15px;
  right: 15px;
  top: 15px;

  .modal-dialog {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .modal-content {
    height: auto;
  }
}

.modal-dialog--narrow {
  max-width: 500px;

  .modal-body {
    padding: 20px;
  }

  .modal-header {
    padding: 15px 20px;
  }

  .modal-footer {
    padding: 0 20px 20px;
  }
}
</style>
