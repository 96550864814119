<!--DEPRECATED - Do not use this component-->
<template>
  <component
    :is="isAnchor ? 'a' : 'button'"
    :class="{
      'btn-primary': buttonStyle === 'primary',
      'btn-secondary': buttonStyle === 'secondary',
      'btn--cancel': buttonStyle === 'cancel',
      'btn-link': buttonStyle === 'link',
      'btn--fab': buttonStyle === 'fab',
      'btn--icon': buttonStyle === 'icon',
      'btn--size-s': size === 'small',
      'btn--size-lg': size === 'large',
      'btn--disabled': disabled,
    }"
    :disabled="disabled"
    :style="{
      top: `${top}px`,
      right: `${right}px`,
      bottom: `${bottom}px`,
      left: `${left}px`,
    }"
    :type="isAnchor ? null : 'button'"
    class="btn"
    @click="isAnchor ? null : performAction($event)"
  >
    <i v-if="icon" :class="`ic ic-${icon} ic--middle`" />
    <LoadingSpinner v-if="loading" type="xxs" />
    <span v-else-if="buttonStyle !== 'fab'">
      {{ text }}
    </span>
  </component>
</template>

<script>
import LoadingSpinner from '@/components/atoms/LoadingSpinner/Index.vue';

export default {
  name: 'BaseButton',
  components: { LoadingSpinner },
  props: {
    isAnchor: {
      type: Boolean,
      default: false,
    },
    action: Function,
    icon: String,
    text: String,
    loading: Boolean,
    buttonStyle: {
      type: String,
      default: 'primary',
      validator(style) {
        return ['primary', 'secondary', 'cancel', 'link', 'fab', 'icon'].includes(style);
      },
    },
    size: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    top: [String, Number],
    right: [String, Number],
    bottom: [String, Number],
    left: [String, Number],
  },

  methods: {
    performAction: function (event) {
      event.preventDefault();
      this.action();
    },
  },
};
</script>

<style scoped lang="scss">
// Some selectors have been doubled up to ensure they
// override Bootstrap styles that are unintentionally being
// loaded after component styles in storybook.

.btn:focus {
  outline: none;
}
.btn-link,
.btn-link:hover,
.btn-link:active,
.btn-link:visited,
.btn-link:focus {
  outline: none;
  font-size: 16px;
  text-decoration: none;
  color: $dark-midnight-blue;
  padding-left: 0;
}
:not(.btn--cancel):not(.btn-link).btn:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(67, 135, 247, 0.6);
}
.btn--disabled.btn--cancel {
  background-color: unset;
}

.btn.btn--fab {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  overflow: hidden;
  height: 30px;
  width: 30px;
  position: absolute;
  background-color: $white;
  color: $charcoal;
  padding: 0;
  box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.1);
}

.btn.btn--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  background: none;
  border: none;
  padding: 0;
  box-shadow: none;
  border-radius: 999px;
  min-width: 26px;
  min-height: 26px;

  & ::v-deep {
    .ic {
      top: auto;
      height: auto;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.07);
  }
}
</style>
