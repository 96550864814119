export enum TileType {
  CAROUSEL = 'Carousel',
  LEARNLIST = 'Learnlist',
  ITEM = 'Item',
  EVENT = 'Event',
  EVENT_SESSION = 'EventSession',
  SURVEY = 'Survey',
  QUIZ = 'Quiz',
  ONE_TO_ONE = 'OneToOne',
}
