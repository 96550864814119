<template id="announcements-sidebar-template">
  <div
    v-click-away="handleClickAway"
    data-test-id="announcements-sidebar-container"
    class="announcements-sidebar"
    :class="visible ? 'visible' : ''"
  >
    <Tabs v-if="!supportOnly" :full-width="true" :tab-config="tabConfig" />

    <div class="announcements-sidebar__body">
      <div
        v-if="supportOnly || activeTab === 'support'"
        data-test-id="announcements-sidebar-support-tab"
      >
        <SidebarSupport :admin-owner-user="adminOwnerUser">
          <img :src="chatBotUrl" alt="Learn Amp Chatbot" />
        </SidebarSupport>
      </div>

      <div
        v-else-if="activeTab === 'announcements'"
        data-test-id="announcements-sidebar-announcements-tab"
      >
        <SidebarAnnouncements
          :announcements="announcements"
          :announcements-loaded="announcementsLoaded"
        />
      </div>

      <div v-else-if="activeTab === 'webinars'" data-test-id="announcements-sidebar-webinars-tab">
        <SidebarWebinars
          :webinars="webinars"
          :past-webinars-url="pastWebinarsUrl"
          :webinars-loaded="webinarsLoaded"
        />
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';
import Tabs from '@/components/atoms/Tabs';
import SidebarSupport from '@/components/molecules/AnnouncementsSidebar/Support';
import SidebarAnnouncements from '@/components/molecules/AnnouncementsSidebar/Announcements';
import SidebarWebinars from '@/components/molecules/AnnouncementsSidebar/Webinars';

export default {
  name: 'AnnouncementsSidebar',

  components: {
    Tabs,
    SidebarSupport,
    SidebarAnnouncements,
    SidebarWebinars,
  },

  mixins: [i18n, VueClickaway],

  props: {
    supportOnly: Boolean,
    endpoint: String,
    announcementsEndpoint: String,
    webinarsEndpoint: String,
    adminOwnerUser: Boolean,
    chatBotUrl: String,
  },

  data: function () {
    return {
      activeTab: 'announcements',
      visible: false,
      loadedAlready: false,
      announcements: null,
      announcementsLoaded: false,
      webinars: null,
      webinarsLoaded: false,
      newWebinars: false,
      any: false,
      pastWebinarsUrl: false,
      loading: false,
    };
  },

  computed: {
    tabConfig: function () {
      let tabs = [];

      if (!this.supportOnly) {
        tabs.push(
          {
            name: 'support',
            active: this.activeTab === 'support',
            visible: true,
            action: () => this.changeTab('support'),
            label: this.t('vue_templates.announcements_sidebar.support'),
          },
          {
            name: 'announcements',
            active: this.activeTab === 'announcements',
            visible: true,
            action: () => this.changeTab('announcements'),
            label: this.t('vue_templates.announcements_sidebar.whats_new'),
          },
          {
            name: 'webinars',
            active: this.activeTab === 'webinars',
            visible: true,
            action: () => this.changeTab('webinars'),
            label: this.t('vue_templates.announcements_sidebar.webinars'),
          }
        );
      }

      return {
        testIdentifier: 'sidebar-tabs-list',
        tabs,
      };
    },
  },

  mounted: function () {
    let button = document.getElementById('announcements-toggle');
    button.addEventListener('click', this.toggle);
    button.addEventListener('touchstart', this.toggle);
  },

  methods: {
    toggle: _.throttle(
      function () {
        this.visible = !this.visible;
        if (this.loadedAlready === false && !this.supportOnly) {
          this.loadedAlready = true;
          this.getAny();
        }
      },
      100,
      { trailing: false }
    ),
    handleClickAway: function () {
      if (this.visible) this.toggle();
    },
    changeTab: function (tab) {
      this.activeTab = tab;
      if (tab === 'announcements' && !this.announcementsLoaded) {
        this.getAnnouncements();
      }
      if (tab === 'webinars' && !this.webinarsLoaded) {
        this.getWebinars();
      }
    },

    // When the component is first toggled open, all announcements and webinars
    // are fetched using the 'any' endpoint (announcements_any_path in the Ruby
    // code). This data populates all tabs with initial data.
    // Subsequent tab changes will only refresh the data in that tab, e.g. if
    // a user changes to tab 'announcements', only getAnnouncements is called.
    getAny: function () {
      this.loading = true;
      this.$http.get(this.endpoint).then(
        (response) => {
          this.announcements = response.data.announcements;
          this.announcementsLoaded = response.data.announcements_loaded;
          this.webinars = response.data.webinars;
          this.webinarsLoaded = response.data.webinars_loaded;
          if (this.webinarsLoaded) this.activeTab = 'webinars';
          this.anyNew = response.data.any_new;
          this.newWebinars = response.data.new_webinars;
          this.pastWebinarsUrl = response.data.past_webinars_url;
          this.loading = false;
          this.updateNavigationDot();
        },
        () => {
          this.error = true;
          this.loading = false;
        }
      );
    },
    getAnnouncements: function () {
      this.loading = true;
      this.$http.get(this.announcementsEndpoint).then(
        (response) => {
          this.announcements = response.data.announcements;
          this.anyNew = response.data.any_new;
          this.newWebinars = response.data.new_webinars;
          this.pastWebinarsUrl = response.data.past_webinars_url;
          this.announcementsLoaded = true;
          this.loading = false;
          this.updateNavigationDot();
        },
        () => {
          this.error = true;
          this.loading = false;
        }
      );
    },
    getWebinars: function () {
      this.loading = true;
      this.$http.get(this.webinarsEndpoint).then(
        (response) => {
          this.webinars = response.data.webinars;
          this.anyNew = response.data.any_new;
          this.newWebinars = response.data.new_webinars;
          this.pastWebinarsUrl = response.data.past_webinars_url;
          this.webinarsLoaded = true;
          this.loading = false;
          this.updateNavigationDot();
        },
        () => {
          this.error = true;
          this.loading = false;
        }
      );
    },
    updateNavigationDot: function () {
      if (!this.anyNew) {
        document.getElementById('announcements-toggle').classList.remove('unseen');
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
