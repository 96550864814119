<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <textarea
    ref="input"
    data-test-id="text-input-textarea"
    :rows="rows"
    :style="{ height: inputHeight }"
    class="text-input__input"
    :class="{
      'text-input__input--narrow': narrow,
      'text-input__input--rounded': rounded,
      'text-input__input--flat': flat,
      'text-input__input--error': error,
      'text-input__input--disabled': disabled,
    }"
    :disabled="disabled"
    :value="modelValue"
    :placeholder="placeholder"
    @input="$emit('update:modelValue', $event.target.value)"
  >
  </textarea>
</template>

<script>
export default {
  name: 'TextArea',
  compatConfig: { MODE: 3 },
  props: {
    disabled: Boolean,
    modelValue: String,
    placeholder: String,
    rounded: Boolean,
    error: Boolean,
    flat: Boolean,
    narrow: Boolean,
    isFocused: Boolean,
    rows: {
      type: Number,
      default: 1,
    },
    autoResize: {
      type: Boolean,
      default: true,
    },
    focusAnimation: {
      type: Function,
      default: () => Promise.resolve(),
    },
    focusOnRender: Boolean,
  },
  data: function () {
    return {
      inputHeight: '',
    };
  },
  computed: {
    initialHeight() {
      // Line height + padding
      if (this.narrow) {
        return this.rows * 20 + 10;
      }
      return this.rows * 20 + 20;
    },
  },
  watch: {
    modelValue: {
      handler() {
        if (!this.autoResize) return;
        this.resizeInput();
      },
      immediate: true,
    },
    isFocused: {
      async handler(newFocus) {
        if (!this.$refs.input) return;

        if (newFocus) {
          await this.focusAnimation();
          this.$refs.input.focus();
        } else {
          this.$refs.input.blur();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.resizeInput();
    if (this.focusOnRender) {
      this.$el.focus();
    }
  },
  methods: {
    resizeInput() {
      const input = this.$refs.input;
      if (input) {
        this.inputHeight = `${this.initialHeight}px`;
        this.$nextTick(() => {
          const input = this.$refs.input;
          /* istanbul ignore next */
          if (input.scrollHeight > this.initialHeight) {
            this.inputHeight = `${input.scrollHeight + 4}px`;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-input__input {
  height: 40px;
  line-height: 20px;
  border: 1px $lavendar-gray solid;
  padding: 8px 15px;
  resize: none;
  border-radius: 5px;
  max-height: 30vh;
}

.text-input__input:focus {
  outline: solid 1px var(--button-color);
  box-shadow: 0px 0px 5px 1px var(--button-color);
}

// MODIFIERS
// =====================
.text-input__input--narrow {
  padding: 3px 8px;
}
.text-input__input--rounded {
  border-radius: 20px;
}
.text-input__input--flat {
  border-color: transparent;
}
.text-input__input--error {
  border-color: $ou-crimson-red;
  &.text-input__input--error {
    outline-color: $ou-crimson-red;
  }
}
</style>
