<template>
  <tr class="table--empty-row">
    <td class="text-muted text-center" :colspan="columns">
      <div class="table--empty-wrapper">
        <img :src="imagePath" alt="" />
        <div class="m-t-1">
          <strong>{{ emptyText }}</strong>
          <div v-if="canAdd">
            {{ canAddText }}
            <div class="m-t-1">
              <a class="btn btn-primary btn--size-s" :href="canAddUrl">
                {{ canAddButtonText }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'TableEmpty',
  props: {
    columns: Number,
    imagePath: String,
    emptyText: String,
    canAdd: Boolean,
    canAddUrl: String,
    canAddText: String,
    canAddButtonText: String,
  },
};
</script>

<style scoped lang="scss"></style>
