<template>
  <div
    ref="like"
    :data-original-title="tooltipTitle"
    class="like"
    data-toggle="tooltip"
    data-html="true"
    @click="$emit('like-clicked')"
  >
    <Icon
      :icon-name="liked ? 't-like-thumb-up-vote-filled' : 't-like-thumb-up-vote'"
      size="large"
    />
    <span class="liked-count">
      {{ likesCount }}
    </span>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon/Index.vue';
export default {
  name: 'LikeCount',
  components: {
    Icon,
  },
  props: {
    liked: Boolean,
    likedBy: Array,
    likesCount: Number,
  },
  computed: {
    tooltipTitle() {
      if (this.likedBy && this.likedBy.length) {
        return `<p class="tooltip-left-align">${this.likedBy
          .map(([firstname, lastname]) => `${firstname} ${lastname}`)
          .join('<br>')}</p>`;
      } else {
        return '';
      }
    },
  },
  mounted() {
    if (this.$refs.like) {
      // Hacky fix to prevent tooltip breaking because
      // Calling tooltipToggler() mixin will not work.
      $(this.$refs.like).tooltip();
    }
  },
};
</script>

<style lang="scss" scoped>
.like {
  display: inline-block;
  cursor: default;
  user-select: none;
}
.liked-count {
  padding: 2px 4px 2px 0;
}
</style>
