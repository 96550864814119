export default {
  data: function () {
    return {
      linkScraperResponse: {},
      linkScraperLoading: false,
      linkScraperHasError: false,
      scrapeDescription: false,
      linkScraperScrapeId: null,
      $_linkScraper_createScrapeEndpoint: '/scrapes.json',
      $_linkScraper_scrapePollingEndpoint: null,
      $_linkScraper_pollRepeats: 100,
      $_linkScraper_pollRuns: 0,
      $_linkScraper_pollInterval: null,
    };
  },
  methods: {
    createScrape(url) {
      if (this.$data.$_linkScraper_pollInterval) {
        this.$_linkScraper_clearPollInterval();
      }

      this.linkScraperHasError = false;
      this.linkScraperLoading = true;
      this.$http
        .post(this.$data.$_linkScraper_createScrapeEndpoint, {
          scrape: { url, use_pismo: this.scrapeDescription },
        })
        .then(
          (response) => {
            this.linkScraperLoading = false;
            this.$data.$_linkScraper_scrapePollingEndpoint = response.body.show_path;
            this.$_linkScraper_startPolling();
          },
          () => {
            this.$_linkScraper_handleScrapeFail();
          }
        );
    },
    resetScrape() {
      this.$_linkScraper_clearPollInterval();
      this.linkScraperHasError = false;
      this.linkScraperLoading = false;
    },
    $_linkScraper_startPolling() {
      this.$data.$_linkScraper_pollRuns = 0;
      this.linkScraperLoading = true;
      this.$data.$_linkScraper_pollInterval = setInterval(this.$_linkScraper_getScrape, 1000);
    },
    $_linkScraper_getScrape() {
      if (this.$data.$_linkScraper_pollRuns >= this.$data.$_linkScraper_pollRepeats) {
        this.$_linkScraper_handleScrapeFail();
      }

      this.$data.$_linkScraper_pollRuns += 1;
      this.$http.get(this.$data.$_linkScraper_scrapePollingEndpoint).then(
        (response) => {
          if (response.body.status === 'unprocessable') {
            this.$_linkScraper_handleScrapeFail();
          } else if (response.body.status === 'successful') {
            this.$_linkScraper_handleScrapeSuccessful(response.body);
          }
        },
        () => {
          this.$_linkScraper_handleScrapeFail();
        }
      );
    },
    $_linkScraper_handleScrapeFail() {
      this.$_linkScraper_clearPollInterval();
      this.linkScraperHasError = true;
      this.linkScraperLoading = false;
      this.onLinkScraperFail && this.onLinkScraperFail();
    },
    $_linkScraper_handleScrapeSuccessful({
      id,
      url,
      title,
      fullsize_image_path,
      image,
      supplier: { name } = {},
    } = {}) {
      this.$_linkScraper_clearPollInterval();
      this.linkScraperResponse = {
        title,
        image_url: fullsize_image_path,
        image,
        url,
        supplier_name: name,
      };
      this.linkScraperLoading = false;
      this.linkScraperScrapeId = id;
      this.onLinkScraperSuccess && this.onLinkScraperSuccess();
    },
    $_linkScraper_clearPollInterval() {
      if (this.$data.$_linkScraper_pollInterval) {
        clearInterval(this.$data.$_linkScraper_pollInterval);
      }
    },
  },
};
