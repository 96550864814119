<template>
  <div class="dropdown dropdown--pips" :class="{ dropup }">
    <a
      class="dropdown-toggle"
      data-test-id="menu-pips-toggle"
      :class="{
        'dropdown-toggle--muted': type === 'muted',
        'dropdown-toggle--float-none': floatNone,
      }"
      href="#"
      data-toggle="dropdown"
      @click="handleClick"
    >
      <i class="ic ic-ellipsis-h" />
    </a>
    <ul
      ref="dropdownMenu"
      class="dropdown-menu dropdown-menu-right menu-list"
      :class="{
        'menu-list--full-width': fullWidth,
        'dropdown-menu--has-scroll': hasScroll,
        'dropdown-menu--has-scroll-end': scrollNearBottom,
      }"
      @scroll="handleScroll"
    >
      <LoadingSpinner v-if="loading" data-test-id="menu-pips-loading" type="small" />
      <slot v-else />
    </ul>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/atoms/LoadingSpinner/Index.vue';

export default {
  name: 'PipsMenu',

  components: {
    LoadingSpinner,
  },
  props: {
    dropup: {
      type: Boolean,
      default: false,
    },
    fetchUrl: String,
    type: {
      type: String,
      validator: (propValue) => {
        const types = ['muted'];
        return types.find((type) => type === propValue);
      },
    },
    fullWidth: Boolean,
    floatNone: Boolean,
    loading: Boolean,
  },

  data: function () {
    return {
      hasScroll: false,
      scrollNearBottom: false,
    };
  },

  watch: {
    loading: function (newLoading) {
      if (newLoading === false) {
        this.setHasScroll();
      }
    },
  },

  methods: {
    handleClick: function () {
      if (!this.fetchUrl) {
        return;
      }

      if (this.fetchUrl) {
        this.$emit('fetch-menu-items', this.fetchUrl);
      }

      this.setHasScroll();
    },

    handleScroll: function () {
      const dropdown = this.$refs.dropdownMenu;
      this.scrollNearBottom =
        dropdown.scrollTop + dropdown.offsetHeight - dropdown.scrollHeight > -5;
    },

    setHasScroll: function () {
      const dropdown = this.$refs.dropdownMenu;
      setTimeout(() => {
        this.hasScroll = dropdown.scrollHeight > dropdown.clientHeight;
      }, 0);
    },
  },
};
</script>

<style scoped lang="scss">
.dropdown--pips {
  z-index: 10;
}

.dropdown-toggle {
  width: auto;
  height: auto;
  float: right;
  margin-bottom: -10px;
  margin-top: -4px;
  line-height: 20px;
  text-align: right;
  font-size: 26px;
  color: $menu-pip-grey;
  text-shadow: none;

  &:hover {
    text-decoration: none;
    color: $mid-gray;
  }

  &--muted {
    color: $mid-gray;
    font-size: 20px;
  }
}

.dropdown-toggle--float-none {
  float: none;

  .ic {
    vertical-align: middle;
  }
}

.menu-list {
  min-width: 94px;
  margin-top: 10px;
  max-height: 280px;
}

.menu-list--full-width {
  right: -15px;
  max-width: 245px;
  background: $white;
  overflow: auto;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-menu--has-scroll {
  box-shadow: inset 0 -20px 10px -7px $dark-gray;
  transition: box-shadow 300ms ease;
}

.dropdown-menu--has-scroll-end {
  box-shadow: none;
}
</style>
