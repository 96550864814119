<template>
  <div
    :class="{ [`search-input__container--${type}`]: type, 'full-width': fullWidth }"
    class="search-input__container"
  >
    <span ref="icon" role="presentation" class="search-input__icon"></span>
    <input
      ref="input"
      :value="modelValue"
      :placeholder="placeholder"
      :aria-label="srLabel || placeholder"
      :class="{
        'search-input__input--rounded': rounded,
        'search-input__input--bordered': bordered,
        [`search-input__input--${type}`]: type,
      }"
      class="form-control search-input__input"
      type="text"
      data-test-id="search-input"
      @keyup.enter="(e) => $emit('enter', e.target.value)"
      @keyup.escape="$emit('escape')"
      @focus="(e) => $emit('focus', e)"
      @blur="(e) => $emit('blur', e)"
      @input="(e) => $emit('update:modelValue', e.target.value)"
    />
    <button
      v-if="modelValue?.length > 0"
      ref="clear-search"
      class="btn btn--cancel"
      tabindex="0"
      :aria-label="t('helpers.buttons.clear_search')"
      @click.prevent.stop="clearSearch"
      @keyup.enter="clearSearch"
    >
      <Icon icon-name="error-cross" :align-middle="true" size="medium"></Icon>
    </button>
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';
import Icon from '@/components/atoms/Icon/Index.vue';

export default {
  name: 'SearchInput',
  compatConfig: { MODE: 3 },
  components: {
    Icon,
  },
  mixins: [i18n],
  props: {
    type: {
      type: String,
      validator: (type) => {
        return ['discover-content', 'slim'].includes(type);
      },
    },
    placeholder: String,
    focusHandler: Function,
    blurHandler: Function,
    rounded: Boolean,
    bordered: {
      type: Boolean,
      default: true,
    },
    modelValue: String,
    srLabel: String,
    fullWidth: Boolean,
  },
  methods: {
    clearSearch() {
      this.$emit('update:modelValue', '');
      this.$emit('clear');
      this.$refs.input.blur();
    },
  },
};
</script>

<style scoped lang="scss">
.search-input__container {
  position: relative;
  max-width: 500px;
  min-width: 200px;
  &.full-width {
    max-width: 100%;
  }

  &--type-discover-content {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-shrink: 0;
    height: 30px;
  }

  &--dropdown {
    .search-input__icon:before {
      margin: 4px 0 0 8px;
      line-height: 36px;
    }

    button {
      padding: 10px;
    }
    .search-input__input {
      padding-left: 30px;
    }
  }

  .dropdown-menu & {
    position: sticky;
    top: 0;
    z-index: 1;
    margin: 8px 5px;
  }
}

.search-input__input {
  pointer-events: all;
  padding: 15px 20px 15px 45px;
  height: 42px;
  border-radius: 10px;
  padding: 12px 15px 12px 35px;
  width: 100%;
  @include form-control-focus($input-border-focus);
  box-shadow: none;
  border: none;

  :disabled {
    color: $text-disabled;
  }

  &--rounded {
    border-radius: 200px;
  }

  &--bordered {
    border: 1px solid $mid-gray;
  }

  &--slim {
    height: 35px;
    padding: 10px 15px 10px 35px;
  }
}

.search-input__icon {
  top: 0;
  left: 0;

  &:after {
    content: none;
  }

  &:before {
    pointer-events: none;
    cursor: none;
    position: absolute;
    font-family: 'ic';
    font-size: 16px;
    content: $ic-search;
    margin: 10px 0 0 12px;

    .search-input__container--slim & {
      margin: 6px 0 0 12px;
    }
  }

  &--search {
    color: $gray-light;
  }

  &--clear {
    &:before {
      pointer-events: all;
      cursor: pointer;
      content: $ic-error-cross;
    }

    &:after {
      content: none;
    }
  }
}

button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px 20px;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
</style>
