<script lang="ts">
export default {
  name: 'SurveyQuestion',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import { SurveyQuestion, SurveyAnswer } from '@/types/Survey';
import RadioInputs from '@/components/atoms/forms/RadioInputs/Index.vue';
import InputHint from '@/components/atoms/forms/InputHint/Index.vue';
import sanitize from 'sanitize-html';

const emit = defineEmits<{
  (e: 'update:model-value', value: SurveyAnswer): void;
}>();
const props = defineProps<{
  modelValue: SurveyAnswer;
  question: SurveyQuestion;
  inputId: string;
  error?: string;
}>();
const inputName = `survey-question-radio-${props.question.id}`;

const handleInput = (choiceId: string) => {
  const payload = {
    ...props.modelValue,
    choice_id: Number(choiceId),
  };
  emit('update:model-value', payload);
};
</script>

<template>
  <RadioInputs
    v-bind="$attrs"
    :id="inputId"
    :options="
      question.choices_attributes.map(({ id, sentence }) => ({
        label: sanitize(sentence, { allowedTags: [], allowedAttributes: {} }),
        id,
        value: id,
        name: inputName,
      }))
    "
    class="survey-question-radio-input"
    :model-value="modelValue?.choice_id"
    @update:modelValue="handleInput"
  />
  <InputHint v-if="error" :value="error" :error="!!error" class="m-t-1" />
</template>

<style lang="scss" scoped>
.survey-question-radio-input {
  color: inherit;
}
</style>
