<template>
  <main :class="{ tile__in_widget: showingWidget, ...responsiveClasses }">
    <div v-if="progress && type !== TileType.CAROUSEL" class="tile__progress">
      <div class="progress">
        <div
          class="progress-bar"
          role="progressbar"
          :aria-valuenow="progress"
          aria-valuemin="0"
          aria-valuemax="100"
          :class="[progress == 0 ? 'progress-disabled' : 'progress-bar-success']"
          :style="{ width: progress + '%' }"
        />
      </div>
    </div>
    <div class="tile__title-container">
      <div :class="useMobileFirst('tile__title')">
        {{ name }}
      </div>
      <div v-if="subHeading" class="tile__title--sub-heading">
        {{ subHeading }}
      </div>
    </div>
    <!-- Slot for tile properties -->
    <slot v-if="mdAndGreater" />
    <div v-if="type === TileType.ONE_TO_ONE && mdAndGreater">
      <div v-if="deadline" class="tile__details m-t-2">
        <icon icon-name="t-users-man-woman" />
        <img v-if="user && user.avatar" alt="" class="avatar" :src="user.avatar" />
        <img v-if="assessor && assessor.avatar" alt="" class="avatar" :src="assessor.avatar" />
      </div>
    </div>
    <!-- TODO: https://learnamp.atlassian.net/browse/LA-23815 -->
    <div
      v-if="type !== TileType.EVENT && description && mdAndGreater"
      class="tile__description"
      v-html="sanitizedDescription"
    ></div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from '@/composables/useI18n';
import { useMobileFirst, mdAndGreater } from '@/composables/useMobileFirst';
import pluralizeWord from '@/helpers/pluralize-word';
import sanitizeHTML from 'sanitize-html';
import { TileType } from '@/types/Tiles';

export default defineComponent({
  name: 'TileBody',
  compatConfig: { MODE: 3 },
  props: {
    progress: Number,
    name: String,
    subHeading: String,
    type: String,
    typeLabel: String,
    learnlistItemCount: Number,
    questionsCount: Number,
    description: String,
    showingWidget: Boolean,
    totalTime: String,
    time: String,
    user: Object,
    assessor: Object,
    deadline: String,
    location: String,
    dates: String,
    eventSessionsCount: Number,
  },
  setup(props) {
    const { t } = useI18n();
    const responsiveClasses = useMobileFirst('tile__body');
    const itemCountPluralize = (itemCount) => {
      return pluralizeWord(
        t('activerecord.models.question.one'),
        t('activerecord.models.question.other'),
        itemCount
      );
    };

    const sanitizedDescription = sanitizeHTML(props.description || '');

    return {
      itemCountPluralize,
      mdAndGreater,
      responsiveClasses,
      useMobileFirst,
      sanitizedDescription,
      TileType,
    };
  },
});
</script>

<style scoped lang="scss">
@import 'styles.scss';

.tile__title-container {
  margin-bottom: 6px;
}

.tile__title {
  word-break: break-word;
  -webkit-line-clamp: 3;
  max-height: 68px;
  &--sub-heading {
    color: $dark-electric-blue;
    font-size: 12px;
    font-weight: normal;
  }
}

.tile__title--md-and-greater {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  font-size: 16px;
  font-weight: bold;
  max-height: 46px;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tile__details {
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: 1.65em;
  padding-left: 1px;
  display: -webkit-box;

  .ic {
    margin-right: 5px;
  }
}

.tile__body {
  height: $body-height;
  padding: 16px 8px 5px 8px;
  font-size: $description-font-size;
}

.tile__body--md-and-greater {
  padding: $inner-padding;
  font-size: $description-font-size-desktop;
}

.tile__description {
  margin-top: 10px;
  font-size: $description-font-size;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-height: 4.2em;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tile__description--md-and-greater {
  font-size: $description-font-size-desktop;
}

.avatar {
  width: 22px;
  height: 22px;
  display: inline-block;
  border-radius: 22px;
  margin-right: -7px;
}

.progress {
  margin: 0.5em 0em;
  height: 12px;
  border-radius: 3px;
}
</style>
