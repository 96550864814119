<script lang="ts">
export default {
  name: 'SurveyPanelQuestions',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import _ from 'lodash';
import { defineEmits, defineProps, ref, Ref, computed, inject } from 'vue';
import { Survey, SurveyAnswersFrom, SurveyAttempt, SurveySaveResponse } from '@/types/Survey';
import { useI18n } from '@/composables/useI18n';
import { useNotification } from '@kyvg/vue3-notification';
import { useApiClient } from '@/composables/useApiClient';
import BaseButton from '@/components/atoms/BaseButton/Index.vue';
import SurveyQuestion from '@/components/molecules/surveys/SurveyQuestion/Index.vue';

interface AnswerError {
  error: string;
  questionId: number;
}

const emit = defineEmits<{
  (e: 'error'): void;
  (e: 'updated', attempt: SurveyAttempt): void;
}>();

// Although surveyFor seems like a duplicate property for survey.answers_from, it is
// modified in the parent if this component is used in the TabGroup in SurveyPanel.
const props = defineProps<{
  survey: Survey;
  surveyFor: SurveyAnswersFrom;
  initAttempt: SurveyAttempt;
  oneToOneName: string;
}>();

const { t } = useI18n();
const { notify } = useNotification();
const { apiClient } = useApiClient();

const isLoading = ref(false);
const attempt = ref(_.cloneDeep(props.initAttempt));
const updatedAttempt = ref(_.cloneDeep(props.initAttempt));
const showingQuestionIndex = ref(0);
const isEditing = ref(!attempt.value?.submitted_at);
const answerErrors: Ref<AnswerError[]> = ref([]);

const submitButtonText = computed(() => {
  if (attempt.value?.completed) {
    return t('vue_templates.components.one_to_one.survey_panel.update');
  }
  return t('vue_templates.components.one_to_one.survey_panel.finish');
});

const waitingOnAnswersText = computed(() => {
  switch (props.survey.answers_from) {
    case SurveyAnswersFrom.REVIEWEE:
      return t('vue_templates.one_to_one.no_attempts_reviewee', {
        one_to_one_name: props.oneToOneName,
      });
    case SurveyAnswersFrom.REVIEWER:
      return t('vue_templates.one_to_one.no_attempts_reviewer', {
        one_to_one_name: props.oneToOneName,
      });
    default:
      return t('vue_templates.one_to_one.no_attempts');
  }
});

const onDiscardClick = () => {
  updatedAttempt.value = _.cloneDeep(attempt.value);
  answerErrors.value = [];

  if (attempt.value?.completed) {
    isEditing.value = false;
  }
};

const saveSurvey = async (saveAsDraft) => {
  const endpoint = attempt.value.save_path;

  if (!endpoint) return;

  isLoading.value = true;
  const params = _.omitBy(
    {
      surveys_attempt: {
        answers_attributes: updatedAttempt.value?.answers.reduce(
          (acc, { question_id, answer, opinion_scale_score, choice_id, choice_ids }, index) => {
            return Object.assign({}, acc, {
              [index]: {
                question_id: question_id || attempt.value.questions[index].id,
                answer,
                opinion_scale_score,
                choice_id,
                choice_ids,
              },
            });
          },
          {}
        ),
      },
      save_draft: saveAsDraft,
    },
    _.isUndefined
  );

  try {
    const response: SurveySaveResponse = await apiClient.put(endpoint, params);
    if (response.ok && !response.data.errors) {
      attempt.value = _.cloneDeep(response.data);
      updatedAttempt.value = _.cloneDeep(response.data);
      answerErrors.value = [];
      emit('updated', attempt.value);

      if (response.data.completed) {
        isEditing.value = false;
        notify({
          type: 'success',
          title: t('vue_templates.components.one_to_one.survey_panel.answers_submitted'),
        });
      } else {
        notify({
          type: 'success',
          title: t('vue_templates.components.one_to_one.survey_panel.answers_saved_as_draft'),
        });
      }
    } else {
      if (response.data.errors) {
        answerErrors.value = response.data.errors.map((item) => ({
          error: item.errors.text.join(', '),
          questionId: item.question_id,
        }));
      }

      emit('error');
      notify({
        type: 'error',
        title: t('vue_templates.components.one_to_one.survey_panel.unable_to_save'),
      });
    }
  } catch (error: unknown) {
    const errorMessage = typeof error === 'string' ? error : (error as Error)?.message;

    notify({
      type: 'error',
      title: t('vue_templates.components.one_to_one.survey_panel.unable_to_save'),
      text: errorMessage,
    });
  }
  isLoading.value = false;
};

const useMultiplePages = computed(() => {
  return !props.survey.single_page;
});

const showSurveyQuestion = (index: number): boolean => {
  if (!useMultiplePages.value) return true;

  return showingQuestionIndex.value === index;
};

const showWaitingForResponseText = computed(() => {
  return !attempt.value?.can_answer && !attempt.value?.answers?.length;
});

const onBeforeUnload = (event) => {
  if (isEditing.value && !_.isEqual(attempt.value, updatedAttempt.value)) {
    event.preventDefault();
    event.returnValue = t('vue_templates.components.one_to_one.survey_panel.unsaved_changes');
  }
};

window.addEventListener('beforeunload', onBeforeUnload);

const oneToOneAttemptProps = inject('oneToOneAttemptProps', {});
</script>

<template>
  <div class="survey-panel-questions">
    <template v-if="showWaitingForResponseText">
      <div data-test-id="survey-panel-questions-waiting-answers-text">
        {{ waitingOnAnswersText }}
      </div>
    </template>
    <template v-else-if="attempt && !isEditing">
      <one-to-one-survey-attempt
        v-if="attempt"
        v-bind="oneToOneAttemptProps"
        :display-question="survey.display_question"
        :attempt="attempt"
        emit-edit
        @edit="isEditing = true"
      />
    </template>
    <template v-else>
      <div class="survey-panel-questions__dropdown-menu">
        <survey-dropdown
          v-if="survey.dropdown_menu"
          :id="survey.dropdown_menu.id"
          :user-endpoint="survey.dropdown_menu.user_endpoint"
          :carousel-notice-displayed="survey.dropdown_menu.carousel_notice_displayed"
          :learnlist-notice-displayed="survey.dropdown_menu.learnlist_notice_displayed"
          :modal-copy="survey.dropdown_menu.modal_copy"
          :actions-endpoint="survey.dropdown_menu.actions_endpoint"
          right
        />
      </div>
      <form>
        <template v-for="(question, index) in attempt.questions" :key="question.id">
          <SurveyQuestion
            v-show="showSurveyQuestion(index)"
            v-model="updatedAttempt.answers[index]"
            :question="question"
            :show-question-number="survey.display_question"
            :disabled="isLoading"
            :error="answerErrors.find((item) => item.questionId === question.id)?.error"
            class="m-b-3"
          />
        </template>
      </form>
      <div class="survey-panel-questions__edit-actions">
        <BaseButton
          variant="plain"
          theme="error"
          :text="t('vue_templates.components.one_to_one.survey_panel.discard_changes')"
          data-test-id="survey-panel-questions-discard-changes-button"
          :disabled="isLoading"
          @click="onDiscardClick"
        />
        <div class="survey-panel-questions__spacer" />
        <template v-if="useMultiplePages">
          <BaseButton
            variant="icon"
            :title="t('vue_templates.components.one_to_one.survey_panel.previous_question')"
            data-test-id="survey-panel-questions-page-controls-back"
            :disabled="isLoading || showingQuestionIndex === 0"
            prepend-icon="chevron-left"
            size="large"
            theme="info"
            class="m-r-1"
            @click="showingQuestionIndex -= 1"
          />
          <BaseButton
            variant="icon"
            :title="t('vue_templates.components.one_to_one.survey_panel.next_question')"
            data-test-id="survey-panel-questions-page-controls-next"
            :disabled="isLoading || showingQuestionIndex === attempt.questions.length - 1"
            prepend-icon="chevron-right"
            size="large"
            theme="info"
            @click="showingQuestionIndex += 1"
          />
        </template>
        <div class="survey-panel-questions__save-actions">
          <BaseButton
            v-if="!attempt.completed"
            variant="outlined"
            :text="t('vue_templates.components.one_to_one.survey_panel.save_draft')"
            data-test-id="survey-panel-questions-save-draft-button"
            :disabled="isLoading"
            class="survey-panel-questions__draft-button"
            @click="saveSurvey(true)"
          />
          <div class="survey-panel-questions__spacer" />
          <BaseButton
            v-if="
              !useMultiplePages ||
              attempt.completed ||
              showingQuestionIndex === attempt.questions.length - 1
            "
            :text="submitButtonText"
            data-test-id="survey-panel-questions-finish-button"
            class="m-l-3"
            :disabled="isLoading"
            @click="saveSurvey(undefined)"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.survey-panel-questions__dropdown-menu {
  position: absolute;
  right: 1rem;
  top: 0;
}
.survey-panel-questions__name {
  font-size: 1.8rem;
  font-weight: 500;
}
.survey-panel-questions__content {
  position: relative;
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
}

.survey-panel-questions__edit-actions {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
}

.survey-panel-questions__save-actions {
  display: flex;
  flex-basis: 100%;
  margin-top: 2rem;
  justify-content: space-between;

  @media screen and (min-width: $screen-sm-min) {
    flex-basis: auto;
    margin-top: 0;
    justify-content: flex-end;
  }
}

.survey-panel-questions__draft-button {
  @media screen and (min-width: $screen-sm-min) {
    margin-left: 4rem;
  }
}

.survey-panel-questions__spacer {
  flex-grow: 1;
}
</style>
