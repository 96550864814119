<template>
  <TableFilterWrapper>
    <DropdownList :label="label" :selection-handler="updateModel" :items="items" />
  </TableFilterWrapper>
</template>

<script>
import DropdownList from '@/components/atoms/DropdownList/Index.vue';
import TableFilterWrapper from './TableFilterWrapper.vue';
import i18n from '@/mixins/i18n';

export default {
  components: {
    TableFilterWrapper,
    DropdownList,
  },
  mixins: [i18n],

  props: {
    items: Array,
    label: String,
  },

  data: function () {
    return {
      expandFilters: false,
    };
  },

  methods: {
    updateModel: function (value) {
      this.$emit('input', value);
    },
  },
};
</script>
