<template>
  <div class="select-input">
    <BaseIcon icon-name="caret-down" size="large" class="select-input__icon" />
    <select
      ref="select"
      class="select-input__select"
      @change="$emit('change', $event.target.value)"
    >
      <option
        v-if="placeholder"
        :value="undefined"
        :disabled="true"
        :selected="value === undefined || value === null"
      >
        {{ placeholder }}
      </option>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :selected="option.value === value"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
import BaseIcon from '@/components/atoms/Icon/Index.vue';

export default {
  name: 'SelectInput',
  components: { BaseIcon },
  props: {
    options: Array,
    placeholder: String,
    initialValue: [String, Number],
    value: [String, Number],
  },
};
</script>

<style lang="scss" scoped>
.select-input {
  position: relative;

  .select-input__icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    z-index: 2;
    pointer-events: none;
    height: auto;
  }
}
.select-input__select {
  padding: 10px 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 4px;
  border-color: $selectize-border-color;
  cursor: pointer;
  color: #333333;
  font-family: inherit;
  font-size: inherit;
  line-height: 20px;
  -webkit-appearance: none;
  z-index: 1;

  &:focus {
    outline-color: var(--button-color);
  }
}
</style>
