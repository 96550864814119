<template>
  <div data-test-id="player-resumer" class="player-resumer">
    <div
      v-if="showResumePrompt"
      data-test-id="player-resumer-prompt"
      :class="{ 'player-resumer__prompt--low': low }"
      class="player-resumer__prompt"
    >
      <div class="m-y-2">
        {{ t('vue_templates.components.player_embed.resume_where_left_off') }}
      </div>
      <a
        class="link-teal strong p-a-2"
        data-test-id="prompt-button-yes"
        href="#"
        @click.prevent="handleResumePrevious"
      >
        {{ t('vue_templates.components.player_embed.yes') }}
      </a>
      <a
        class="link-teal strong p-a-2"
        href="#"
        data-test-id="prompt-button-no"
        @click.prevent="handleResumeStart"
      >
        {{ t('vue_templates.components.player_embed.no') }}
      </a>
    </div>
    <slot />
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';

export default {
  name: 'PlayerResumer',
  mixins: [i18n],
  props: {
    low: Boolean,
    showResumePrompt: Boolean,
  },
  methods: {
    handleResumeStart() {
      this.$emit('resume');
    },
    handleResumePrevious() {
      this.$emit('resume', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.player-resumer {
  position: relative;

  &__prompt {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 150px;
    padding-top: 18%;
    z-index: 1000;
    font-size: 20px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;

    &--low {
      padding-top: 20px;
    }
  }
}
</style>
