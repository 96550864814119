<script lang="ts">
export default {
  name: 'SurveyQuestion',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import { SurveyQuestion, SurveyAnswer } from '@/types/Survey';
import { useI18n } from '@/composables/useI18n';
import TextArea from '@/components/atoms/forms/TextArea/Index.vue';
import TransitionSlide from '@/components/atoms/Transitions/SlideX.vue';
import InputHint from '@/components/atoms/forms/InputHint/Index.vue';
import InputSlider from '@/components/atoms/InputSlider/Index.vue';

const props = defineProps<{
  modelValue: SurveyAnswer;
  question: SurveyQuestion;
  inputId: string;
  disabled?: boolean;
  error?: string;
}>();
const emit = defineEmits<{
  (e: 'update:model-value', value: SurveyAnswer): void;
}>();

const { t } = useI18n();

const onTextInput = (answer: string) => {
  const payload = {
    ...props.modelValue,
    answer,
  };
  emit('update:model-value', payload);
};
</script>

<template>
  <InputSlider
    :model-value="modelValue?.opinion_scale_score"
    :min="props.question.opinion_scale_min"
    :max="props.question.opinion_scale_max"
    v-bind="$attrs"
    @update:model-value="(value: number) => emit('update:model-value', {
      ...props.modelValue,
      opinion_scale_score: value,
    })"
  />
  <TransitionSlide>
    <div v-if="question.opinion_scale_optional_free_text && modelValue?.opinion_scale_score">
      <label :for="inputId">{{ t('surveys.answer_questions.fields.please_elaborate') }}</label>
      <TextArea
        :id="inputId"
        :model-value="modelValue?.answer"
        class="survey-question-slider-input__text"
        data-test-id="survey-question-slider-text-input"
        @update:model-value="onTextInput"
      />
    </div>
  </TransitionSlide>
  <InputHint v-if="error" :value="error" :error="!!error" class="m-t-1" />
</template>

<style lang="scss" scoped>
.survey-question-slider-input__text {
  display: block;
  width: 100%;
}
</style>
