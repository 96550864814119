export default function useUsersListProps() {
  return {
    companyEnabled: Boolean,
    industryEnabled: Boolean,
    sizeEnabled: Boolean,
    sizeOptions: Array,
    industriesEndpoint: String,
    businessesEndpoint: String,
    attributeInfoUrl: String,
    columnInfoUrl: String,
    noUsersImagePath: String,
    skeletonTableData: Array,
    csvEndpoint: String,
    showingManaged: Boolean,
    includeNested: Boolean,
    teamId: String,
    advancedGroupingId: String,
    coached: Boolean,
    managed: Boolean,
    endpoint: String,
    exportEndpoint: String,
    hideSearch: Boolean,
    roles: Array,
    teamsEndpoint: String,
    labelsEndpoint: String,
    languages: Array,
    initName: String,
    initSearch: String,
    canManage: Boolean,
    csvExportMessage: String,
    showQuickAddToTeamButton: Boolean,
    inviteSinglePath: String,
    inviteManyPath: String,
    addStatusFilterOnLoad: Boolean,
    initialAdvancedGroupingRefreshing: Boolean,
    escalationLog: Boolean,
    locationsEndpoint: String,
    loginNameFilterLabel: String,
    loginNamePlaceholder: String,
    initStatus: String,
    showEditColumns: Boolean,
    batchActionsEndpoint: String,
    filters: Array,
    icon: String,
  };
}
