/*
  PROPERTIES AVAILABLE
  ===========================

  query {Object}
  ---------------------------
  This stores the data that you want your components to use that will
  also be stored in the URL params.
  Example: { search: 'activity', page: 2 }

  queryTypes {Object}
  ---------------------------
  This will transform your URL query when loading a page initially.
  By default, all params are treated as a string, so you can use
  this to transform a value into a different data type.

  The keys in queryTypes should match keys in "query" and
  the values should be a constuctor.
  Example: { page: Number, created: Date }

  ignoreQueryChanges {Array}
  ---------------------------
  Any changes inside "query" will trigger the "handleQueryUpdate"
  function by default. If you don't want anything to trigger at all,
  simply don't include the "handleQueryUpdate" function in your component.

  If you are using "handleQueryUpdate" but don't want a specific
  query param to trigger a change (for example if a param only makes a
  frontend change), you can pass the query param in this array.
  Example: ['gridLayout']

  handleQueryUpdate {Function}
  ---------------------------
  This function will be called via a watcher anytime "query" is
  updated (unless one of the params is added to "ignoreQueryChanges"),
  assuming it has been defined on your component.
  Example: handleQueryUpdate() { this.fetchDataFromApi(this.endpoint, this.query) }

*/

/*
  TEST CAVEATS
  ===========================

  This mixin modifies the browser URL via window.location therefore
  you must ensure you reset this for each test when using the mixin,
  otherwise concurrent tests may interfere with eachother.
  Example:

  beforeEach(() => {
    Object.defineProperty(window, 'location', {
      value: new URL('http://localhost/'),
    });
  });

*/

import _ from 'lodash';

/**
 * Formats a search param using a given constructor. Most constructors
 * work by default, however, some require the "new" keyword so should
 * be defined in the switch statement.
 * @param {function} constructor Formats the given param.
 * @param {string} param The string parsed from the URL search params.
 */
const formatParam = function formatParam(constructor, param) {
  switch (constructor) {
    case Array:
      return param.split(',');
    case Date:
      return new Date(param);
    default:
      return constructor(param);
  }
};

/**
 * Parses the "search" property in window.location
 * @param {object} queryTypes Converts params (key) using the given constructor (value).
 */
const getParsedQuery = function getParsedQuery(queryTypes) {
  const urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
  return Object.entries(urlParams).reduce((acc, [key, value]) => {
    if (queryTypes[key]) {
      const constructor = queryTypes[key];
      return { ...acc, [key]: formatParam(constructor, value) };
    }
    return { ...acc, [key]: value };
  }, {});
};

/**
 * Updates the browser URL with modified search query params.
 * @param {object} query The key/value pairs to update the URL.
 */
const updateUrlQuery = function updateUrlQuery(query) {
  const url = new URL(window.location.href);
  url.search = '';
  Object.entries(query).forEach(([key, value]) => {
    let valueIsEmptyArray = false;

    if (Array.isArray(value)) {
      if (value.length === 0) {
        valueIsEmptyArray = true;
      }

      if (value.length === 1 && value[0] === '') {
        valueIsEmptyArray = true;
      }
    }

    if (value !== null && value !== '' && !valueIsEmptyArray) {
      url.searchParams.set(key, value);
    } else {
      url.searchParams.delete(key);
    }
  });
  window.history.replaceState({}, '', url);
};

export default {
  data: () => ({
    $_queryHandler_mounted: false,
    $_queryHandler_clonedQuery: false,
    ignoreQueryChanges: [],
    query: {},
    queryTypes: {},
  }),
  watch: {
    query: {
      /**
       * 'deep' option cannot identify difference between old/new objects
       * so it requires cloning the query for comparison.
       */
      handler(newQuery) {
        if (!this.$_queryHandler_mounted) return;
        const strippedNewQuery = _.omit(newQuery, this.ignoreQueryChanges);
        const strippedPrevQuery = _.omit(this.$_queryHandler_clonedQuery, this.ignoreQueryChanges);
        this.$_queryHandler_clonedQuery = _.cloneDeep(newQuery);

        updateUrlQuery(newQuery);
        if (!_.isEqual(strippedNewQuery, strippedPrevQuery)) {
          this.handleQueryUpdate && this.handleQueryUpdate();
        }
      },
      deep: true,
    },
  },
  created() {
    this.query = _.merge(this.query, getParsedQuery(this.queryTypes));
  },
  mounted() {
    this.$_queryHandler_clonedQuery = _.cloneDeep(this.query);
    this.$_queryHandler_mounted = true;
  },
};
