<template>
  <Transition appear :name="`swap-${direction}`" mode="out-in">
    <slot />
  </Transition>
</template>

<script>
export default {
  name: 'TransitionSwap',
  props: {
    direction: {
      type: String,
      default: 'up',
    },
  },
};
</script>

<style lang="scss" scoped>
.swap-down-enter-active,
.swap-down-leave-active,
.swap-up-enter-active,
.swap-up-leave-active {
  transition: all 0.3s ease;
}

.swap-up-enter,
.swap-up-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.swap-down-enter,
.swap-down-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

.swap-down-leave,
.swap-down-enter-to,
.swap-up-leave,
.swap-up-enter-to {
  transform: translateY(0);
  opacity: 1;
}
</style>
